import React from "react";

const StarRating = ({ avgRating, reviewsCount }) => {
  const maxRating = 5;
  const renderStars = () => {
    const stars = [];
    if (reviewsCount === undefined || reviewsCount === 0) {
      for (let i = 0; i < maxRating; i++) {
        stars.push(
          <i
            key={`empty-star-${i}`}
            className="pi pi-star"
            style={{ fontSize: "1.3rem" }}
          />
        );
      }
    } else {
      const fullStars = Math.floor(avgRating); // Number of full stars
      const emptyStars = maxRating - fullStars; // Number of empty stars
      for (let i = 0; i < fullStars; i++) {
        stars.push(
          <i
            key={`full-star-${i}`}
            className="pi pi-star-fill"
            style={{ fontSize: "1.3rem" }}
          />
        );
      }
      for (let i = 0; i < emptyStars; i++) {
        stars.push(
          <i
            key={`empty-star-${i}`}
            className="pi pi-star"
            style={{ fontSize: "1.3rem" }}
          />
        );
      }
    }
    return stars;
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {renderStars()}({reviewsCount})
    </div>
  );
};
export default StarRating;
