import React, { useEffect, useState } from "react";
import axios from "../../../../services/axios/Axios";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { UpdateEnum } from "../../../../services/enums";
import { Button } from "primereact/button";

export const UpdatesHistory = ({ profileId }) => {
  const [updatesHistory, setUpdatesHistory] = useState("");

  const fetchUpdatesHistory = async () => {
    try {
      axios
        .get(`/profile-updates/${profileId}/get-by-profile-id`)
        .then((response) => {
          setUpdatesHistory(response.data);
        });
    } catch (error) {
      console.error(error);
    }
  };
  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY HH:mm");
  };

  const formatUpdatedField = (updatedField) => {
    try {
      return UpdateEnum.find((update) => update.code === updatedField).name;
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchUpdatesHistory();
  }, [profileId]);

  const setReviewsStatus = (reviewId) => {
    axios
      .put(`/profile-updates/${reviewId}/update-profile-updates`)
      .then((response) => {
        fetchUpdatesHistory();
      });
  };
  const updateAllReviewStatus = (profileId) => {
    axios
      .put(`/profile-updates/${profileId}/update-all-profile-updates`)
      .then((response) => {
        fetchUpdatesHistory();
      });
  };

  return (
    <div>
      <h2>Historique des mises à jour</h2>
      <div style={{ margin: "20px 0" }}>
        <Button
          label="Tout passer en OK"
          size="small"
          onClick={() => {
            window.confirm("Êtes-vous sûr de vouloir tout passer en OK ?")
              ? updateAllReviewStatus(profileId)
              : window.alert("Opération annulée");
          }}
        />
      </div>
      <div style={{ height: "500px" }}>
        <DataTable
          value={updatesHistory}
          className="profilTable"
          stripedRows
          scrollable
          scrollHeight="flex"
        >
          <Column field="id" header="id"></Column>
          <Column
            field="updatedField"
            header="Information changée"
            body={(row) => {
              return formatUpdatedField(row.updatedField);
            }}
          ></Column>
          <Column field="updateOrigin" header="Initiateur"></Column>
          <Column field="updateReviewStatus" header="Status"></Column>
          <Column
            field="createdAt"
            header="Date de création"
            body={(row) => {
              return formatDate(row.createdAt);
            }}
          ></Column>
          <Column
            field=""
            header="Mettre à jour le statut"
            body={(row) => {
              return (
                <input
                  type="checkbox"
                  onChange={() => {
                    if (row.updateReviewStatus !== "OK") {
                      setReviewsStatus(row.id);
                    }
                  }}
                  checked={row.updateReviewStatus === "OK" ? true : false}
                ></input>
              );
            }}
          ></Column>
          <Column
            field="updatedAt"
            header="Date de modification"
            body={(row) => {
              return formatDate(row.updatedAt);
            }}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};
