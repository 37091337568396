import React, { useEffect, useState } from "react";
import "../style.css";
import axios from "../../../../services/axios/Axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { PRIMARY_GREEN } from "../../../../styles/colors";

const MissionNotification = (props) => {
  const { missionId } = props;
  const [targetedProfiles, setTargetedProfiles] = useState([]);

  const fetchTargetedProfiles = () => {
    axios.get(`/missions/targeted-profiles/${missionId}`).then((response) => {
      setTargetedProfiles(response.data);
    });
  };
  useEffect(() => {
    fetchTargetedProfiles();
  }, []);

  return (
    <div className="section-container">
      <div style={{ display: "flex", gap: "10px" }}>
        <h2 style={{ color: PRIMARY_GREEN }}>{targetedProfiles.length}</h2>
        <h2>HELPERS notifiés par cette mission</h2>
      </div>
      <div className="applications-border">
        <DataTable
          value={targetedProfiles}
          className="p-datatable-gridlines"
          paginator
          rows={10}
          emptyMessage="Aucune candidature"
          onSelectionChange={(e) => {
            console.log("VALUE ID", e.value.id);
            window.open(`/profils?detailedProfileId=${e.value.userId}`);
          }}
          selectionMode={"single"}
          scrollable
          scrolldirection="both"
          scrollHeight="flex"
        >
          <Column
            field="hasApplied"
            header="Candidature"
            body={(rowData) => {
              return rowData.hasApplied ? (
                <span style={{ color: "green" }}>Oui</span>
              ) : (
                <span style={{ color: "red" }}>Non</span>
              );
            }}
            sortable
            style={{ textAlign: "center" }}
          ></Column>
          <Column
            field="receivedSmsNotif"
            header="Demande d'envoi SMS Brevo"
            sortable
            body={(rowData) => {
              return rowData.receivedSmsNotif ? (
                <span style={{ color: "green" }}>Oui</span>
              ) : (
                <span style={{ color: "red" }}>Non</span>
              );
            }}
            style={{ textAlign: "center" }}
          ></Column>
          <Column
            field="receivedPushNotif"
            header="Envoi Push"
            sortable
            body={(rowData) => {
              return rowData.receivedPushNotif ? (
                <span style={{ color: "green" }}>Oui</span>
              ) : (
                <span style={{ color: "red" }}>Non</span>
              );
            }}
            style={{ textAlign: "center" }}
          ></Column>
          <Column
            field="receivedEmailNotif"
            header="Demande d'Envoi Email Brevo"
            sortable
            body={(rowData) => {
              return rowData.receivedEmailNotif ? (
                <span style={{ color: "green" }}>Oui</span>
              ) : (
                <span style={{ color: "red" }}>Non</span>
              );
            }}
            style={{ textAlign: "center" }}
          ></Column>
          <Column field="role" header="Role" sortable></Column>
          <Column field="firstName" header="Prénom" sortable></Column>
          <Column field="lastName" header="Nom" sortable></Column>
          <Column field="status" header="Statut" sortable filter></Column>
          <Column field="city" header="Ville" sortable></Column>
          <Column field="zipCode" header="Code postal" sortable></Column>
          <Column field="phoneNumber" header="Téléphone" sortable></Column>
          <Column field="email" header="Email" sortable></Column>
          <Column field="id" header="ID" sortable></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default MissionNotification;
