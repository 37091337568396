import mime from "mime";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const ViewFile = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [mimeType, setMimeType] = useState();
  const getMimeTypeOfFile = async (file) => {
    const getMimeType = (signature) => {
      switch (signature) {
        case "89504E47":
          return "image/png";
        case "47494638":
          return "image/gif";
        case "25504446":
          return "application/pdf";
        case "FFD8FFDB":
        case "FFD8FFE0":
        case "FFD8FFE1":
          return "image/jpeg";
        case "504B0304":
          return "application/zip";
        default:
          return "Unknown filetype";
      }
    };

    return await new Promise((resolve) => {
      let fileReader = new FileReader();

      fileReader.onloadend = function (evt) {
        if (evt.target.readyState === FileReader.DONE) {
          const uint = new Uint8Array(evt.target.result);
          let bytes = [];

          uint.forEach((byte) => {
            bytes.push(byte.toString(16));
          });

          const hex = bytes.join("").toUpperCase();
          const mime = getMimeType(hex);
          setMimeType(mime);
          resolve(mime);
        }
      };
      try {
        fileReader.readAsArrayBuffer(file?.slice(0, 4));
      } catch (e) {
        console.log(e);
      }
    });
  };

  useEffect(async () => {
    if (isLoaded === false) {
      const toto = await getMimeTypeOfFile(props?.data?.resp?.data).result;
      setIsLoaded(true);
    }
    return () => {};
  }, []);

  useEffect(() => {
    console.log(mimeType);
    if (mimeType !== undefined) {
      const file = window.URL.createObjectURL(
        new Blob([props.data.resp.data], { type: mimeType })
      );
      window.open(file);
      props.actions.setHideSubForm();
    }
    return () => {};
  }, [mimeType]);

  return (
    <>
      <tr>
        <td>
          <Button
            style={{ width: "150px", height: "24px", margin: "20px" }}
            label={props?.type}
            className="p-button-outlined"
          />{" "}
        </td>
        <td>
          {" "}
          <Button
            icon="pi pi-check"
            style={{ height: "24px", width: "24px", margin: "20px" }}
            className={
              props?.valid === true ? "p-button-success" : "p-button-secondary"
            }
          />
        </td>
      </tr>
    </>
  );
};

ViewFile.propTypes = {};
ViewFile.defaultProps = {};
export default ViewFile;
