import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import axios from "../../../../services/axios/Axios";
import moment from "moment";
import InputDivNumber from "../../../Base/InputNumber";

const RechercheToutProfil = (props) => {
  const [rangeSearch, setRangeSearch] = useState(20);
  const [rangeSearchResult, setRangeSearchResult] = useState();

  useEffect(() => {
    axios
      .get(
        "search/viewResultProfiles/" +
          props.profileInfo.profile?.userId +
          "?roles=HELPER&&roles=HELPRO&&range=" +
          rangeSearch +
          "&allProfiles=true"
      )
      .then((resp) => {
        if (resp?.status !== undefined) {
          setRangeSearchResult(resp.data.profilesList);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeSearch]);

  return (
    <>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "6px ",
        }}
      >
        <p>Chercher dans un rayon de</p>
        <InputDivNumber
          value={rangeSearch}
          //name="Nom"
          handleChange={(e) => {
            setRangeSearch(e.target?.value);
          }}
          showButtons={true}
          suffix=" Km"
          customStyle={{ height: "26px", width: "85px" }}
        />
        <p
          style={{ marginLeft: "80px" }}
        >{`${rangeSearchResult?.length} profils trouvés`}</p>
      </div>
      <DataTable
        value={rangeSearchResult}
        paginator
        className="profilTable"
        stripedRows
        scrollable
        scrollDirection="both"
        scrollHeight="flex"
        sortOrder={-1}
        paginatorPosition="bottom"
        rows={10}
        dataKey="id"
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" /*{this.paginatorTemplate}*/
        selectionMode="single"
        onSelectionChange={(e) => {
          window.open(`/profils?detailedProfileId=${e.value.user_id}`);
        }}
      >
        <Column
          header={"Match"}
          body={(rawData) => {
            if (
              props?.profileInfo?.match
                .map((e) => [e.profileA.id, e.profileB.id])
                .flat()
                .includes(rawData.profile_id)
            ) {
              return <span>💛</span>;
            } else {
              return null;
            }
          }}
        ></Column>
        <Column
          header={"Like émis"}
          body={(rawData) => {
            if (
              props?.profileInfo?.givenLike
                .map((e) => e.favoriteProfile.id)
                .includes(rawData.profile_id)
            ) {
              return <span>❤️</span>;
            } else {
              return null;
            }
          }}
        ></Column>
        <Column
          style={{
            minWidth: "100px",
          }}
          // body={mailBodyTemplate}
          field="profile_role"
          sortable
          filter
          header="Role"
        ></Column>

        <Column
          style={{ minWidth: "145.95px" }}
          field="profile_firstName"
          sortable
          filter
          header="Prénom"
        ></Column>
        <Column
          style={{ minWidth: "145px" }}
          field="profile_lastName"
          header="Nom"
          sortable
          filter
        ></Column>
        <Column
          style={{ minWidth: "145px" }}
          field="profile_profileStatus"
          header="Profile Status"
          body={(rowData) =>
            rowData.profile_profileStatus === "LIVE" ? (
              <span style={{ color: "green" }}>LIVE</span>
            ) : rowData.profile_profileStatus === "BLOCKED" ? (
              <span style={{ color: "red" }}>BLOQUÉ</span>
            ) : rowData.profile_profileStatus === "UNSUBSCRIBED" ? (
              "DÉSINSCRIT"
            ) : rowData.profile_profileStatus === "DELETED" ? (
              "SUPPRIMÉ"
            ) : rowData.profile_profileStatus === "INCOMPLETE" ? (
              <span style={{ color: "orange" }}>INCOMPLET</span>
            ) : rowData.profile_profileStatus === "INACTIF_1" ? (
              <span style={{ color: "orange" }}>INACTIF 1</span>
            ) : rowData.profile_profileStatus === "INACTIF_6" ? (
              <span style={{ color: "lightred" }}>INACTIF 6</span>
            ) : rowData.profile_profileStatus === "INACTIF_12" ? (
              <span style={{ color: "red" }}>INACTIF 12</span>
            ) : (
              <span style={{ color: "black" }}>
                {rowData.profile_profileStatus}
              </span>
            )
          }
          sortable
          filter
        ></Column>
        <Column
          style={{ minWidth: "145px" }}
          field="user_creationDate"
          header="Date de Création"
          sortable
          filter
          body={(rowData) => {
            const dateParts = rowData.user_creationDate?.split(" ");
            if (dateParts && dateParts.length > 0) {
              const date = dateParts[0];
              return <span>{date}</span>;
            }
            return null; // Handle cases where rowData.user?.creationDate is undefined or invalid
          }}
        ></Column>
        <Column
          style={{ minWidth: "145px" }}
          field="profile_completedAt"
          header="Date de Complétion"
          sortable
          filter
          body={(rowData) =>
            rowData?.profile_completedAt
              ? moment(rowData.profile_completedAt).format("DD-MM-YYYY")
              : ""
          }
        ></Column>
      </DataTable>
    </>
  );
};

export default RechercheToutProfil;
