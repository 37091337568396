import { React, useEffect, useState } from "react";
import axios from "../../services/axios/Axios";
import "./dashboard.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import parse from "html-react-parser";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import DatePicker from "react-multi-date-picker";
import dayjs from "dayjs";
import { PRIMARY_GREEN } from "../../styles/colors";
import { savUpdateType } from "../../services/enums";
import { TabPanel, TabView } from "primereact/tabview";

const Dashboard = (props) => {
  const [priorityProfilesHelpee, setPriorityProfilesHelpee] = useState([]);
  const [priorityProfilesHelper, setPriorityProfilesHelper] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [profilesCount, setProfilesCount] = useState({});
  const [reports, setReports] = useState({
    results: [],
    totalCount: 0,
  });
  const [range, setRange] = useState([
    dayjs().subtract(7, "days").toDate(),
    dayjs().toDate(),
  ]);

  useEffect(() => {
    // Fetch priority profiles
    axios.get("/profiles/priorityProfiles").then((response) => {
      setPriorityProfilesHelpee(response.data.priorityProfiles.helpees);
      setPriorityProfilesHelper(response.data.priorityProfiles.helpers);
    });
    axios.get("/profiles/getCountsForDashboard").then((response) => {
      setProfilesCount(response.data);
    });
    getReports();
  }, []);

  const getReports = () => {
    axios
      .get("/sav/findBetweenDates", {
        params: {
          startDate: dayjs(range[0])
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
          endDate: dayjs(range[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        },
      })
      .then((response) => {
        setReports(response.data);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const navigateToDashboardProfiles = (initialFilter) => {
    const url = `/profils?origin=dashboard&initialFilter=${initialFilter}`;
    window.open(url, "_blank");
  };

  // Ugly but necessary to display the last comment
  const lastComm = (rowData) => {
    return rowData.user.comment?.split("<br/>")[0] !== undefined ? (
      <div className="tableComment">
        {parse(rowData.user.comment?.split("<br/>")[0])}
      </div>
    ) : (
      ""
    );
  };

  return (
    <div
      style={{
        margin: "20px auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <div className="dashboard-container">
        <div className="dashboard-section" style={{ borderColor: "#47B9A8" }}>
          <h2>VÉRIFICATIONS</h2>
          <div className="dashboard-buttons-section">
            <Button
              style={{
                color: "white",
                backgroundColor: "#47B9A8",
                border: "none",
              }}
              size="small"
              label="Nouveaux inscrits bloqués"
              onClick={() => {
                navigateToDashboardProfiles("newBlocked");
              }}
            >
              <Badge
                value={profilesCount?.newBlockedCount}
                style={{ color: "#47B9A8" }}
              />
            </Button>
            <Button
              style={{
                color: "white",
                backgroundColor: "#47B9A8",
                border: "none",
              }}
              size="small"
              label="Nouvelles Modifications"
              onClick={() => {
                navigateToDashboardProfiles("newUpdates");
              }}
              tooltip="Profils ayant fait des modifs et apparaissant en NEW"
            >
              <Badge
                value={profilesCount?.newUpdatesCount}
                style={{ color: "#47B9A8" }}
              />
            </Button>
            <Button
              style={{
                color: "white",
                backgroundColor: "#47B9A8",
                border: "none",
              }}
              size="small"
              label="Permis de séjour"
              onClick={() => {
                navigateToDashboardProfiles("workPermitValidity");
              }}
              tooltip="Profils ayant comme statut de travail 'ALERTE'"
            >
              <Badge
                value={profilesCount?.workPermitValidityCount}
                style={{ color: "#47B9A8" }}
              />
            </Button>
          </div>
        </div>
        <div className="dashboard-section" style={{ borderColor: "#FED36B" }}>
          <h2>CALL INSCRIPTIONS</h2>
          <div className="dashboard-buttons-section">
            <Button
              style={{
                color: "white",
                backgroundColor: "#FED36B",
                border: "none",
              }}
              size="small"
              label="Bienvenue"
              onClick={() => {
                navigateToDashboardProfiles(
                  "welcomeProfiles&filterByDate=true"
                );
              }}
              tooltip="LIVE mais call de bienvenue en TODO"
            >
              <Badge
                value={profilesCount?.welcomeProfilesCount}
                style={{ color: "#FED36B" }}
              />
            </Button>
            <Button
              style={{
                color: "white",
                backgroundColor: "#FED36B",
                border: "none",
              }}
              size="small"
              label="Incomplets"
              onClick={() => {
                navigateToDashboardProfiles(
                  "incompleteProfiles&filterByDate=true"
                );
              }}
              tooltip="Profils incomplets des 15 derniers jours glissants "
            >
              <Badge
                value={profilesCount?.incompleteProfilesCount}
                style={{ color: "#FED36B" }}
              />
            </Button>
            <Button
              style={{
                color: "white",
                backgroundColor: "#FED36B",
                border: "none",
              }}
              size="small"
              label="Bloqué mais qualité OK"
              onClick={() => {
                navigateToDashboardProfiles("blockedQualityOK");
              }}
              tooltip="Profils des 15 derniers jours glissants qui sont bloqués mais OK dans qualité"
            >
              <Badge
                value={profilesCount?.blockedQualityOKCount}
                style={{ color: "#FED36B" }}
              />
            </Button>
          </div>
        </div>
        <div className="dashboard-section" style={{ borderColor: "#FF6F5B" }}>
          <h2>ACTIONS ACTIVATIONS</h2>
          <div className="dashboard-buttons-section">
            <Button
              style={{
                color: "white",
                backgroundColor: "#FF6F5B",
                border: "none",
              }}
              size="small"
              label="Helpee avec nouveaux résultat de recherche"
              onClick={() => {
                navigateToDashboardProfiles("canLikeAgain");
              }}
              tooltip="Helpee live qui ont un nouveau résultat de recherche alors qu’ils étaient à 0"
            >
              <Badge
                value={profilesCount?.canLikeAgainCount}
                style={{ color: "#FF6F5B" }}
              />
            </Button>
            <Button
              style={{
                color: "white",
                backgroundColor: "#FF6F5B",
                border: "none",
              }}
              size="small"
              label="Helpee sans résultats de recherche"
              onClick={() => {
                navigateToDashboardProfiles("cannotLike");
              }}
            >
              <Badge
                value={profilesCount?.cannotLikeCount}
                style={{ color: "#FF6F5B" }}
              />
            </Button>
            <Button
              style={{
                color: "white",
                backgroundColor: "#FF6F5B",
                border: "none",
              }}
              size="small"
              label="Helpee qui n’a pas eu de réponse"
              onClick={() => {
                navigateToDashboardProfiles("noAnswer");
              }}
              tooltip="Helpee qui n’a pas eu de candidatures à sa mission ou de réponse après un match"
            >
              <Badge
                value={profilesCount?.noAnswersCount}
                style={{ color: "#FF6F5B" }}
              />
            </Button>
            <Button
              style={{
                color: "white",
                backgroundColor: "#FF6F5B",
                border: "none",
              }}
              size="small"
              label="Helpee qui n’a pas donné de réponse"
              onClick={() => {
                navigateToDashboardProfiles("didnotReplyToHelper");
              }}
              tooltip="Helpee n’a pas donné de réponse a un helper suite à une candidature sur une mission ou n'a pas envoyé de message à un helper après un match"
            >
              <Badge
                value={profilesCount?.didNotReplyToHelperCount}
                style={{ color: "#FF6F5B" }}
              />
            </Button>
          </div>
        </div>
      </div>
      <div
        className="dashboard-section-bottom"
        style={{ borderColor: "#47B9A8" }}
      >
        <h2 style={{ marginBottom: 10 }}>
          Profils SAV prioritaires :{" "}
          {priorityProfilesHelpee.length + priorityProfilesHelper.length}
        </h2>
        <TabView
          activeIndex={activeTab}
          onTabChange={(e) => {
            setActiveTab(e.index);
          }}
        >
          <TabPanel
            header={`HELPEE prioritaires ${priorityProfilesHelpee.length}`}
          >
            <DataTable
              value={priorityProfilesHelpee}
              dataKey="id"
              style={{ width: "100%" }}
              emptyMessage="Aucun profil prioritaire"
              selectionMode={"single"}
              scrollable
              scrolldirection="both"
              scrollHeight="flex"
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
              onSelectionChange={(e) => {
                console.log("VALUE ID", e.value.id);
                window.open(`/profils?detailedProfileId=${e.value.userId}`);
              }}
            >
              <Column field="id" header="ID" />
              <Column field="firstName" header="Prénom" />
              <Column field="lastName" header="Nom" />
              <Column field="phone" header="Téléphone" />
              <Column field="zipCode" header="Code Postal" />
              <Column
                field="user.comments"
                header="Commentaire"
                body={lastComm}
              />
            </DataTable>
          </TabPanel>
          <TabPanel
            header={`HELPER prioritaires ${priorityProfilesHelper.length}`}
          >
            <DataTable
              value={priorityProfilesHelper}
              dataKey="id"
              style={{ width: "100%" }}
              emptyMessage="Aucun profil prioritaire"
              selectionMode={"single"}
              scrollable
              scrolldirection="both"
              scrollHeight="flex"
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
              onSelectionChange={(e) => {
                console.log("VALUE ID", e.value.id);
                window.open(`/profils?detailedProfileId=${e.value.userId}`);
              }}
            >
              <Column field="id" header="ID" />
              <Column field="firstName" header="Prénom" />
              <Column field="lastName" header="Nom" />
              <Column field="phone" header="Téléphone" />
              <Column field="zipCode" header="Code Postal" />
              <Column
                field="user.comments"
                header="Commentaire"
                body={lastComm}
              />
            </DataTable>
          </TabPanel>
        </TabView>
      </div>
      <div
        className="reports-container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "20px",
          border: "2px solid #47B9A8",
          borderRadius: "5px",
          padding: "10px",
          width: "90%",
        }}
      >
        <h2>Reports SAV : {reports.totalCount}</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <DatePicker
            value={range}
            onChange={setRange}
            range
            style={{
              height: "45px",
              width: "200px",
              marginLeft: "20px",
            }}
            rangeHover
          />
          <div style={{ padding: "10px" }}>
            Dates: du {dayjs(range[0]).format("DD/MM/YYYY")} au{" "}
            {dayjs(range[1]).format("DD/MM/YYYY")}
          </div>
          <Button
            label="Rechercher"
            style={{ backgroundColor: PRIMARY_GREEN, border: 0 }}
            size="small"
            onClick={() => getReports()}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid #FED36B ",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          {reports.results.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <DataTable
                value={reports.results}
                dataKey="id"
                style={{ width: "100%" }}
                emptyMessage="Aucun rapport pour cette période"
                scrollable
                scrolldirection="both"
                scrollHeight="flex"
              >
                <Column
                  field="sav_savUpdateType"
                  header="Type"
                  body={(rowData) => {
                    const foundType = savUpdateType.find(
                      (type) => type.code === rowData.sav_savUpdateType
                    );
                    // Check if foundType is not undefined before accessing the 'name' property
                    return foundType ? foundType.name : "";
                  }}
                />
                <Column field="count" header="Nombre" />
              </DataTable>
            </div>
          ) : (
            " Pas de résultats pour cette période."
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
