import React from "react";
import dayjs from "dayjs";
import { LIGHT_GREY, PRIMARY_GREEN } from "../../../../../styles/colors";

const PeriodicalMissionDates = ({ dates }) => {
  const daysEnum = [
    { day: "Lundi", code: "monday" },
    { day: "Mardi", code: "tuesday" },
    { day: "Mercredi", code: "wednesday" },
    { day: "Jeudi", code: "thursday" },
    { day: "Vendredi", code: "friday" },
    { day: "Samedi", code: "saturday" },
    { day: "Dimanche", code: "sunday" },
  ];

  const displayMissionLength = () => {
    const days = dates.periodDays;
    let total = 0;
    days.forEach((day) => {
      const start = dayjs(day.startTime);
      const end = dayjs(day.endTime);
      const diff = end.diff(start, "hours");
      total += diff;
    });
    return total + " heures";
  };

  const displayDays = (selectedDays) => {
    const dayStyle = {
      padding: "8px",
      borderRadius: "10px",
      color: LIGHT_GREY,
      backgroundColor: PRIMARY_GREEN,
    };
    return selectedDays.map((selectedDay) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <span style={dayStyle} key={selectedDay.day}>
            {daysEnum.find((d) => d.code === selectedDay.day).day}
          </span>
          <p style={{ color: PRIMARY_GREEN }}>De</p>
          <h4>{dayjs(selectedDay.startTime).format("HH:mm")}</h4>
          <p style={{ color: PRIMARY_GREEN }}>À</p>
          <h4>{dayjs(selectedDay.endTime).format("HH:mm")}</h4>
        </div>
      );
    });
  };
  const displayPeriodalDates = (dates) => {
    return (
      <div className="periodical-hours" style={{ borderColor: PRIMARY_GREEN }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <p>Début le: </p>
          <h4 style={{ fontWeight: "bold" }}>
            {dayjs(dates.startDate).format("DD/MM/YYYY")}
          </h4>
        </div>
        <div>
          <p>Tous les :</p>
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {displayDays(dates.periodDays)}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <p>Durée totale :</p>
          <h4>{displayMissionLength()}</h4>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <p>Fin le :</p>
          <h4 style={{ fontWeight: "bold" }}>
            {dates.endDate
              ? dayjs(dates.endDate).format("DD/MM/YYYY")
              : "Pas de date de fin"}
          </h4>
        </div>
      </div>
    );
  };

  return <div className="section-content">{displayPeriodalDates(dates)}</div>;
};
export default PeriodicalMissionDates;
