import { hot } from "react-hot-loader/root";
import PropTypes from "prop-types";

import React from "react";
import "primeicons/primeicons.css";

import { Route, Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Page from "./Page";

import Home from "../Home/container";
import logo from "../../images/LOGO DEAR HELPEE RVB_fond_transparent.png";
import { Button } from "primereact/button";
import MyMenu from "../MyMenu";
import jwtDecode from "jwt-decode";
import { setConfig } from "react-hot-loader";
import Dashboard from "../Dashboard/container";
import B2BPreparation from "../B2BPreparation/container";
import Profils from "../Profils/container";
import DetailedProfil from "../Profils/DetailedProfil";
import BlackDiv from "../BlackDiv";
import BaseForm from "../BaseForm/container";
import Reservation from "../Reservation/container";
import InternalTesting from "../InternalTesting/container";
import Company from "../Company/container";
import Missions from "../Missions/container";
import KpiDashboard from "../KpiDashboard";

setConfig({
  showReactDomPatchNotification: false,
});

class RouterRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = { settedRef: null };
    this.wrapperMenuRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    let decoded;
    if (
      localStorage.getItem(
        "adal.access.token.key495b14ec-0d72-4e07-be86-914f8b84f7fb"
      ) !== null
    ) {
      decoded = jwtDecode(
        localStorage.getItem(
          "adal.access.token.key495b14ec-0d72-4e07-be86-914f8b84f7fb"
        )
      );
    } else {
      decoded = jwtDecode(sessionStorage.getItem("adal.idtoken"));
    }

    this.props.actions.setDecoded(decoded);
  }
  handleClickOutside(event) {
    if (
      this.props.isOpenedMenu &&
      this.wrapperMenuRef?.current &&
      !this.wrapperMenuRef?.current?.contains(event?.target)
    ) {
      this.setState({ isMenuOpen: false });
    }

    this.props.actions.setIsOpenedMenu(false);
  }
  setRef = (value) => {
    this.wrapperMenuRef = value;
  };
  openMenu(event) {
    this.props.actions.setIsOpenedMenu(true);
  }
  navigate = (url) => {
    if (url.startsWith("/")) {
      this.props.history.push(url);
    }
  };
  stringToColour = (str) => {
    if (!str) {
      str = "SYSTEM";
    }
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var j = 0; j < 3; j++) {
      var value = (hash >> (j * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  };
  hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };
  getOpositeColor = (rgb) => {
    //Extract colors
    let color = {
      r: parseInt(rgb.r),
      g: parseInt(rgb.g),
      b: parseInt(rgb.b),
    };
    //If is this operation be <= 128 return white, others else return black
    let OpositeColor =
      0.3 * color["r"] + 0.59 * color["g"] + 0.11 * color["b"] <= 128
        ? "#FFF"
        : "#000";
    return OpositeColor;
  };

  render() {
    let decoded = "";
    try {
      if (
        localStorage.getItem(
          "adal.access.token.key495b14ec-0d72-4e07-be86-914f8b84f7fb"
        ) !== null
      ) {
        decoded = jwtDecode(
          localStorage.getItem(
            "adal.access.token.key495b14ec-0d72-4e07-be86-914f8b84f7fb"
          )
        );
      } else {
        decoded = jwtDecode(sessionStorage.getItem("adal.idtoken"));
      }
    } catch (e) {
      console.error("Error while decoding JWT", e);
    }

    const page = this.props.location.pathname.split("/")[1];

    return (
      <>
        <div className={`skel-${page || "home"}`} websocket={this.state.ws}>
          <div className={"skel-header"}>
            <div id="header-showMenu">
              <Button
                icon="pi pi-bars"
                id="header-btnToggleMenu"
                className="p-button-secondary"
                // onClick={(event) => this.toggle(event)}
                onMouseOver={() => {
                  this.openMenu();
                }}
                label=""
              />
            </div>
            <Link id="header-logo" to="/">
              <img id="header-imgLogo" alt="logo DH" src={logo} />
            </Link>
            <span style={{ marginLeft: "20px", fontSize: "10px" }}>
              Version 1.2.0
            </span>
            <div
              id="header-userIcon"
              className="header-userIcon"
              style={{
                backgroundColor: this.stringToColour(decoded?.name),
                color: this.getOpositeColor(
                  this.hexToRgb(this.stringToColour(decoded?.name))
                ),
              }}
            >
              {decoded?.name &&
                decoded?.name
                  .split(" ")
                  .map((n) => n[0])
                  .join("")
                  .toUpperCase()}
            </div>
          </div>
          <div className="skel-content">
            {this.props.general.isDisplayedOneOrSeveralSubForms && (
              <BlackDiv actions={this.props.actions} />
            )}

            {this.props.general.listOfSubformsDisplayed.map(
              (subform, index) => (
                <BaseForm
                  possibleLevelOfSubform={index}
                  displayComponentFromProp
                  componentToDisplayFromProps={subform.component}
                  data={subform.data}
                  isSubFormLarge={subform.isSubFormLarge}
                  key={index}
                  forceSmall={subform.forceSmall}
                />
              )
            )}

            <MyMenu
              ref={this.state.settedRef}
              popup={true}
              setRef={(e) => this.setRef(e)}
              className={
                this.props.isOpenedMenu ? "skel-menu" : "skel-menu-hidden"
              }
              id="Menu"
            />
            <Route
              path="/"
              exact
              render={(props) => (
                <Page {...props} component={Home} title="Dear Helpee" />
              )}
            />
            <Route
              path="/profils"
              render={(props) => (
                <Page
                  {...props}
                  component={Profils}
                  title="Liste des Profils"
                />
              )}
            />
            <Route
              path="/dashboard"
              render={(props) => (
                <Page {...props} component={Dashboard} title="Dashboard SAV" />
              )}
            />
            <Route
              path="/preparation-b2b"
              render={(props) => (
                <Page
                  {...props}
                  component={B2BPreparation}
                  title="Préparation B2B"
                />
              )}
            />
            <Route
              path="/detailedProfil"
              render={(props) => (
                <Page
                  {...props}
                  component={DetailedProfil}
                  title="Détail du profil"
                />
              )}
            />

            <Route
              path="/reservation"
              render={(props) => (
                <Page {...props} component={Reservation} title="Reservation" />
              )}
            />
            <Route
              path="/internal-testing"
              render={(props) => (
                <Page
                  {...props}
                  component={InternalTesting}
                  title="Reservation"
                />
              )}
            />
            <Route
              path="/company"
              render={(props) => (
                <Page {...props} component={Company} title="Entreprise" />
              )}
            />
            <Route
              path="/missions"
              render={(props) => (
                <Page {...props} component={Missions} title="Missions" />
              )}
            />
            <Route
              path="/kpi-dashboard"
              render={(props) => (
                <Page {...props} component={KpiDashboard} title="KPI" />
              )}
            />
          </div>
        </div>
      </>
    );
  }
}
RouterRoute.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,

  classes: PropTypes.object,
};
RouterRoute.defaultProps = {
  classes: {},
};

export default withRouter(hot(RouterRoute));
