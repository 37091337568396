import * as types from "../../types";
const initialState = {
  backOfficeBearer: "",
  numberOfSubFormsDisplayedOnScreen: 0,
  listOfSubformsDisplayed: [],
  decoded: "",
};

export default (prevState = initialState, action = {}) => {
  switch (action.type) {
    case types.setDecoded: {
      return { ...prevState, decoded: action.value };
    }
    case types.setSample: {
      return {
        ...prevState,
        userList: action.value,
      };
    }
    case types.isOpenedMenu: {
      return {
        ...prevState,
        isOpenedMenu: action.value,
      };
    }
    case types.backOfficeBearer: {
      return {
        ...prevState,
        backOfficeBearer: action.value,
      };
    }
    case types.addSubForm: {
      return {
        ...prevState,
        isDisplayedOneOrSeveralSubForms: true,
        numberOfSubFormsDisplayedOnScreen:
          prevState.numberOfSubFormsDisplayedOnScreen + 1,
        listOfSubformsDisplayed: [
          ...prevState.listOfSubformsDisplayed,
          action.value,
        ],
      };
    }
    case types.hideSubForm: {
      const subformsDisplayed = [...prevState.listOfSubformsDisplayed];
      if (!prevState.isDisplayedPickup) {
        subformsDisplayed.splice(subformsDisplayed.length - 1, 1);
      }
      return {
        ...prevState,
        isDisplayedOneOrSeveralSubForms:
          prevState.numberOfSubFormsDisplayedOnScreen > 1 ||
          (prevState.numberOfSubFormsDisplayedOnScreen > 0 &&
            prevState.isDisplayedPickup),
        numberOfSubFormsDisplayedOnScreen: prevState.isDisplayedPickup
          ? prevState.numberOfSubFormsDisplayedOnScreen
          : prevState.numberOfSubFormsDisplayedOnScreen > 0
          ? prevState.numberOfSubFormsDisplayedOnScreen - 1
          : 0,
        listOfSubformsDisplayed: subformsDisplayed,
        isDisplayedForm: prevState.isDisplayedForm
          ? prevState.numberOfSubFormsDisplayedOnScreen > 0 ||
            prevState.isDisplayedPickup
          : false,
      };
    }
    default:
      return prevState;
  }
};

export const setSample = (value) => ({
  type: types.setSample,
  value,
});
export const setIsOpenedMenu = (value) => ({
  type: types.isOpenedMenu,
  value,
});
export const setBackOfficeBearer = (value) => ({
  type: types.backOfficeBearer,
  value,
});
export const setDecoded = (value) => ({
  type: types.setDecoded,
  value,
});
export const setAddSubform = (value) => ({
  type: types.addSubForm,
  value,
});
export const setHideSubForm = (value) => ({
  type: types.hideSubForm,
  value,
});
