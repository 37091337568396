import React from "react";
import "../style.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { PRIMARY_GREEN } from "../../../../styles/colors";
import {
  missionApplicationStatus,
  missionApplicationStatusData,
} from "../../../../services/enums";
import InputDropdown from "../../../Base/InputDropdown";
import { Button } from "primereact/button";
import axios from "../../../../services/axios/Axios";

const MissionApplications = (props) => {
  const [applications, setApplications] = React.useState(props.applications);
  const [applicationStatus, setApplicationStatus] = React.useState({
    status: "",
    applicationId: null,
  });
  React.useEffect(() => {
    const updatedApplications = applications.map((application) => {
      return {
        ...application,
        tmpStatus: application.status,
      };
    });
    setApplications(updatedApplications);
  }, []);

  const handleAlterApplicationStatus = (applicationStatus) => {
    axios
      .put(
        `/missions/modify-application-status/${applicationStatus.applicationId}`,
        applicationStatus
      )
      .then((response) => {
        const updatedApplications = applications.map((application) => {
          if (application.id === applicationStatus.applicationId) {
            return { ...application, status: applicationStatus.status };
          }
          return application;
        });
        // Assuming you're updating state in React or similar
        setApplications(updatedApplications);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const alterApplicationStatus = (rowData) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 6,
        }}
      >
        <InputDropdown
          value={rowData.tmpStatus}
          handleChange={(e) => {
            setApplications(
              applications.map((application) => {
                if (application.id === rowData.id) {
                  return { ...application, tmpStatus: e.value };
                }
                return application;
              })
            );
            setApplicationStatus({
              status: e.value,
              applicationId: rowData.id,
            });
          }}
          options={missionApplicationStatusData}
          customStyle={{
            height: "36px",
          }}
        />
        <Button
          onClick={() => {
            handleAlterApplicationStatus(applicationStatus);
          }}
          severity="danger"
          size="small"
          style={{
            color: "white",
            backgroundColor: "#47B9A8",
            border: "none",
            display: "flex",
            flexDirection: "row",
            width: 30,
            height: 30,
          }}
          icon="pi pi-check"
        />
      </div>
    );
  };

  const handleSelectionChange = (rowData, field) => {
    if (field !== "alterStatus") {
      window.open(`/profils?detailedProfileId=${rowData.applicant.userId}`);
    }
  };

  return (
    <div className="section-container">
      <div style={{ display: "flex", gap: "10px" }}>
        <h2 style={{ color: PRIMARY_GREEN }}>{applications.length}</h2>
        <h2>candidatures à la mission</h2>
      </div>
      <div className="applications-border">
        <DataTable
          value={applications}
          className="p-datatable-gridlines"
          paginator
          rows={10}
          emptyMessage="Aucune candidature"
          onSelectionChange={(e) => {
            handleSelectionChange(e.value.rowData, e.value.field);
          }}
          cellSelection
          selectionMode="single"
        >
          <Column field="id" header="ID" sortable></Column>
          <Column
            field="createdAt"
            header="Date de candidature"
            sortable
          ></Column>
          <Column
            field="applicant.id"
            header="Id candidature"
            sortable
          ></Column>
          <Column field="applicant.firstName" header="Prénom" sortable></Column>
          <Column field="message" header="Msg de candidature" sortable></Column>
          <Column
            field="status"
            header="Réponse de candidature"
            body={(rowData) => {
              return rowData.isDeclined ? (
                <span style={{ color: "red" }}>Refusé</span>
              ) : rowData.status === missionApplicationStatus.MESSAGE_SENT ? (
                <span style={{ color: "green" }}>
                  {missionApplicationStatus.MESSAGE_SENT}
                </span>
              ) : rowData.status ===
                missionApplicationStatus.AUTO_MESSAGE_SENT ? (
                <span style={{ color: "red" }}>
                  {missionApplicationStatus.AUTO_MESSAGE_SENT}
                </span>
              ) : rowData.status === missionApplicationStatus.PENDING ? (
                <span style={{ color: "orange" }}>
                  {missionApplicationStatus.PENDING}
                </span>
              ) : null;
            }}
            sortable
          ></Column>
          <Column
            field={"alterStatus"}
            header="Changer le statut de la candidature"
            body={alterApplicationStatus}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default MissionApplications;
