import React from "react";
import dayjs from "dayjs";
import "./style.css";
import { LIGHT_GREY, PRIMARY_GREEN } from "../../../../../styles/colors";

// Dayjs to french locale
import "dayjs/locale/fr";
dayjs.locale("fr");

const RegularMissionDates = ({ dates }) => {
  const daysEnum = [
    { day: "Lundi", code: "monday" },
    { day: "Mardi", code: "tuesday" },
    { day: "Mercredi", code: "wednesday" },
    { day: "Jeudi", code: "thursday" },
    { day: "Vendredi", code: "friday" },
    { day: "Samedi", code: "saturday" },
    { day: "Dimanche", code: "sunday" },
  ];
  const displayMissionLength = (startDateTmp, endDateTmp) => {
    const startDate = dayjs(startDateTmp);
    const endDate = dayjs(endDateTmp);
    const duration = endDate.diff(startDate, "hours");
    return `${duration}H`;
  };

  const displayDates = (dates) => {
    return dates.map((date) => {
      return (
        <div
          className="section-hours-column"
          key={date.startDate + date.endDate}
        >
          <span
            style={{
              padding: "8px",
              borderRadius: "10px",
              color: LIGHT_GREY,
              backgroundColor: PRIMARY_GREEN,
            }}
          >
            {dayjs(date.startDate).format("dddd DD MMM YYYY")}
          </span>
          <div style={{ display: "flex", gap: "5px", alignItems: "flex-end" }}>
            <p style={{ color: PRIMARY_GREEN }}>De</p>
            <h4>{dayjs(date.startDate).format("HH:mm")}</h4>
            <p style={{ color: PRIMARY_GREEN }}>À</p>
            <h4>{dayjs(date.endDate).format("HH:mm")}</h4>
            <p style={{ color: PRIMARY_GREEN }}>Durée</p>
            <h4>{displayMissionLength(date.startDate, date.endDate)}</h4>
          </div>
        </div>
      );
    });
  };

  return <div className="section-content">{displayDates(dates)}</div>;
};
export default RegularMissionDates;
