import React from "react";
import PropTypes from "prop-types";
import { BlockUI } from "primereact/blockui";
import axios from "../../services/axios/Axios";
import { Bar } from "react-chartjs-2";

import Idfhelpro from "../../images/idfhelpro.png";
import Provhelpro from "../../images/provhelpro.png";
import FranceHelpro from "../../images/francehelpro.png";

import { Dropdown } from "primereact/dropdown";
import DatePicker from "react-multi-date-picker";
import { isUndefined } from "lodash";
import moment from "moment";
import "./homeStyle.css";

import DotRoleColorNumber from "../Base/SharedComponentsStyled/DotRoleColorNumber";
import PieChart from "./PieChart/index.js";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: [],
      isLoading: false,
      startDate: this.getFirstDayOfWeek(),
      endDate: moment().format("YYYY-MM-DD"),
      report: {},
      rangeDate: [this.getFirstDayOfWeek(), moment().format("YYYY-MM-DD")],
      selectedFilter: "s",
      blocked: true,
    };
    this.lightOptions = {
      plugins: {
        legend: {
          labels: {
            fontSize: "8px",
          },
        },
      },
      legend: {
        display: true,
        labels: {
          fontColor: "#495057",
          fontSize: "8px",
        },
      },
    };
  }

  getFirstDayOfWeek() {
    // https://stackoverflow.com/questions/18875649/starting-the-week-on-monday-with-isoweekday
    return moment().startOf("isoWeek").format("YYYY-MM-DD");
  }

  getFirstDayOfMonth() {
    return moment().startOf("month").format("YYYY-MM-DD");
  }

  getFirstDayOfYear() {
    return moment().startOf("year").format("YYYY-MM-DD");
  }

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.rangeDate !== this.state.rangeDate) {
      if (this.state.rangeDate.length === 2) {
        this.setState({
          startDate: this.state.rangeDate[0].format("YYYY-MM-DD"),
          endDate: this.state.rangeDate[1].format("YYYY-MM-DD"),
        });
      }
    }
    if (prevState.startDate !== this.state.startDate) {
      this.getData();
    }

    if (prevState.endDate !== this.state.endDate) {
      this.getData();
    }
  }

  saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, fileName + EXCEL_EXTENSION);
      }
    });
  };
  exportExcel = () => {
    let arrangedTab = [];
    arrangedTab.push(Object.keys(this.state.report));

    arrangedTab.push(Object.values(this.state.report));

    import("xlsx").then((xlsx) => {
      const moment = require("moment");

      const worksheet = xlsx.utils.json_to_sheet(arrangedTab);
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, moment().format("YY-MM-DD"));
    });
  };

  getData() {
    this.setState({ blocked: true });
    axios
      .post("/reports", {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then((resp) => {
        this.setState({ report: resp.data.reports });
        this.setState({ blocked: false });
      });
  }

  changeFilter(value) {
    this.setState({ selectedFilter: value });
    switch (value) {
      case "s":
        this.setState({
          startDate: this.getFirstDayOfWeek(),
          endDate: moment().format("YYYY-MM-DD"),
        });
        break;
      case "m":
        this.setState({
          startDate: this.getFirstDayOfMonth(),
          endDate: moment().format("YYYY-MM-DD"),
        });
        break;
      case "a":
        this.setState({
          startDate: this.getFirstDayOfYear(),
          endDate: moment().format("YYYY-MM-DD"),
        });
        break;
      case "p":
        break;
      default:
        break;
    }
  }
  changeDate(value) {
    this.setState({ rangeDate: value });
  }
  render() {
    const lightOptions = {
      plugins: {
        legend: {
          labels: {
            fontSize: "8px",
          },
        },
      },
      legend: {
        display: true,
        labels: {
          fontColor: "#495057",
          fontSize: "8px",
        },
      },
    };

    const VALabel = this.state?.report?.HistoUser?.map((e) => e.id);

    const VAData = {
      labels: VALabel,
      datasets: [
        {
          type: "line",
          label: "Inscription",
          backgroundColor: "blue",
          borderWidth: 2,
          fill: false,
          data: VALabel?.map(
            (e) =>
              this.state?.report?.HistoUser?.find((elt) => elt.id === e).qty
          ),
        },
        {
          label: "Volume d'affaire",
          data: VALabel?.map((e) =>
            isUndefined(
              this.state?.report?.HistoVolume?.find((elt) => elt.id === e)
            )
              ? 0
              : this.state?.report?.HistoVolume?.find((elt) => elt.id === e)
                  .volume
          ),
          borderColor: "#FF6F5B",
          backgroundColor: "rgba(255, 99, 132, 1)",
        },
        {
          label: "Chiffre d'affaire",
          data: VALabel?.map((e) =>
            isUndefined(
              this.state?.report?.HistoCA?.find((elt) => elt.id === e)
            )
              ? 0
              : this.state?.report?.HistoCA?.find((elt) => elt.id === e).CA
          ),

          borderColor: "#527849",
          backgroundColor: "rgba(155, 12, 189, 1)",
        },
      ],
    };

    const timeSelectItems = [
      { label: "Cette semaine", value: "s" },
      { label: "Ce mois", value: "m" },
      { label: "Cette année", value: "a" },
      { label: "Personnalisée", value: "p" },
    ];

    return (
      <BlockUI blocked={this.state.blocked}>
        <div name={"CONTAINER"} className="container">
          <div name={"STATS DIV"} className="stats-container" key="homDiv">
            <h1>Statistiques par période</h1>
            <div name={"DATE PICKER"} className={"date-picker"}>
              <Dropdown
                value={this.state.selectedFilter}
                options={timeSelectItems}
                onChange={(e) => this.changeFilter(e.value)}
                placeholder="Quand..."
              />
              {this.state.selectedFilter === "p" ? (
                <>
                  <DatePicker
                    value={this.state.range}
                    onChange={(e) => this.changeDate(e)}
                    range
                    style={{
                      height: "45px",
                      width: "200px",
                      marginLeft: "20px",
                    }}
                    rangeHover
                  />
                  <div style={{ padding: "10px" }}>
                    Date: {moment(this.state.startDate).format("DD/MM/YYYY")}
                    au {moment(this.state.endDate).format("DD/MM/YYYY")}
                  </div>
                </>
              ) : (
                ""
              )}
              <button onClick={this.exportExcel} className="export-button">
                EXPORTER
              </button>
              {/* <Button onClick={this.exportExcel} text="EXPORTER"></Button> */}
            </div>
            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={() => {
                  const initialFilter = "workPermitValidity";
                  const url = `/profils?initialFilter=${initialFilter}`;
                  window.open(url, "_blank");
                }}
                severity="danger"
                size="small"
                style={{
                  color: "white",
                  backgroundColor: "#FF6F5B",
                  border: "none",
                }}
                label={`${this.state.report.nbOfInvalidWorkPermits} licences de travail invalides`}
              />
              <Button
                onClick={() => {
                  const initialFilter = "newUpdates";
                  const url = `/profils?initialFilter=${initialFilter}`;
                  window.open(url, "_blank");
                }}
                size="small"
                style={{
                  color: "white",
                  backgroundColor: "#47B9A8",
                  border: "none",
                }}
                label={`${this.state.report.nbOfProfileUpdates} profils mis à jour`}
              />
            </div>
            <div name={"INSCRIT/COMPLET"} className="inscrit-complet-container">
              <div name={"INSCRIT"} className="inscrit-complet">
                <h3
                  style={{
                    fontSize: "14px",
                    fontWeight: "900",
                    color: "#999",
                    float: "left",
                  }}
                >
                  Inscrit
                </h3>

                <div className="fr-idf-reg">
                  <div>
                    <div
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <img
                        src={FranceHelpro}
                        style={{ width: "32px", height: "32px" }}
                        alt="France"
                      ></img>
                      France : {this.state.report.periodInscrit}
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <DotRoleColorNumber
                        role="HELPEE"
                        value={this.state.report.periodHelpee}
                      />
                      <DotRoleColorNumber
                        role="HELPER"
                        value={this.state.report.periodHelper}
                      />
                      <DotRoleColorNumber
                        role="HELPRO"
                        value={this.state.report.periodHelpro}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <img
                        src={Idfhelpro}
                        style={{ width: "40px", height: "32px" }}
                        alt="IDF"
                      ></img>
                      IDF : {this.state.report.periodIDFInscrit}
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <DotRoleColorNumber
                        role="HELPEE"
                        value={this.state.report.periodIDFHelpee}
                      />
                      <DotRoleColorNumber
                        role="HELPER"
                        value={this.state.report.periodIDFHelper}
                      />
                      <DotRoleColorNumber
                        role="HELPRO"
                        value={this.state.report.periodIDFHelpro}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <img
                        src={Provhelpro}
                        style={{ width: "32px", height: "32px" }}
                        alt="Région"
                      ></img>
                      Région : {this.state.report.periodRegionInscrit}
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <DotRoleColorNumber
                        role="HELPEE"
                        value={this.state.report.periodRegionHelpee}
                      />
                      <DotRoleColorNumber
                        role="HELPER"
                        value={this.state.report.periodRegionHelper}
                      />
                      <DotRoleColorNumber
                        role="HELPRO"
                        value={this.state.report.periodRegionHelpro}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div name={"COMPLET"} className="inscrit-complet">
                <h3
                  style={{
                    fontSize: "14px",

                    fontWeight: "900",
                    color: "#999",
                    float: "left",
                  }}
                >
                  Complet
                </h3>
                <div className="fr-idf-reg">
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={FranceHelpro}
                        style={{ width: "32px", height: "32px" }}
                        alt="France"
                      ></img>
                      France : {this.state.report.periodCompleted}
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <DotRoleColorNumber
                        role="HELPEE"
                        value={this.state.report.periodCompletedHelpee}
                      />
                      <DotRoleColorNumber
                        role="HELPER"
                        value={this.state.report.periodCompletedHelper}
                      />
                      <DotRoleColorNumber
                        role="HELPRO"
                        value={this.state.report.periodCompletedHelpro}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={Idfhelpro}
                          style={{ width: "40px", height: "32px" }}
                          alt="IDF"
                        ></img>
                        IDF : {this.state.report.periodIDFCompleted}
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <DotRoleColorNumber
                          role="HELPEE"
                          value={this.state.report.periodIDFCompletedHelpee}
                        />
                        <DotRoleColorNumber
                          role="HELPER"
                          value={this.state.report.periodIDFCompletedHelper}
                        />
                        <DotRoleColorNumber
                          role="HELPRO"
                          value={this.state.report.periodIDFCompletedHelpro}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={Provhelpro}
                          style={{ width: "32px", height: "32px" }}
                          alt="Région"
                        ></img>
                        Région :{this.state.report.periodRegionCompleted}
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <DotRoleColorNumber
                          role="HELPEE"
                          value={this.state.report.periodRegionCompletedHelpee}
                        />
                        <DotRoleColorNumber
                          role="HELPER"
                          value={this.state.report.periodRegionCompletedHelper}
                        />
                        <DotRoleColorNumber
                          role="HELPRO"
                          value={this.state.report.periodRegionCompletedHelpro}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div name={"STATSMAIN"} className="nb-container">
              <div name={"NB STATS"} className="nb-stats">
                <div className="container-reports">
                  <h3 className="reports">ABONNEMENTS EN COURS</h3>
                  <i className="pi pi-user" style={{ color: "#47B9A8" }}></i>
                  {this.state.report.allPremiumUsers}
                </div>
                <div className="container-reports">
                  <h3 className="reports">DONT ABONNEMENTS BTC</h3>
                  <i className="pi pi-user" style={{ color: "#47B9A8" }}></i>
                  {this.state.report.periodPremiumUsersBtoC}
                </div>
                <div className="container-reports">
                  <h3 className="reports">DONT ABONNEMENTS BTB</h3>
                  <i className="pi pi-user" style={{ color: "#47B9A8" }}></i>
                  {this.state.report.periodPremiumUsersCoupon}
                </div>
                <div className="container-reports">
                  <h3 className="reports">INACTIF 1 MOIS</h3>
                  {this.state.report.inactifOneMonth}
                </div>
                <div className="container-reports">
                  <h3 className="reports">INACTIF 6 MOIS</h3>
                  {this.state.report.inactifSixMonth}
                </div>
                <div className="container-reports">
                  <h3 className="reports">INACTIF 12 MOIS</h3>
                  {this.state.report.inactifTwelveMonth}
                </div>

                <div className="container-reports">
                  <Tooltip target=".reports" position="top" />
                  <h3
                    className="reports"
                    data-pr-tooltip="Lorsqu'un HELPEE ou un HELPER/PRO clique
                sur le coeur sur l'app"
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                  >
                    DEMANDES DE CONTACT
                  </h3>

                  <i className="pi pi-heart" style={{ color: "red" }}></i>
                  {this.state.report.periodLike}
                </div>
                <div className="container-reports">
                  <h3
                    className="reports"
                    data-pr-tooltip="Lorsqu'un HELPEE clique sur le coeur sur l'app ou
                accepte la demande de contact d'un HELPER/PRO"
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                  >
                    MISES EN RELATION
                  </h3>
                  <i className="pi pi-heart-fill" style={{ color: "red" }}></i>
                  {this.state.report.periodMatch}
                </div>
                <div className="container-reports">
                  <h3
                    className="reports"
                    data-pr-tooltip="Un échange de message entre un HELPEE et un HELPER/PRO"
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                  >
                    DISCUSSIONS
                  </h3>
                  <i className="pi pi-send" style={{ color: "blue" }}></i>
                  {this.state.report.periodMessage}
                </div>
                <div className="container-reports">
                  <h3 className="reports">DISCUSSIONS VIDEOS</h3>
                  <i className="pi pi-video" style={{ color: "green" }}></i>
                  {this.state.report.periodVideoCall}
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "40px",
                    justifyContent: "space-between",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3 className="reports">RESERVATIONS CREEES</h3>

                  <i className="pi pi-book" style={{ color: "brown" }}></i>
                  {this.state.report.periodReservation}
                </div>
                <div className="container-reports">
                  <h3 className="reports">VOLUME D'AFFAIRE</h3>

                  <i
                    className="pi pi-shopping-bag"
                    style={{ color: "gold" }}
                  ></i>

                  {this.state.report.periodVA}
                </div>
                <div className="container-reports">
                  <h3 className="reports">CHIFFRE D'AFFAIRE</h3>
                  <i
                    className="pi pi-dollar"
                    style={{ color: "darkGreen" }}
                  ></i>

                  {Math.round(this.state.report.periodCA * 100) / 100}
                </div>
              </div>
              <div
                name={"CHART VA INSCRIPTION"}
                className="chart-va-inscription"
                key="homDiv"
              >
                <Bar data={VAData} options={lightOptions} />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "50px" }}>
            <div name={"PIECHART"} className="pie-chart">
              <PieChart
                rawData={this.state.report?.allProfilesOrigin}
                dataName="origin"
              />
            </div>
            <div name={"STATUS PIECHART"} className="pie-chart">
              <PieChart
                rawData={this.state.report?.allProfilesStatus}
                dataName="status"
              />
            </div>
          </div>
        </div>
      </BlockUI>
    );
  }
}
Home.propTypes = {
  history: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
};

Home.defaultProps = {};
export default Home;
