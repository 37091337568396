import React from "react";
import {ReactComponent as CheckPeriod} from './checkPeriod.svg';
import {ReactComponent as MinusPeriod} from './minusPeriod.svg';
import "./index.css";

const Availibility = ({
  initialProfile,
}) => {

  const DAYS = ["monday", "tuesday", "wednesday", "thursday","friday", "saturday", "sunday"]

  const defaultAvailabilityValue = () => {
    const initAvailability = [];
    DAYS.map(day => {
      initAvailability.push({
        day: day,
        periods: []
      });
      return day;
    });
    return initAvailability;
  }

  const [profile, setProfile] = React.useState(() => {
    if(!initialProfile.weekAvailability) {
      initialProfile.weekAvailability = defaultAvailabilityValue();
    }
    return initialProfile;
  });

  const handleChange = (e, d, p) => {
    setProfile((prevProfile) => {
      let nextAvailability = prevProfile.weekAvailability.map( a => {
        if(a.day === d) {
          if(a.periods.includes(p)) {
            a.periods.splice(a.periods.indexOf(p), 1);
          } else {
            a.periods.push(p);
          }
        }
        return a;
      });
    
      return {...prevProfile, weekAvailability: nextAvailability}
    });
  }

  const daysColumns = DAYS.map(day => {
    var dayAvailibility = profile.weekAvailability.filter((a) => a.day === day);
    const getPeriod = (day, p) => {
      if(dayAvailibility.length === 1 && dayAvailibility[0].periods.includes(p)) {
        return (<CheckPeriod/>);
      }
      return (<MinusPeriod/>);
    }
    const getDayTranslation = (day) => {
        switch (day) {
          case "monday":
            return "Lundi";
          case "tuesday":
            return "Mardi";
          case "wednesday":
            return "Mercredi"
          case "thursday":
            return "Jeudi"
          case "friday":
            return "Vendredi"
          case "saturday":
            return "Samedi"
          case "sunday":
            return "Dimanche"
          default:
            return "Oups..."
        }
    }

    return (
      <div class="availibilityCol">
        <div>{getDayTranslation(day)}</div>
        <div onClick={(e) => handleChange(e, day, "morning")}>{getPeriod(day, "morning")}</div>
        <div onClick={(e) => handleChange(e, day, "noon")}>{getPeriod(day, "noon")}</div>
        <div onClick={(e) => handleChange(e, day, "afternoon")}>{getPeriod(day, "afternoon")}</div>
        <div onClick={(e) => handleChange(e, day, "evening")}>{getPeriod(day, "evening")}</div>
        <div onClick={(e) => handleChange(e, day, "night")}>{getPeriod(day, "night")}</div>
      </div>)
  })

  return (
    <>
      <div class="availibility">
        <div class="availibilityHeaderCol">
          <div>-</div>
          <div>Matin</div>
          <div>Midi</div>
          <div>Après-midi</div>
          <div>Soirée</div>
          <div>Nuit</div>
        </div>
        {daysColumns}
      </div>
    </>
  );
};

export default Availibility;
