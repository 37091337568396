import { Dropdown } from "primereact/dropdown";
import PropTypes from "prop-types";
import React from "react";

const InputDropdown = (fieldName) => {
  return (
    <div
      style={{
        ...fieldName.divStyle,
        //marginTop: "0px",
        // marginLeft: "10px",
      }}
    >
      <span className="p-dropdown">
        <Dropdown
          disabled={fieldName.disabled}
          style={{
            ...fieldName.customStyle,
            color: "black",
            fontWeight: "bold",
            fontSize: "12px",
            borderColor: "#A9A9A9",
            borderWidth: 1,
          }}
          id={fieldName.name}
          value={fieldName.value}
          onChange={fieldName.handleChange}
          options={fieldName.options}
          optionLabel="name"
          optionValue="code"
          placeholder={fieldName.placeholder}
        />
      </span>
    </div>
  );
};

InputDropdown.propTypes = {};
InputDropdown.defaultProps = {};
export default InputDropdown;
