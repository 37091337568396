import React, { useState } from "react";
import RegularMissionDates from "./MissionDates/RegularMissionDates";
import PeriodicalMissionDates from "./MissionDates/PeriodicalMissionDates";
import "../style.css";
import { PRIMARY_RED } from "../../../../styles/colors";
import InputArea from "../../../Base/InputArea";
import InputDiv from "../../../Base/InputDiv";
import { NeededServicesData } from "../../../../services/enums";

const MissionInformation = ({ mission, handleChangeMission }) => {
  const displayNeeds = (needs) => {
    return needs.map((need) => {
      return (
        <div key={need} className="mission-needs">
          <p>{NeededServicesData[need].text}</p>
        </div>
      );
    });
  };

  const displayMissionType = (type) => {
    switch (type) {
      case "ponctual":
        return "Ponctuelle";

      case "defined_period":
        return "Périodique";

      case "recurring":
        return "Récurrente";
      default:
        break;
    }
  };

  return (
    <div className="information-container">
      <div className="section-border">
        <div className="informations">
          <div className="section-content-column">
            <p style={{ color: PRIMARY_RED }}>Nom de la mission :</p>
            <InputDiv
              value={mission.title}
              handleChange={(e) => handleChangeMission(e.target.value, "title")}
            />
          </div>
          <div className="section-content-column">
            <p style={{ color: PRIMARY_RED }}>Type de la mission :</p>
            <p style={{ fontWeight: "bold" }}>
              {displayMissionType(mission.missionType)}
            </p>
          </div>
          <div className="section-content-column">
            <p style={{ color: PRIMARY_RED }}>Besoin :</p>
            {displayNeeds(mission.needs)}
          </div>
          <div className="section-content-column">
            <p style={{ color: PRIMARY_RED }}>Description de la mission :</p>
            <InputArea
              handleChange={(e) =>
                handleChangeMission(e.target.value, "description")
              }
              value={mission.description}
              name=""
              customStyle={{ height: "100px" }}
            />
          </div>
        </div>
        <div className="section-content-column">
          <p style={{ color: PRIMARY_RED }}>Dates :</p>
          {mission.isPeriodical ? (
            <PeriodicalMissionDates dates={mission.dates} />
          ) : (
            <RegularMissionDates dates={mission.dates} />
          )}
        </div>
      </div>
    </div>
  );
};
export default MissionInformation;
