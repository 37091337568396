import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Route from "./index";
import {
  setIsOpenedMenu,
  setHideSubForm,
  setDecoded,
} from "../../store/reducers/general";
import { Login } from "../../store/middleware/ajax";

const mapStateToProps = (state) => ({
  isOpenedMenu: state.general.isOpenedMenu,
  general: state.general,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { Login, setIsOpenedMenu, setHideSubForm, setDecoded },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Route);
