import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Reservation from "./index";

const mapStateToProps = (state) => ({});
// Actions

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reservation);
