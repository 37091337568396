/* Les couleurs sont définies dans DEUX fichiers : ce fichier .js et colors.css */

export const PRIMARY_RED = "#FF6F5B";
export const SECONDARY_RED = "#F7B0A6";
export const TERCIARY_RED = "#FF1F00";
export const PRIMARY_YELLOW = "#FED36B";
export const PRIMARY_YELLOW_ALPHA = "rgba(254, 211, 107, 0.4)";
export const SECONDARY_YELLOW = "#FFBF23";
export const PRIMARY_GREEN = "#47B9A8";
export const SECONDARY_GREEN = "#B3D7B0";
export const PRIMARY_GREY = "#4D4D4D";
export const DARK_GREY = "#111111";
export const DIM_GREY = "#E6E6E6";
export const LIGHT_GREY = "#F4F2F2";
export const THUMB_SLIDER = "#C4C4C4";

export const HELPEE_COLOR = PRIMARY_RED;
export const HELPER_COLOR = SECONDARY_YELLOW;
export const HELPRO_COLOR = PRIMARY_GREEN;

const colors = {
  colorPrimaryMain: "rgba(0, 125, 255, 1)",
  colorPrimaryDark: "rgba(0, 87, 178, 1)",
  colorPrimaryLight: "rgba(51, 151, 255, 1)",
  colorPrimaryContrastText: "#ffffff",
  colorPrimaryState: "rgba(0, 125, 255, 0.08)",
  colorPrimaryBorder: "rgba(0, 125, 255, 0.5)",

  colorSecondaryMain: "rgba(255, 80, 95, 1)",
  colorSecondaryDark: "rgba(178, 56, 66, 1)",
  colorSecondaryLight: "rgba(255, 115, 127, 1)",
  colorSecondaryContrastText: "#ffffff",
  colorSecondaryState: "rgba(244, 67, 54, 0.08)",
  colorSecondaryBorder: "rgba(255, 80, 95, 0.5)",

  colorActionsMain: "rgba(224, 224, 224, 1)",
  colorActionsHover: "rgba(0, 0, 0, 0.04)",
  colorActionsSelected: "rgba(0, 0, 0, 0.08)",
  colorActionsDisabledBackground: "rgba(0, 0, 0, 0.12)",
  colorActionsDisabledText: "rgba(0, 0, 0, 0.26)",

  colorOtherTextPrimary: "rgba(0, 0, 0, 0.87)",
  colorOtherTextSecondary: "rgba(0, 0, 0, 0.54)",
  colorOtherTextDisabled: "rgba(0, 0, 0, 0.38)",
  colorOtherOtherBorder: "rgba(0, 0, 0, 0.23)",
  colorOtherOtherDivider: "rgba(224, 224, 224, 1)",
  colorOtherOtherBackdrop: "rgba(0, 0, 0, 0.5)",
  colorOtherOtherSnackbar: "rgba(50, 50, 50, 1)",
  colorOtherOtherActiveRating: "rgba(255, 180, 0, 1)",

  colorInfoMain: "rgba(33, 150, 243, 1)",
  colorInfoDark: "rgba(11, 121, 208, 1)",
  colorInfoLight: "rgba(100, 182, 247, 1)",
  colorInfoDarkText: "rgba(13, 60, 97, 1)",
  colorInfoLightBg: "rgba(233, 245, 254, 1)",
  colorInfoContrastText: "#ffffff",

  colorErrorMain: "rgba(244, 67, 54, 1)",
  colorErrorDark: "rgba(227, 27, 12, 1)",
  colorErrorLight: "rgba(248, 128, 120, 1)",
  colorErrorDarkText: "rgba(98, 27, 22, 1)",
  colorErrorLightBg: "rgba(254, 236, 235, 1)",
  colorErrorContrastText: "#ffffff",

  colorWarningMain: "rgba(255, 152, 0, 1)",
  colorWarningDark: "rgba(199, 119, 0, 1)",
  colorWarningLight: "rgba(255, 181, 71, 1)",
  colorWarningDarkText: "rgba(102, 61, 0, 1)",
  colorWarningLightBg: "rgba(255, 245, 229, 1)",
  colorWarningContrastText: "#ffffff",

  colorSuccessMain: "rgba(76, 175, 80, 1)",
  colorSuccessDark: "rgba(59, 135, 62, 1)",
  colorSuccessLight: "rgba(123, 198, 126, 1)",
  colorSuccessDarkText: "rgba(255, 115, 127, 1)",
  colorSuccessLightBg: "rgba(30, 70, 32, 1)",
  colorSuccessContrastText: "rgba(37, 247, 237, 1)",

  colorObligatory: "rgba(255, 0, 0, 1)",

  colorGrey50: "rgba(250, 250, 250, 1)",
  colorGrey100: "rgba(245, 245, 245, 1)",
  colorGrey200: "rgba(238, 238, 238, 1)",
  colorGrey300: "rgba(224, 224, 224, 1)",
  colorGrey400: "rgba(189, 189, 189, 1)",
  colorGrey500: "rgba(158, 158, 158, 1)",
  colorGrey600: "rgba(117, 117, 117, 1)",
  colorGrey700: "rgba(97, 97, 97, 1)",
  colorGrey800: "rgba(66, 66, 66, 1)",
  colorGrey900: "rgba(33, 33, 33, 1)",
  colorGreyA100: "rgba(213, 213, 213, 1)",
  colorGreyA200: "rgba(170, 170, 170, 1)",
  colorGreyA400: "rgba(97, 97, 97, 1)",
  colorGreyA700: "rgba(48, 48, 48, 1)",

  colorBackgroundWhite: "#ffffff",
};

export default colors;
