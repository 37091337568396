import React, { useEffect, useState } from "react";
import axios from "../../../../services/axios/Axios";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import DatePicker from "react-multi-date-picker";

const Statistics = (props) => {
  const [timeSelect, setTimeSelect] = useState("s");
  const [startDate, setStartDate] = useState(
    moment().startOf("week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [companyStats, setCompanyStats] = useState({});
  const [couponsStats, setCouponsStats] = useState();
  const [rangeDate, setRangeDate] = useState([
    moment().startOf("week").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);

  const timeSelectItems = [
    {
      label: "Cette semaine",
      value: "s",
      startDate: moment().startOf("week"),
      endDate: moment(),
    },
    {
      label: "Ce mois",
      value: "m",
      startDate: moment().startOf("month"),
      endDate: moment(),
    },
    {
      label: "Cette année",
      value: "a",
      startDate: moment().startOf("year"),
      endDate: moment(),
    },
    { label: "Personnalisée", value: "p" },
  ];

  const handleTimeSelectChange = (e) => {
    const selectedOption = timeSelectItems.find(
      (item) => item.value === e.value
    );
    setTimeSelect(e.value);

    if (selectedOption) {
      setStartDate(selectedOption.startDate.format("YYYY-MM-DD"));
      setEndDate(selectedOption.endDate.format("YYYY-MM-DD"));
    }
  };

  const getCompanyStatistics = async () => {
    axios
      .get(`/companies/statistics/${props.companyId}`, {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
      })
      .then((response) => {
        console.log(response?.data);
        setCompanyStats(response?.data);
      });
  };

  useEffect(() => {
    getCompanyStatistics();
  }, [startDate, endDate]);

  useEffect(() => {
    const couponsStatistics = props?.coupons.reduce(
      (acc, coupon) => {
        const nbUsed = coupon.nbUsed;
        const nbAvailable = coupon.nbAvailable;
        return {
          nbUsed: (acc.nbUsed += nbUsed),
          nbAvailable: (acc.nbAvailable += nbAvailable),
        };
      },
      { nbUsed: 0, nbAvailable: 0 }
    );

    setCouponsStats(couponsStatistics);
  }, []);

  return (
    <div name={"main"} className="">
      <div style={{ display: "flex" }}>
        <Dropdown
          value={timeSelect}
          options={timeSelectItems}
          onChange={handleTimeSelectChange}
          placeholder="Sélectionner une période"
        />
        {timeSelect === "p" ? (
          <div style={{ display: "flex" }}>
            <DatePicker
              value={rangeDate}
              onChange={(e) => {
                setStartDate(e[0].format("YYYY-MM-DD"));
                setEndDate(e[1].format("YYYY-MM-DD"));
              }}
              range
              style={{
                height: "45px",
                width: "200px",
                marginLeft: "20px",
              }}
              rangeHover
            />
            <div style={{ padding: "10px" }}>
              Date: {moment(startDate).format("DD/MM/YYYY")}
              au {moment(endDate).format("DD/MM/YYYY")}
            </div>
          </div>
        ) : null}
      </div>
      <div name="statsContainer" className="statsContainer">
        <div name="couponsStats" className="couponsStats">
          <p>
            Nombre de licences activées : {couponsStats?.nbUsed} /{" "}
            {couponsStats?.nbAvailable}
          </p>
        </div>
        <div name="favorites" className="favorites">
          <p>Demandes de contact : {companyStats?.favorites}</p>
        </div>
        <div name="matches" className="matchesContainer">
          <p>Mises en relation : {companyStats?.matches}</p>
        </div>
        <div name="messages" className="messagesContainer">
          <p>Discussions : {companyStats?.messages}</p>
        </div>
        <div name="videocall" className="videoCallContainer">
          <p>Discussions videos : {companyStats?.videoCalls}</p>
        </div>
        <div name="reservations" className="reservationsContainer">
          <p>Réservations créées : {companyStats?.reservations}</p>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
