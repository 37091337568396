import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { ProfileStatusEnum, userOrigin } from "../../../services/enums";

export default function PieChart(rawData, dataName) {
  const [chartData, setChartData] = useState();
  const [chartOptions, setChartOptions] = useState({});

  const mapOriginToName = (originCode) => {
    const matchingOrigin = userOrigin.find(
      (origin) => origin.code === originCode
    );
    return matchingOrigin ? matchingOrigin.name : originCode;
  };

  const totalData = rawData?.rawData?.reduce(
    (acc, item) => acc + item.count,
    0
  );

  useEffect(() => {
    const colorsByLabel = {
      INCOMPLET: "#FED36B",
      BLOQUÉ: "#FF6F5B",
      LIVE: "#47B9A8",
      DÉSINSCRIT: "#9b9b9e",
      SUPPRIMÉ: "#9b9b9e",
      INACTIF_1: "#FED36B",
      INACTIF_6: "#FED36B",
      INACTIF_12: "#FF6F5B",
      wordOfMouth: "#47B9A8",
      socialNetwork: "#FF6F5B",
      otherNetwork: "#FF6F5B",
      institution: "#FED36B",
      company: "#B3D7B0",
      association: "#F7B0A6",
      dontKnow: "#9b9b9e",
      other: "#9b9b9e",
      nc: "#9b9b9e",
    };
    const data = {
      labels: rawData?.rawData?.map((item) => {
        return `${mapOriginToName(item.origin)}: ${item.count} (${Number(
          Math.floor(item.percentage)
        )}%)`;
      }),
      datasets: [
        {
          data: rawData?.rawData?.map((item) => item.count),
          backgroundColor: rawData?.rawData?.map(
            (item) => colorsByLabel[item.origin] || "#9b9b9e"
          ),
          hoverBackgroundColor: rawData?.rawData?.map(
            (item) => colorsByLabel[item.origin] || "#9b9b9e"
          ),
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [rawData]);
  return (
    <>
      <h2>
        {rawData.dataName === "origin"
          ? "D'où viennent les utilisateurs ?"
          : "Vue globale des statuts"}
      </h2>
      <span> Total: {totalData}</span>
      <Chart
        type="pie"
        data={chartData}
        options={chartOptions}
        className="w-full md:w-60rem"
      />
      <span></span>
    </>
  );
}
