import React from "react";
import { PhoneNumberValidator } from "../../../../services/validator";
import InputDiv from "../../../Base/InputDiv";
import { TabView, TabPanel } from "primereact/tabview";
import { Avatar } from "primereact/avatar";
import DocumentSelectorAndValidator from "../../../Base/DocumentSelectorAndValidator/container";
import picto from "../../../../images/picto_hq.png";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import Stats from "../Stats/container";
import InputArea from "../../../Base/InputArea";
import HighLightSwitch from "../../../Base/HighLightSwitch";
import InputDropdown from "../../../Base/InputDropdown";
import InputDivNumber from "../../../Base/InputNumber";
import RadioButton from "../../../Base/RadioButton";
import StarRating from "../../../Base/StarRating";
import Availibility from "../Availibility";
import {
  JobHelpro,
  JobHelproFormat,
  diplomaType,
  getJobValue,
  userOrigin,
  NeedServicesProType,
  NeedServicesType,
} from "../../../../services/enums";
import { HelperExperience } from "../../../../services/enums";
import { HelperExperienceList } from "../../../../services/enums";
import { HelperSoftSkills } from "../../../../services/enums";
import { HelperSoftSkillsList } from "../../../../services/enums";
import { Image } from "primereact/image";
import { UpdatesHistory } from "../UpdatesHistory";
import axios from "../../../../services/axios/Axios";
import { PRIMARY_GREEN } from "../../../../styles/colors";

const HelproRole = ({
  user,
  profile,
  reviewsCount,
  avatar,
  activeTab,
  setActiveTab,
  handleChangeProfile,
  handleChangeRates,
  handleChangeRadio,
  handleChangeRadioPersonalServiceApproval,
  handleChangeRadioTransport,
  handleChangeRadioVaccationMode,
  handleChangeHasWorkLicence,
  addRate,
  userTemplate,
  deleteRate,
  setAutonomy,
  setMobilities,
  setMentalHealth,
  setServicesHelpro,
  setNeededServices,
  ServicesData,
  NeededServicesProData,
  NeededServicesData,
  getInitialData,
  uploadHandlerAvatar,
  refreshParent,
  searchProfile,
  statInfos,
  comment,
  setComment,
  addComment,
  deleteComment,
  deleteAccount,
  healthGrids,
  HealthData,
  durationValues,
  deleteTemplate,
  filteredCityByZipCode,
  setCity,
  city,
  setSoftSkills,
  setPathologyExperience,
}) => {
  var parse = require("html-react-parser");

  const [isPhoneValid, setIsPhoneValid] = React.useState("none");
  const [isEnumJob, setEnumJob] = React.useState(
    getJobValue(JobHelproFormat, profile?.job).code !== "Autre"
  );

  const displayCorrectActivities = () => {
    if (profile?.activityField === NeedServicesProType.HOME_HELP) {
      return healthGrids?.neededServicesHelper?.map((info, index) => {
        if (
          info === NeedServicesType.BATHROOM &&
          ![
            JobHelpro.AUXILIAIRE,
            JobHelpro.CAREGIVER,
            JobHelpro.ASSISTANT,
          ].includes(profile.job)
        ) {
          return null;
        }
        return (
          <div style={{ display: "inline-block" }} key={index}>
            <HighLightSwitch
              isHighlighted={profile.neededServices?.indexOf(info) !== -1}
              onClick={() => {
                setNeededServices(info);
              }}
              text={NeededServicesData[info].text}
            />
          </div>
        );
      });
    } else {
      return healthGrids?.servicesPro?.map((info, index) => {
        return (
          <div style={{ display: "inline-block" }} key={index}>
            <HighLightSwitch
              isHighlighted={profile?.activities?.indexOf(info) !== -1}
              onClick={() => {
                setServicesHelpro(info);
              }}
              text={ServicesData[info].text}
            />
          </div>
        );
      });
    }
  };

  const computeProfileStatus = (profileStatus) => {
    const statusArray = new Set(["INACTIF_1", "INACTIF_6", "INACTIF_12"]);
    return statusArray.has(profileStatus);
  };

  const displayComments = () => {
    if (user?.comment) {
      const comments = user.comment
        .split("<br/>")
        .filter((comment) => comment.trim() !== "");
      return comments.map((comment, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div style={{ width: "50%" }}>{parse(comment)}</div>
          <Button
            className={"p-button-primary"}
            size="small"
            onClick={() => {
              deleteComment(comment);
            }}
            style={{ fontSize: "10px", padding: "8px", marginTop: "10px" }}
            label="Supprimer"
          />
        </div>
      ));
    } else {
      return null;
    }
  };

  return (
    <>
      <p style={{ textAlign: "center" }}>
        ⚠️ Merci de sauvegarder après toute modification (y compris les
        documents)
      </p>
      <TabView
        activeIndex={activeTab}
        onTabChange={(e) => setActiveTab(e.index)}
      >
        <TabPanel headerTemplate={userTemplate} header="1">
          <div
            className="CHECKBOXES"
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "8px",
              backgroundColor: "#47b9a8",
              borderRadius: "8px",
            }}
          >
            <div
              className="QUALITÉ"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <h2
                style={{
                  margin: "0px 0px 0px 4px",
                  color: "#F4F2F2",
                }}
              >
                Qualité
              </h2>
              <div style={{ display: "flex" }}>
                <RadioButton
                  onChange={handleChangeRadio}
                  radioData={{
                    name: "qualityAppreciation",
                    value: "TODO, TOP, OK, OK_AA, NOK_AA, NOK",
                  }}
                  profileInfo={profile?.qualityAppreciation}
                />
              </div>
            </div>
            <div
              className="BIENVENUE"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <h2
                style={{
                  margin: "0px 0px 0px 4px",
                  color: "#F4F2F2",
                }}
              >
                Bienvenue
              </h2>
              <div style={{ display: "flex" }}>
                <RadioButton
                  onChange={handleChangeRadio}
                  radioData={{
                    name: "welcomeMessage",
                    value: "TODO, OK, MESS",
                  }}
                  profileInfo={profile?.welcomeMessage}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div className="ÉTAT_CIVIL">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2 style={{ margin: "10px 0px" }}>PROFIL ID#{profile?.id}</h2>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <h2 style={{ color: PRIMARY_GREEN }}>
                    {profile?.role} {profile?.profileStatus}
                  </h2>
                  {computeProfileStatus(profile?.profileStatus) && (
                    <button
                      style={{
                        border: "2px solid",
                        borderColor: PRIMARY_GREEN,
                        backgroundColor: PRIMARY_GREEN,
                        borderRadius: "8px",
                        padding: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.confirm(
                          "Êtes-vous sûr de vouloir passer ce profil en LIVE ?"
                        )
                          ? handleChangeProfile("LIVE", "profileStatus")
                          : window.alert("Opération annulée");
                      }}
                      className="p-button-danger"
                    >
                      <span style={{ color: "white", fontWeight: "bold" }}>
                        Passer ce profil en LIVE ?
                      </span>
                    </button>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: 14,
                    }}
                  >
                    Provenance
                  </p>
                  <InputDropdown
                    value={profile.profileOrigin}
                    //name="duration"
                    handleChange={(e) => {
                      handleChangeProfile(e.value, "profileOrigin");
                    }}
                    options={userOrigin}
                    customStyle={{
                      height: "36px",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  border: "2px solid lightgrey",
                  borderRadius: "8px",
                  padding: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    className="AVATAR"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "end",
                      padding: "10px",
                    }}
                  >
                    {avatar !== undefined ? (
                      <Image
                        src={URL.createObjectURL(avatar)}
                        zoomSrc={URL.createObjectURL(avatar)}
                        width="200px"
                        height="200px"
                        alt="avatar"
                        preview
                      />
                    ) : (
                      <Avatar
                        image={picto}
                        shape="circle"
                        style={{
                          width: "150px",
                          height: "150px",
                        }}
                        className="mx-2"
                      />
                    )}
                    <FileUpload
                      multiple={false}
                      withCredentials
                      mode="basic"
                      name="demo[]"
                      auto
                      chooseOptions={{
                        iconOnly: true,
                        // icon: "pi pi-fw pi-plus",
                      }}
                      customUpload
                      uploadHandler={uploadHandlerAvatar}
                    />
                    <div style={{ marginTop: 5, display: "flex" }}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "0px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Mode absence
                      </p>
                      <RadioButton
                        onChange={handleChangeRadioVaccationMode}
                        radioData={{
                          name: "vaccationMode",
                          value: "OUI, NON",
                        }}
                        profileInfo={profile?.vaccationMode ? "OUI" : "NON"}
                        textColor="black"
                      />
                    </div>
                    <button
                      onClick={async () =>
                        axios
                          .post("/auth/forgot", {
                            email: profile?.user?.email,
                          })
                          .then(() => {
                            alert(
                              "Email de réinitialisation du mot de passe envoyé"
                            );
                          })
                      }
                      style={{
                        margin: "15px 0",
                      }}
                    >
                      Envoyer email réinitisation mdp
                    </button>
                  </div>
                  <div className="PROFIL">
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "0px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Prénom
                    </p>
                    <InputDiv
                      value={profile?.firstName}
                      //name="Prénom"
                      handleChange={(e) =>
                        handleChangeProfile(e.target.value, "firstName")
                      }
                    />
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "0px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Nom
                    </p>
                    <InputDiv
                      value={profile?.lastName}
                      //name="Nom"
                      handleChange={(e) =>
                        handleChangeProfile(e.target.value, "lastName")
                      }
                    />
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "5px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Code postal
                    </p>
                    <InputDiv
                      value={profile?.zipCode}
                      //name="Code postal"
                      handleChange={(e) => {
                        const newZipCode = e.target.value;
                        const newCity = filteredCityByZipCode(newZipCode);
                        handleChangeProfile(newZipCode, "zipCode");
                        setCity(newCity);
                      }}
                    />
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "5px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Ville
                    </p>
                    <InputDiv value={city} disabled />
                  </div>
                  <div className="CONTACT">
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "0px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Téléphone
                    </p>
                    <InputDiv
                      value={profile?.phoneNumber}
                      //name="Téléphone"
                      handleChange={(e) => {
                        if (!PhoneNumberValidator(e.target.value)) {
                          setIsPhoneValid("block");
                          handleChangeProfile(e.target.value, "phoneNumber");
                        } else {
                          setIsPhoneValid("none");
                          handleChangeProfile(e.target.value, "phoneNumber");
                        }
                      }}
                    />
                    <span
                      style={{
                        display: isPhoneValid,
                        color: "red",
                        fontSize: "9px",
                        margin: "5px 0 0 10px",
                        fontWeight: "bold",
                      }}
                    >
                      Le numéro doit contenir 10 chiffres
                    </span>
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "0px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Email
                    </p>
                    <InputDiv
                      value={profile?.user?.email}
                      //name="Nom"
                      disabled={true}
                    />
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "0px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Références
                    </p>
                    <InputDivNumber
                      value={profile?.nbRecommendations ?? 0}
                      handleChange={(e) =>
                        handleChangeProfile(e.target.value, "nbRecommendations")
                      }
                    />
                    <div style={{ margin: "0px 10px 5px" }}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "10px 0px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Avis
                      </p>
                      <StarRating
                        avgRating={profile?.avgRating}
                        reviewsCount={reviewsCount}
                      />
                    </div>
                  </div>
                  <div className="DOCUMENTS">
                    <DocumentSelectorAndValidator
                      profile={profile}
                      type="FrontID"
                      valid={profile?.validIdFront}
                      refreshParent={() => refreshParent()}
                    ></DocumentSelectorAndValidator>
                    <DocumentSelectorAndValidator
                      profile={profile}
                      type="BackID"
                      valid={profile?.validIdBack}
                      invalid={profile?.inValidateBackID}
                      refreshParent={() => refreshParent()}
                    ></DocumentSelectorAndValidator>
                    {/* <DocumentSelectorAndValidator
                      profile={profile}
                      type="Casier Jud."
                      valid={profile?.validCR}
                      invalid={profile?.inValidateCR}
                      refreshParent={() => refreshParent()}
                    ></DocumentSelectorAndValidator> */}
                    <DocumentSelectorAndValidator
                      profile={profile}
                      type="KBIS"
                      valid={profile?.validKbis}
                      refreshParent={() => refreshParent()}
                    ></DocumentSelectorAndValidator>
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "0px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Métier
                    </p>
                    <InputDropdown
                      value={getJobValue(JobHelproFormat, profile?.job).code}
                      handleChange={(e) => {
                        if (e.value !== "Autre") {
                          setEnumJob(true);
                          handleChangeProfile(e.value, "job");
                        } else {
                          profile.job = "";
                          setEnumJob(false);
                        }
                      }}
                      options={JobHelproFormat}
                      customStyle={{
                        height: "36px",
                      }}
                    />
                    <InputText
                      id="jobInput"
                      disabled={isEnumJob}
                      visible={isEnumJob}
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: 12,
                        borderColor: "#A9A9A9",
                        borderWidth: 1,
                        display: isEnumJob ? "none" : "",
                      }}
                      value={profile?.job}
                      onChange={(e) => {
                        handleChangeProfile(e.target.value, "job");
                      }}
                    />
                    <DocumentSelectorAndValidator
                      profile={profile}
                      type="Diplome"
                      valid={profile?.validDiploma}
                      invalid={profile?.inValidateDiploma}
                      refreshParent={() => refreshParent()}
                    ></DocumentSelectorAndValidator>
                    {profile.job === JobHelpro.AUXILIAIRE ||
                    profile.job === JobHelpro.ASSISTANT ||
                    profile.job === JobHelpro.CAREGIVER ? (
                      <InputDropdown
                        value={profile.diplomaType}
                        handleChange={(e) => {
                          handleChangeProfile(e.value, "diplomaType");
                        }}
                        options={diplomaType}
                        customStyle={{
                          height: "36px",
                        }}
                        placeholder="Type de diplôme"
                      />
                    ) : null}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          marginBottom: "5px",
                          textDecoration: "underline",
                        }}
                      >
                        Est-ce un permis de travail ?
                      </p>
                      <RadioButton
                        onChange={handleChangeHasWorkLicence}
                        radioData={{
                          name: "hasWorkLicence",
                          value: "OUI, NON",
                        }}
                        profileInfo={profile?.hasWorkLicence ? "OUI" : "NON"}
                        textColor="black"
                      />
                      <h5
                        style={{
                          margin: "8px 0px 12px 0px",
                          textAlign: "center",
                        }}
                      >
                        Date de validité du permis
                      </h5>
                      <input
                        type="date"
                        value={profile?.workingLicenseValidityDate}
                        onChange={(e) => {
                          handleChangeProfile(
                            !!e.target.value ? e.target.value : null, // If date is an empty string, we save null in db,
                            "workingLicenseValidityDate"
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="CHECKBOXES2"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    margin: "16px 0px 8px 0px",
                    padding: "8px",
                    borderRadius: "8px",
                    backgroundColor: "#f7b0a6",
                  }}
                >
                  <div
                    className="CHECK_IDENTITÉ"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <h2
                      style={{
                        margin: "0px 0px 0px 4px",
                        color: "#F4F2F2",
                      }}
                    >
                      Identité
                    </h2>
                    <div style={{ display: "flex" }}>
                      <RadioButton
                        onChange={handleChangeRadio}
                        radioData={{
                          name: "identityAppreciation",
                          value: "TODO, OK, OK_AA, NOK_AA, NOK",
                        }}
                        profileInfo={profile?.identityAppreciation}
                      />
                    </div>
                  </div>
                  <div
                    className="CHECK_TRAVAIL"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <h2
                      style={{
                        margin: "0px 0px 0px 4px",
                        color: "#F4F2F2",
                      }}
                    >
                      Travail
                    </h2>
                    <div style={{ display: "flex" }}>
                      <RadioButton
                        onChange={handleChangeRadio}
                        radioData={{
                          name: "workAppreciation",
                          value: "TODO, OK, OK_AA, NOK_AA, NOK, ALERTE",
                        }}
                        profileInfo={profile?.workAppreciation}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="DISNONIBILITES_HELPRO">
              <h2>DISPONIBILITES</h2>
              <Availibility initialProfile={profile} />
            </div>
            <div className="INFOS_HELPRO">
              <h2>RENSEIGNEMENTS</h2>
              <div
                style={{
                  display: "flex",
                  border: "2px solid lightgrey",
                  borderRadius: "8px",
                  padding: "12px",
                }}
              >
                <div className="BIO">
                  <h5 style={{ margin: "5px 0px 5px 10px" }}>Bio</h5>
                  <InputArea
                    handleChange={(e) =>
                      handleChangeProfile(e.target.value, "bio")
                    }
                    value={profile?.bio}
                    name=""
                    customStyle={{ height: "200px" }}
                  />
                </div>
                <div className="EXPERIENCE">
                  <h5 style={{ margin: "5px 0px 5px 10px" }}>Expérience</h5>
                  <InputArea
                    value={profile?.bioExperience}
                    //name="Experience"
                    handleChange={(e) =>
                      handleChangeProfile(e.target.value, "bioExperience")
                    }
                    customStyle={{ height: "200px" }}
                  />
                </div>
                <div className="DIPLOMES">
                  <h5 style={{ margin: "5px 0px 5px 10px" }}>Diplôme</h5>
                  <InputArea
                    value={profile?.bioEducation}
                    handleChange={(e) =>
                      handleChangeProfile(e.target.value, "bioEducation")
                    }
                    //name="Cursus"
                    customStyle={{ height: "200px" }}
                  />
                </div>
              </div>
            </div>
            <div className="AISE_DEPENDANCE">
              <h2>SITUATIONS DE DÉPENDANCES</h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  border: "2px solid lightgrey",
                  borderRadius: "8px",
                  padding: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: 11,
                      margin: "0px 0px 0px 10px",
                      textDecoration: "underline",
                    }}
                  >
                    Sur l'origine de l'autonomie
                  </p>
                  {healthGrids?.autonomyLoss?.map((info, index) => {
                    return (
                      <div style={{ display: "inline-flex" }} key={index}>
                        <HighLightSwitch
                          isHighlighted={
                            profile?.autonomyLoss?.indexOf(info) !== -1
                          }
                          onClick={() => {
                            setAutonomy(info);
                          }}
                          text={HealthData[info].text}
                        />
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: 11,
                      margin: "0px 0px 0px 10px",
                      textDecoration: "underline",
                    }}
                  >
                    Sur la mobilité
                  </p>
                  {healthGrids?.mobilities?.map((info, index) => {
                    return (
                      <div style={{ display: "inline-flex" }} key={index}>
                        <HighLightSwitch
                          isHighlighted={
                            profile?.mobilities?.indexOf(info) !== -1
                          }
                          onClick={() => {
                            setMobilities(info);
                          }}
                          text={HealthData[info].text}
                        />
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: 11,
                      margin: "0px 0px 0px 10px",
                      textDecoration: "underline",
                    }}
                  >
                    Et la tête
                  </p>
                  {healthGrids?.mentalHealth?.map((info, index) => {
                    return (
                      <div style={{ display: "inline-flex" }} key={index}>
                        <HighLightSwitch
                          isHighlighted={
                            profile?.mentalHealth?.indexOf(info) !== -1
                          }
                          onClick={() => {
                            setMentalHealth(info);
                          }}
                          text={HealthData[info].text}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="SERVICES_PROPOSES">
              <h2>SERVICES PROPOSÉS</h2>
              <div
                style={{
                  border: "2px solid lightgrey",
                  borderRadius: "8px",
                  padding: "12px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div className="SERVICES" style={{ maxWidth: "50%" }}>
                    <h3>Prestations</h3>
                    <div>{displayCorrectActivities()}</div>
                  </div>
                  {profile?.activityField === NeedServicesProType.HOME_HELP && (
                    <div className="ACTIVITÉS" style={{ maxWidth: "50%" }}>
                      <h3>Activités proposées</h3>
                      <div>
                        {healthGrids?.servicesPro?.map((info, index) => {
                          return (
                            <div
                              style={{ display: "inline-block" }}
                              key={index}
                            >
                              <HighLightSwitch
                                isHighlighted={
                                  profile?.activities?.indexOf(info) !== -1
                                }
                                onClick={() => {
                                  setServicesHelpro(info);
                                }}
                                text={ServicesData[info].text}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div className="AIDE" style={{ maxWidth: "50%" }}>
                    <h3>Type d’aide proposé</h3>
                    <div>
                      {healthGrids?.neededServicesPro?.map((info, index) => {
                        return (
                          <div style={{ display: "inline-block" }} key={index}>
                            <HighLightSwitch
                              isHighlighted={profile?.activityField === info}
                              onClick={() => {
                                handleChangeProfile(info, "activityField");
                              }}
                              text={NeededServicesProData[info].text}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div
                  className="TARIFS"
                  style={{
                    maxWidth: "325px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      margin: "12px 0px",
                    }}
                  >
                    <h3 style={{ margin: "0px 0px 4px 0px" }}>Tarifs</h3>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Button
                        icon="pi pi-plus"
                        aria-label="add"
                        size="small"
                        style={{ width: "26px", height: "26px" }}
                        onClick={() => {
                          addRate();
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    {profile?.rates?.map((rate, index) => {
                      return (
                        <div>
                          <InputDiv
                            value={rate?.details}
                            //name="Nom"
                            handleChange={(e) =>
                              handleChangeRates(
                                e.target.value,
                                `details`,
                                index
                              )
                            }
                            placeholder={"Détail de la prestation"}
                            divStyle={{
                              margin: "8px 0 4px 0",
                            }}
                            customStyle={{
                              width: "100%",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              marginBottom: "4px",
                            }}
                          >
                            <InputDivNumber
                              value={rate.visits}
                              //name="visits"
                              handleChange={(e) =>
                                handleChangeRates(
                                  e.target.value,
                                  `visits`,
                                  index
                                )
                              }
                              customStyle={{
                                height: "36px",
                                width: "85px",
                              }}
                              suffix=" séance"
                            />
                            <InputDropdown
                              value={rate.duration}
                              //name="duration"
                              handleChange={(e) =>
                                handleChangeRates(
                                  e.target.value,
                                  `duration`,
                                  index
                                )
                              }
                              options={durationValues}
                              customStyle={{
                                width: "120px",
                                height: "36px",
                              }}
                            />
                            <InputDivNumber
                              value={rate.price}
                              //name="Prix"
                              handleChange={(e) =>
                                handleChangeRates(
                                  e.target.value,
                                  `price`,
                                  index
                                )
                              }
                              suffix=" €"
                              customStyle={{
                                height: "36px",
                                width: "65px",
                              }}
                            />
                            <Button
                              icon="pi pi-times"
                              aria-label="Cancel"
                              severity="danger"
                              size="small"
                              style={{
                                width: "26px",
                                height: "26px",
                                background: "#DC2626",
                                border: "#DC2626",
                                marginLeft: "10px",
                              }}
                              onClick={() => {
                                deleteRate(index);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="INFOS_COMPLEMENTAIRES">
              <h2>INFORMATIONS COMPLEMENTAIRES</h2>
              <div
                style={{
                  border: "2px solid lightgrey",
                  borderRadius: "8px",
                  padding: "12px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div className="SOFT_SKILLS" style={{ maxWidth: "50%" }}>
                    <h3 style={{ margin: "0px 10px" }}>Soft Skills</h3>
                    <div>
                      {HelperSoftSkillsList?.map((info, index) => {
                        return (
                          <div style={{ display: "inline-block" }} key={index}>
                            <HighLightSwitch
                              isHighlighted={
                                profile?.softSkills?.indexOf(info) !== -1
                              }
                              onClick={() => {
                                setSoftSkills(info);
                              }}
                              text={HelperSoftSkills[info].text}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className="PATHOLOGY_EXPERIENCE"
                    style={{ maxWidth: "50%" }}
                  >
                    <h3 style={{ margin: "0px 10px" }}>
                      Expériences avec des maladies
                    </h3>
                    <div>
                      {HelperExperienceList?.map((info, index) => {
                        return (
                          <div style={{ display: "inline-block" }} key={index}>
                            <HighLightSwitch
                              isHighlighted={
                                profile?.pathologyExperience?.indexOf(info) !==
                                -1
                              }
                              onClick={() => {
                                setPathologyExperience(info);
                              }}
                              text={HelperExperience[info].text}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className="HAS_TRANSPORT"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "0px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Agrément service à la personne
                    </p>
                    <div style={{ display: "flex", margin: "0 auto" }}>
                      <RadioButton
                        onChange={handleChangeRadioPersonalServiceApproval}
                        radioData={{
                          name: "personalServiceApproval",
                          value: "OUI, NON",
                        }}
                        profileInfo={
                          profile?.personalServiceApproval ? "OUI" : "NON"
                        }
                        textColor="black"
                      />
                    </div>
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "0px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Véhiculé
                    </p>
                    <div style={{ display: "flex", margin: "0 auto" }}>
                      <RadioButton
                        onChange={handleChangeRadioTransport}
                        radioData={{
                          name: "transport",
                          value: "OUI, NON",
                        }}
                        profileInfo={profile?.transport ? "OUI" : "NON"}
                        textColor="black"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Statistiques">
          <Stats
            profile={profile}
            profileInfo={statInfos}
            searchProfile={searchProfile}
            onChangeRange={(distance) => getInitialData(distance)}
          ></Stats>
        </TabPanel>
        <TabPanel header="Historique">
          <UpdatesHistory profileId={profile?.id} />
        </TabPanel>
        <TabPanel header="Commentaire" index="1">
          <h5>Historique</h5>
          <div
            style={{
              width: "100%",
              height: "300px",
              overflow: "auto",
            }}
            id="histo"
          >
            {displayComments()}
          </div>

          <div
            style={{
              width: "100%",
              marginTop: "30px",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span className="p-float-label">
              <InputText
                id="Ajouter un commentaire"
                style={{ width: "400px" }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <label htmlFor="Ajouter un commentaire">
                Ajouter un commentaire
              </label>
            </span>
            <Button
              className={"p-button-primary"}
              onClick={() => addComment()}
              label="Valider"
            />
          </div>
        </TabPanel>
        <TabPanel
          headerTemplate={deleteTemplate}
          style={{
            color: "red",
            margin: "auto",
            marginRight: "30px",
            cursor: "pointer",
          }}
          index={2}
        >
          <div
            style={{
              textAlign: "center",
              verticalAlign: "middle",
              height: "300px",
            }}
          >
            <div style={{ margin: "50px" }}>
              La suppression est définitive, êtes vous sur(e) de vouloir
              continuer?
              <br /> <br /> <br />
              <Button onClick={deleteAccount} className="p-button-danger">
                Supprimer
              </Button>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </>
  );
};

export default HelproRole;
