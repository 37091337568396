import React from "react";
import "./KpiDataView.css";

const KpiDataView = ({ bgColor, data, treeNodes = false }) => {
  return (
    <div
      style={{
        margin: "6px auto",
        display: "flex",
        flexDirection: "column",
        gap: 5,
        padding: 5,
      }}
    >
      {data &&
        data.map((item, index) => {
          if (
            treeNodes &&
            item.label !== "INSCRITS" &&
            item.label !== "INCOMPLETS" &&
            item.label !== "COMPLETS" &&
            item.label !== "SUPPRIMÉS" &&
            item.label !== "DÉSINSCRITS"
          ) {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginLeft: 20,
                  gap: 5,
                  marginTop: 5,
                }}
              >
                <i className="pi pi-arrow-right" style={{ color: bgColor }}></i>
                <div className="kpi-dv-container">
                  <div style={{ color: bgColor }}>{item.label}</div>
                  <p>{item.value}</p>
                </div>
              </div>
            );
          } else if (item.currency) {
            return (
              <div key={index} className="kpi-dv-container">
                <div style={{ color: bgColor }}>{item.label}</div>
                <p>{item.value} €</p>
              </div>
            );
          } else {
            return (
              <div key={index} className="kpi-dv-container">
                <div style={{ color: bgColor }}>{item.label}</div>
                <p>{item.value}</p>
              </div>
            );
          }
        })}
    </div>
  );
};

export default KpiDataView;
