import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentSelectorAndValidator from "./index";
import { setHideSubForm, setAddSubform } from "../../../store/reducers/general";
const mapStateToProps = (state) => ({});
// Actions

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setAddSubform, setHideSubForm }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentSelectorAndValidator);
