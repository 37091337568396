import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
import React from "react";
const InputDiv = (fieldName) => {
  return (
    <div
      style={{
        width: "100%",
        //marginTop: "0px",
        marginLeft: "10px",
        ...fieldName.divStyle,
      }}
    >
      <span className="p-float-label">
        <InputText
          disabled={fieldName.disabled}
          style={{
            ...fieldName.customStyle,
            color: "black",
            fontWeight: "bold",
            fontSize: 12,
            borderColor: "#A9A9A9",
            borderWidth: 1,
          }}
          id={fieldName.name}
          value={fieldName.value}
          onChange={fieldName.handleChange}
          placeholder={fieldName.placeholder}
        />
        <label htmlFor="birthDate">{fieldName.name}</label>
      </span>
    </div>
  );
};

InputDiv.propTypes = {};
InputDiv.defaultProps = {};
export default InputDiv;
