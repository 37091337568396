import React from "react";
import PropTypes from "prop-types";

const BlackDiv = ({ actions }) => {
  // const closePopup = () => {
  //   actions.setHideSubForm();
  // };

  return (
    <>
      <div
        className="blackDiv"
        onClick={() => {
          // closePopup(); /* Disabled closed temporarily  because no constistency when we click on cross icon to close the modal (refresh parent data) and here */
        }}
      />
    </>
  );
};

BlackDiv.propTypes = {
  actions: PropTypes.object.isRequired,
  // modalGlobaldivsDisplay: PropTypes.object,
};

BlackDiv.defaultProps = {};

export default BlackDiv;
