import { React, useEffect, useState } from "react";
import axios from "../../services/axios/Axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import DetailedMission from "./DetailedMission/DetailedMission";
import dayjs from "dayjs";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

const Missions = (props) => {
  const [missions, setMissions] = useState([]);

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const initialState = {
    first: 0,
    rows: 10,
    page: 0,
    sortField: "mission.createdAt",
    sortOrder: "DESC",
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "mission.createdAt": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "mission.id": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "mission.title": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "mission.createdBy": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    },
  };
  const sortOrderToString = (order) => {
    const stringOrder = order === 1 ? "ASC" : "DESC";
    return stringOrder;
  };

  const [loading, setLoading] = useState(true);
  const [lazystate, setLazystate] = useState(initialState);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);

  const getData = async (pageOptions, takeOption) => {
    setLoading(true);

    const take = pageOptions.rows ? pageOptions.rows : 10;
    setRows(pageOptions.rows);
    const filterBody = {};
    const filtersKeys = Object.keys(pageOptions.filters);
    for (let i = 0; i < filtersKeys.length; i++) {
      // exclude role from global filters and check if value exists for each column
      if (
        filtersKeys[i] !== "global" &&
        pageOptions.filters?.[filtersKeys[i]]?.constraints?.[0].value
      ) {
        filterBody[filtersKeys[i]] = pageOptions.filters?.[
          filtersKeys[i]
        ]?.constraints.map((filter) => filter.value);
        if (pageOptions.filters?.[filtersKeys[i]]?.constraints?.[0].flatten) {
          filterBody[filtersKeys[i]] = filterBody[filtersKeys[i]].flat();
        }
      }
    }

    const globalSearchQuery = pageOptions.filters?.global?.value
      ? "&search=" + pageOptions.filters?.global?.value
      : "";
    const orderQuery = pageOptions.sortOrder
      ? "&order=" + sortOrderToString(pageOptions.sortOrder)
      : "";
    const sortQuery = pageOptions.sortField
      ? "&sort=" + pageOptions.sortField
      : "";

    axios
      .get(
        `/missions?page=${takeOption ? 1 : pageOptions.page + 1}&take=${take}` +
          orderQuery +
          sortQuery +
          globalSearchQuery,
        filterBody
      )
      .then((response) => {
        setMissions(response.data.data);
        setLoading(false);
        setTotalRecords(response?.data?.meta?.itemCount);
      });
  };
  useEffect(() => {
    getData(lazystate);
  }, []);

  const onSort = (event) => {
    /*  console.log("sort", event); */
    event["first"] = 0;
    event["page"] = 0;
    setLazystate(event);
    getData(event);
  };
  const onFilter = (event) => {
    // console.log("filter", event);
    event["first"] = 0;
    event["page"] = 0;
    setLazystate(event);
    getData(event);
  };
  const onPage = (event) => {
    // console.log("filter", event);
    setLazystate(event);
    getData(event);
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...initialState.filters };

    _filters["global"].value = value;
    const stateWithSearch = { ...initialState, filters: _filters };
    setLazystate(stateWithSearch);
    setGlobalFilterValue(value);
    getData(stateWithSearch);
  };

  const clearFilter = () => {
    setLazystate(initialState);
    setGlobalFilterValue("");
    getData(initialState);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div>
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Clear"
            onClick={clearFilter}
          />
        </div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Recherche"
          />
        </span>
      </div>
    );
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("detailedMissionId")) {
      const detailedMissionId = urlParams.get("detailedMissionId");
      const createdById = urlParams.get("createdById");
      const creatorRole = urlParams.get("creatorRole");

      props.actions.setAddSubform({
        component: DetailedMission,
        data: {
          id: detailedMissionId,
          creatorId: createdById,
          creatorRole: creatorRole,
        },
        actions: props.actions,
      });
    }
  }, []);

  return (
    <div
      style={{
        margin: "50px auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <h1>Missions Urgentes</h1>
      <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "center",
          height: "650px",
        }}
      >
        <DataTable
          header={renderHeader()}
          value={missions}
          dataKey="id"
          scrollable
          scrollHeight="flex"
          showGridlines
          stripedRows
          className="dataStyle"
          paginator
          paginatorPosition="bottom"
          paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="{first} à {last} sur un total de {totalRecords}"
          rows={rows}
          rowsPerPageOptions={[5, 10, 25, 50]}
          lazy
          lazystate={lazystate}
          first={lazystate.first}
          onSort={onSort}
          onPage={onPage}
          sortField={lazystate.sortField}
          sortOrder={lazystate.sortOrder}
          onFilter={onFilter}
          totalRecords={totalRecords}
          filter="true"
          filters={lazystate.filters}
          globalFilterFields={[
            "missions.name",
            "missions.id",
            "missions.createdAt",
          ]}
          onSelectionChange={(e) => {
            props.actions.setAddSubform({
              component: DetailedMission,
              data: {
                id: e.value.id,
                creatorId: e.value.createdBy.id,
                creatorRole: e.value.createdBy.role,
                //updateParent: () => getMissions(),
              },
              actions: props.actions,
            });
          }}
          selectionMode="single"
          loading={loading}
        >
          <Column
            field="createdAt"
            header="Date de création"
            style={{ width: "50px" }}
            filter
            body={(rowData) => {
              return <div>{rowData?.createdAt.split("T")[0]}</div>;
            }}
            sortable
          ></Column>
          <Column
            body={(rowData) => {
              if (Array.isArray(rowData.dates) && rowData.dates.length > 0) {
                return dayjs(rowData.dates[0].startDate).format("DD/MM/YYYY");
              }
              return dayjs(rowData.dates.startDate).format("DD/MM/YYYY");
            }}
            header="Première date"
            filter
          ></Column>
          <Column
            field="id"
            header="ID mission"
            filter
            sortable
            style={{ width: "20px" }}
          ></Column>
          <Column field="title" header="Titre" filter></Column>
          <Column field="createdBy.id" header="ID Helpee" filter></Column>
          <Column field="createdBy.firstName" header="Prénom" filter></Column>
          <Column field="createdBy.lastName" header="Nom" filter></Column>
          <Column
            field="createdBy.zipCode"
            header="Code postal"
            filter
          ></Column>
          <Column
            body={(rowData) => {
              return rowData.applications.length;
            }}
            header="Nb de candidatures"
            filter
          ></Column>
          <Column
            field="missionType"
            header="Type"
            body={(rowData) => {
              if (rowData.missionType === "ponctual") {
                return "Ponctuelle";
              } else if (rowData.missionType === "defined_period") {
                return "Périodique";
              } else {
                return "Récurrente";
              }
            }}
            filter
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default Missions;
