import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import axios from "../../../services/axios/Axios";

import { Button } from "primereact/button";
import ViewFile from "../ViewFile";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { UpdateOrigin } from "../../../services/enums";

const DocumentSelectorAndValidator = (props) => {
  const toast = useRef(null);
  const previewDocument = async () => {
    const config = { responseType: "blob" };
    let response;
    try {
      switch (props?.type) {
        case "FrontID":
          response = await axios.get(
            "/profiles/" + props?.profile?.id + "/identity-card/front",
            config
          );
          if (response === undefined) throw new Error("Introuvable");
          props?.actions.setAddSubform({
            component: ViewFile,
            data: { blob: URL.createObjectURL(response?.data), resp: response },
            actions: props?.actions,
          });
          break;
        case "BackID":
          response = await axios.get(
            "/profiles/" + props?.profile?.id + "/identity-card/back",
            config
          );
          if (response === undefined) throw new Error("Introuvable");

          props?.actions.setAddSubform({
            component: ViewFile,
            data: { blob: URL.createObjectURL(response?.data), resp: response },
            actions: props?.actions,
          });
          break;
        case "Diplome":
          response = await axios.get(
            "/profiles/" + props?.profile?.id + "/diploma",
            config
          );
          if (response === undefined) throw new Error("Introuvable");

          props?.actions.setAddSubform({
            component: ViewFile,
            data: { blob: URL.createObjectURL(response?.data), resp: response },
            actions: props?.actions,
          });
          break;
        case "KBIS":
          response = await axios.get(
            "/profiles/" + props?.profile?.id + "/kbis",
            config
          );
          if (response === undefined) throw new Error("Introuvable");

          props?.actions.setAddSubform({
            component: ViewFile,
            data: { blob: URL.createObjectURL(response?.data), resp: response },
            actions: props?.actions,
          });
          break;
        case "Casier Jud.":
          response = await axios.get(
            "/profiles/" + props?.profile?.id + "/criminal-record",
            config
          );
          if (response === undefined) throw new Error("Introuvable");

          props?.actions.setAddSubform({
            component: ViewFile,
            data: { blob: URL.createObjectURL(response?.data), resp: response },
            actions: props?.actions,
          });
          break;

        case "CV":
          response = await axios.get(
            "/profiles/" + props?.profile?.id + "/curriculum-vitae",
            config
          );
          props?.actions.setAddSubform({
            component: ViewFile,
            data: { blob: URL.createObjectURL(response?.data), resp: response },
            actions: props?.actions,
          });
          break;
        default:
          break;
      }
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Document introuvable",
        detail: "Le document n'existe pas sur azure",
        life: 3000,
      });
    }
  };

  const validateDocument = async () => {
    switch (props?.type) {
      case "FrontID":
        await axios
          .get(`/profiles/validateFrontID/` + props.profile.id)
          .then((resp) => {
            if (resp.status === 200) {
              props.refreshParent();
            }
          });
        break;
      case "BackID":
        await axios
          .get(`/profiles/validateBackID/` + props.profile.id)
          .then((resp) => {
            if (resp.status === 200) {
              props.refreshParent();
            }
          });
        break;
      case "Diplome":
        await axios
          .get(`/profiles/validateDiploma/` + props.profile.id)
          .then((resp) => {
            if (resp.status === 200) {
              props.refreshParent();
            }
          });
        break;
      case "KBIS":
        await axios
          .get(`/profiles/validateKbis/` + props.profile.id)
          .then((resp) => {
            if (resp.status === 200) {
              props.refreshParent();
            }
          });
        break;
      case "Casier Jud.":
        await axios
          .get(`/profiles/validateCR/` + props.profile.id)
          .then((resp) => {
            if (resp.status === 200) {
              props.refreshParent();
            }
          });
        break;
      case "CV":
        await axios
          .get(`/profiles/validateCV/` + props.profile.id)
          .then((resp) => {
            if (resp.status === 200) {
              props.refreshParent();
            }
          });
        break;
      default:
    }
  };

  const invalidateDocument = async () => {
    switch (props?.type) {
      case "FrontID":
        await axios
          .get(`/profiles/inValidateFrontID/` + props.profile.id)
          .then((resp) => {
            if (resp.status === 200) {
              props.refreshParent();
            }
          });
        break;
      case "BackID":
        await axios
          .get(`/profiles/inValidateBackID/` + props.profile.id)
          .then((resp) => {
            if (resp.status === 200) {
              props.refreshParent();
            }
          });
        break;
      case "Diplome":
        await axios
          .get(`/profiles/inValidateDiploma/` + props.profile.id)
          .then((resp) => {
            if (resp.status === 200) {
              props.refreshParent();
            }
          });
        break;
      case "KBIS":
        await axios
          .get(`/profiles/inValidateKbis/` + props.profile.id)
          .then((resp) => {
            if (resp.status === 200) {
              props.refreshParent();
            }
          });
        break;
      case "Casier Jud.":
        await axios
          .get(`/profiles/inValidateCR/` + props.profile.id)
          .then((resp) => {
            if (resp.status === 200) {
              props.refreshParent();
            }
          });
        break;
      case "CV":
        await axios
          .get(`/profiles/inValidateCV/` + props.profile.id)
          .then((resp) => {
            if (resp.status === 200) {
              props.refreshParent();
            }
          });
        break;
      default:
    }
  };

  const uploadHandler = async (uploadInfo) => {
    const file = uploadInfo.files[0];
    if (!file.isUploaded) {
      const formData = new FormData();

      switch (props?.type) {
        case "FrontID":
          formData.append("front", file);
          formData.append("updateOrigin", UpdateOrigin.BACK_OFFICE);
          await axios.post(
            "/profiles/" + props?.profile?.id + "/front-identity-card",
            formData
          );
          break;
        case "BackID":
          formData.append("back", file);
          formData.append("updateOrigin", UpdateOrigin.BACK_OFFICE);
          await axios.post(
            "/profiles/" + props?.profile?.id + "/back-identity-card",
            formData
          );
          break;
        case "Diplome":
          formData.append("diploma", file);
          formData.append("updateOrigin", UpdateOrigin.BACK_OFFICE);
          await axios.post(
            "/profiles/" + props?.profile?.id + "/diploma",
            formData
          );

          break;
        case "KBIS":
          formData.append("kbis", file);
          formData.append("updateOrigin", UpdateOrigin.BACK_OFFICE);
          await axios.post(
            "/profiles/" + props?.profile?.id + "/kbis",
            formData
          );

          break;
        case "Casier Jud.":
          formData.append("cr", file);
          formData.append("updateOrigin", UpdateOrigin.BACK_OFFICE);
          await axios.post(
            "/profiles/" + props?.profile?.id + "/criminal-record",
            formData
          );

          break;

        default:
          break;
      }
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <tr>
        <td>
          <Button
            style={{
              width: "150px",
              height: "24px",
              margin: "0px 0px 5px 0px",
            }}
            label={props?.type}
            className="p-button-outlined p-button-secondary"
            onClick={() => previewDocument()}
          />{" "}
        </td>
        <td>
          {" "}
          <Button
            icon="pi pi-check"
            style={{ height: "24px", width: "24px", margin: "5px" }}
            className={
              props?.valid === true ? "p-button-success" : "p-button-secondary"
            }
            onClick={() => validateDocument()}
          />
        </td>
        <td>
          {" "}
          <Button
            icon="pi pi-times"
            style={{ height: "24px", width: "24px", margin: "5px" }}
            className={"p-button-danger"}
            onClick={() => invalidateDocument()}
          />
        </td>
        <td
          style={{
            verticalAlign: "middle",
            width: "36px",
            textAlign: "center",
          }}
        >
          <FileUpload
            customUpload
            multiple={false}
            withCredentials
            mode="basic"
            auto
            uploadHandler={uploadHandler}
            chooseLabel
          />
        </td>
      </tr>
    </>
  );
};

DocumentSelectorAndValidator.propTypes = {};
DocumentSelectorAndValidator.defaultProps = {};
export default DocumentSelectorAndValidator;
