import { AuthenticationContext, adalFetch } from "react-adal";

export const adalConfig = {
  clientId: "495b14ec-0d72-4e07-be86-914f8b84f7fb", // id de l'application créé ci-dessus
};

export const authContext = new AuthenticationContext(adalConfig);
let cachedToken;
// Méthode de récupération du token
export const getToken = () => {
  if (
    localStorage.getItem(
      "adal.expiration.key495b14ec-0d72-4e07-be86-914f8b84f7fb"
    ) > Math.round(Date.now() / 1000, 0)
  ) {
    cachedToken = localStorage.getItem("adal.idtoken");
  } else {
    cachedToken = authContext.getCachedToken(authContext.config.clientId);
  }
  if (cachedToken === undefined) {
    authContext.acquireToken(authContext.config.clientId);
  } else {
    return cachedToken;
  }
  return null;
};

// Méthode permettant d'exécuter un appel via la librairie ADAL
export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
