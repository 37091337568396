import axios from "../../../services/axios/Axios";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Avatar } from "primereact/avatar";
import picto from "../../../images/picto_hq.png";
import { Toast } from "primereact/toast";
import HighLightSwitch from "../../Base/HighLightSwitch";
import { isArray } from "lodash";
import cityList from "../../../utils/france.json";
import HelperRole from "./HelperRole";
import HelproRole from "./HelproRole";
import HelpeeRole from "./HelpeeRole";
import {
  ActivityData,
  HealthData,
  NeededServicesData,
  NeededServicesProData,
  ServicesData,
  UpdateOrigin,
  durationValues,
  healthGrids,
  relationOptions,
  savUpdateType,
} from "../../../services/enums";
import InputDropdown from "../../Base/InputDropdown";

const DetailedProfil = (props) => {
  const [profile, setProfile] = useState(null);
  const [user, setUser] = useState(null);
  const toast = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [arrangedData, setArrangedData] = useState([]);
  const [avatar, setAvatar] = useState();
  const [tutorAvatar, setTutorAvatar] = useState();
  const [tutorTemplate, setTutorTemplate] = useState();
  const [userTemplate, setUserTemplate] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [statInfos, setStatInfo] = useState();
  const [searchProfile, setSearchProfile] = useState();
  // eslint-disable-next-line no-unused-vars
  const [healthInfos, setHealthInfos] = useState({
    autonomyLoss: profile?.autonomyLoss ?? [],
    mobilities: profile?.mobilities ?? [],
    mentalHealth: profile?.mentalHealth ?? [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [reviewsCount, setReviewsCount] = useState(0);
  const [hasSavedSavStatus, setHasSavedSavStatus] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [comment, setComment] = useState();
  const [city, setCity] = useState("");
  const [savOperations, setSavOperations] = useState({
    savUpdateType: "",
    savUpdateOrigin:
      props?.data?.originParam === "dashboard" ? "dashboard" : "regular",
  });

  const handleCreateSav = (value) => {
    setSavOperations({ ...savOperations, savUpdateType: value });
  };

  // City and zipcode Validator
  const filteredCityByZipCode = (zipCode) => {
    const foundCity = cityList
      .filter((e) => e.Code_postal.toString() === zipCode)
      .map((el) => {
        if (el.Ligne_5 !== "") {
          return el.Ligne_5;
        } else {
          return el.Nom_commune;
        }
      })[0];
    if (foundCity) {
      return foundCity;
    }
    return "Ville Inconnue"; // Return an empty string if no matching city is found
  };

  // eslint-disable-next-line no-unused-vars
  const MobilitySwitch = ({ healthGrid, setHealthInfos }) => {
    return (
      <div>
        {healthGrid?.map((info, index) => {
          let hightlight = false;
          if (isArray(profile.mobilities)) {
            if (profile?.mobilities?.findIndex((e) => e === info) >= 0) {
              hightlight = true;
            }
          }

          return (
            <div style={{ marginVertical: 8 }} key={index}>
              <HighLightSwitch
                isHighlighted={hightlight}
                onClick={() => {
                  setHealthInfos(info);
                }}
                text={HealthData[info].text}
              />
            </div>
          );
        })}
      </div>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const desactiveAccount = () => {
    axios.put("/users/lock/" + props.data.id).then((resp) => {
      refreshParent();
    });
  };

  // eslint-disable-next-line no-unused-vars
  const reactiveAccount = () => {
    axios.put("/users/unlock/" + props.data.id).then((resp) => {
      refreshParent();
    });
  };

  const deleteAccount = () => {
    axios.delete("/users/delete/" + props.data.id).then((resp) => {
      alert("Compte supprimé !");
      refreshParent();
    });
  };
  const getInitialData = async (range) => {
    axios.get("/users/" + props.data.id).then((resp) => {
      setUser(resp.data.user);
    });
    axios
      .get(`/profiles/complexProfile/` + props.data.id)
      .then((resp) => {
        setStatInfo(resp.data.profile);
        setProfile(resp.data.profile.profile);
        setCity(resp.data.profile.profile.city);
        setArrangedData(Object.keys(resp.data.profile.profile));

        let URL;
        switch (resp.data.profile.profile.role) {
          case "HELPEE":
            URL = "roles=HELPER&&roles=HELPRO";
            break;
          default:
            URL = "roles[]=HELPEE";
        }
        //needs
        if (resp.data.profile.profile.neededServices.length === 1) {
          URL += "&&needs[]=" + resp.data.profile.profile.neededServices;
        } else {
          for (
            let i = 0;
            i < resp.data.profile.profile.neededServices?.length;
            i++
          ) {
            URL += "&&needs=" + resp.data.profile.profile.neededServices[i];
          }
        }
        //mobilities
        if (resp.data.profile.profile.mobilities?.length === 1) {
          URL += "&&mobilities[]=" + resp.data.profile.profile.mobilities;
        } else {
          for (
            let i = 0;
            i < resp.data.profile.profile.mobilities.length;
            i++
          ) {
            URL += "&&mobilities=" + resp.data.profile.profile.mobilities[i];
          }
        }
        //autonomyLoss
        if (resp.data.profile.profile.autonomyLoss?.length === 1) {
          URL += "&&autonomyLoss[]=" + resp.data.profile.profile.autonomyLoss;
        } else {
          for (
            let i = 0;
            i < resp.data.profile.profile.autonomyLoss.length;
            i++
          ) {
            URL +=
              "&&autonomyLoss=" + resp.data.profile.profile.autonomyLoss[i];
          }
        }
        //mentalHealth
        if (resp.data.profile.profile.mentalHealth.length === 1) {
          URL += "&&mentalHealth[]=" + resp.data.profile.profile.mentalHealth;
        } else {
          for (
            let i = 0;
            i < resp.data.profile.profile.mentalHealth.length;
            i++
          ) {
            URL +=
              "&&mentalHealth=" + resp.data.profile.profile.mentalHealth[i];
          }
        }

        //price
        URL += "&&price=300";

        //range
        URL += `&&range=${range ?? 20}`;

        axios
          .get(
            "search/viewResultProfiles/" +
              resp.data.profile.profile.userId +
              "?" +
              URL
          )
          .then((resp) => {
            if (resp?.status !== undefined) {
              setSearchProfile(resp.data.profilesList);
            }
          });

        axios
          .get("/profiles/" + resp.data.profile.profile.id + "/avatar", {
            responseType: "blob",
          })
          .then((resp) => {
            if (resp?.status !== undefined) setAvatar(resp.data);
          });
        if (resp.data.profile.profile.role === "HELPEE") {
          axios
            .get(
              "/profiles/" + resp.data.profile.profile.id + "/tutor-avatar",
              {
                responseType: "blob",
              }
            )
            .then((resp) => {
              if (resp?.status !== undefined) setTutorAvatar(resp.data);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isLoaded === false) {
      getInitialData();
      setIsLoaded(true);
    }
    return () => {
      setProfile(null);
      setArrangedData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profile?.id) {
      axios
        .get("/profiles/" + profile?.id + "/reviews")
        .then((resp) => {
          setReviewsCount(resp.data.reviews.length);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [profile]);

  useEffect(() => {
    setUserTemplate(
      <div
        onClick={() => {
          setActiveTab(
            profile?.role === "HELPER"
              ? 0
              : profile?.role === "HELPRO"
              ? 0
              : profile.role === "HELPEE"
              ? profile.relation === "Me"
                ? 1
                : 1
              : ""
          );
        }}
        className="flex align-items-center px-3"
        style={{ cursor: "pointer" }}
      >
        {avatar !== undefined ? (
          <Avatar
            image={URL.createObjectURL(avatar)}
            shape="circle"
            className="mx-2"
          />
        ) : (
          <Avatar image={picto} shape="circle" className="mx-2" />
        )}
        {profile?.firstName + " " + profile?.lastName}
      </div>
    );
  }, [avatar, profile]);

  useEffect(() => {
    setTutorTemplate(
      <div
        onClick={() => {
          setActiveTab(0);
          console.log(activeTab);
        }}
        className="flex align-items-center px-3"
        style={{ cursor: "pointer" }}
      >
        {tutorAvatar !== undefined ? (
          <Avatar
            image={URL.createObjectURL(tutorAvatar)}
            shape="circle"
            className="mx-2"
          />
        ) : (
          <Avatar image={picto} shape="circle" className="mx-2" />
        )}
        {profile?.tutorFirstName + " " + profile?.tutorLastName}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutorAvatar, profile]);

  const deleteTemplate = (e) => {
    return (
      <div
        onClick={() => {
          setActiveTab(e.index);
          console.log(activeTab);
        }}
        style={{ color: "red", fontWeight: 700 }}
      >
        Supprimer
      </div>
    );
  };

  const addComment = () => {
    const data = {
      id: profile.userId,
      modifiedBy: props.general.decoded.name,
      comment: comment,
    };
    axios.put("/users/addComment", data).then((resp) => {
      refreshParent();
    });

    setComment("");
  };

  const deleteComment = (comment) => {
    const data = {
      id: profile.userId,
      comment: comment,
    };
    axios
      .put("/users/deleteComment", {
        data,
      })
      .then((resp) => {
        refreshParent();
      });
  };

  // eslint-disable-next-line no-unused-vars
  const getValue = (r, t) => {
    for (const i in r)
      if (r.hasOwnProperty(i) && i.toString().startsWith(t)) return r[i];
  };

  // HANDLE SELECT FIELDS

  const setAutonomy = (value, info) => {
    if (profile.role === "HELPER" || profile.role === "HELPRO") {
      let autonomyLoss = profile.autonomyLoss;
      if (autonomyLoss.includes(value)) {
        autonomyLoss = autonomyLoss.filter((item) => item !== value);
        handleChangeProfile(autonomyLoss, "autonomyLoss");
      } else {
        autonomyLoss.push(value);
        handleChangeProfile(autonomyLoss, "autonomyLoss");
      }
    } else {
      //limit one choice for helpee bruh

      handleChangeProfile([value], "autonomyLoss");
      //  setHealthInfos((state) => ({ ...state, [info]: [value] }));
    }
  };

  const setServicesHelpro = (value, info) => {
    console.log(value, info);
    if (profile.role === "HELPRO") {
      let servicesPro = profile.activities;
      console.log("CHICKA", servicesPro, value);
      if (servicesPro.includes(value)) {
        servicesPro = servicesPro.filter((item) => item !== value);
        handleChangeProfile(servicesPro, "activities");
      } else {
        servicesPro.push(value);
        handleChangeProfile(servicesPro, "activities");
      }
    } else {
      handleChangeProfile(value, "servicesPro");
    }
  };

  // Set needed services select
  const setNeededServices = (value, info) => {
    let neededServices = profile.neededServices;
    if (neededServices.includes(value)) {
      neededServices = neededServices.filter((item) => item !== value);
      handleChangeProfile(neededServices, "neededServices");
    } else {
      neededServices.push(value);
      handleChangeProfile(neededServices, "neededServices");
    }
  };

  const setActivity = (value, info) => {
    if (profile.role === "HELPEE" || profile.role === "HELPER") {
      let activities = profile.activities;
      if (activities.includes(value)) {
        activities = activities.filter((item) => item !== value);
        handleChangeProfile(activities, "activities");
      } else {
        activities.push(value);
        handleChangeProfile(activities, "activities");
      }
    }
  };

  const setSoftSkills = (value, info) => {
    if (profile.role === "HELPER" || profile.role === "HELPRO") {
      let softSkills = profile.softSkills;
      if (softSkills.includes(value)) {
        softSkills = softSkills.filter((item) => item !== value);
        handleChangeProfile(softSkills, "softSkills");
      } else {
        if (softSkills.length >= 3) {
          toast.current.show({
            severity: "error",
            summary: "Erreur",
            detail: "Vous ne pouvez pas sélectionner plus de 3 soft skills",
            life: 3000,
          });
          return;
        }
        softSkills.push(value);
        handleChangeProfile(softSkills, "softSkills");
      }
    }
  };

  const setPathologyExperience = (value, info) => {
    if (profile.role === "HELPER" || profile.role === "HELPRO") {
      let pathologyExperience = profile.pathologyExperience;
      if (pathologyExperience.includes(value)) {
        pathologyExperience = pathologyExperience.filter(
          (item) => item !== value
        );
        handleChangeProfile(pathologyExperience, "pathologyExperience");
      } else {
        pathologyExperience.push(value);
        handleChangeProfile(pathologyExperience, "pathologyExperience");
      }
    }
  };

  const setMentalHealth = (value, info) => {
    if (profile.role === "HELPER" || profile.role === "HELPRO") {
      let mentalHealth = profile.mentalHealth;
      if (mentalHealth.includes(value)) {
        mentalHealth = mentalHealth.filter((item) => item !== value);
        handleChangeProfile(mentalHealth, "mentalHealth");
      } else {
        mentalHealth.push(value);
        handleChangeProfile(mentalHealth, "mentalHealth");
      }
    } else {
      handleChangeProfile([value], "mentalHealth");
    }
  };

  const setMobilities = (value, info) => {
    if (profile.role === "HELPER" || profile.role === "HELPRO") {
      let mobilities = profile.mobilities;
      if (mobilities.includes(value)) {
        mobilities = mobilities.filter((item) => item !== value);
        handleChangeProfile(mobilities, "mobilities");
      } else {
        mobilities.push(value);
        handleChangeProfile(mobilities, "mobilities");
      }
    } else {
      handleChangeProfile([value], "mobilities");
    }
  };

  const refreshParent = () => {
    setTimeout(() => {
      getInitialData();
    }, 500);
  };
  const updateProfile = async () => {
    setIsLoading(true);
    // updateProfile except the idendityDocuments
    const updatedProfile = {
      ...profile,
      identityDocumentFrontUUID: "deleteThisKeyToUpdate",
      identityDocumentBackUUID: "deleteThisKeyToUpdate",
      kbisUUID: "deleteThisKeyToUpdate",
      curriculumVitaeUUID: "deleteThisKeyToUpdate",
      criminalRecordUUID: "deleteThisKeyToUpdate",
      diplomaUUID: "deleteThisKeyToUpdate",
    };
    for (const key in updatedProfile) {
      if (updatedProfile[key] === "deleteThisKeyToUpdate") {
        delete updatedProfile[key];
      }
    }

    if (
      profile.hasBeenProcessed &&
      savOperations.savUpdateType !== "" &&
      !hasSavedSavStatus
    ) {
      setHasSavedSavStatus(true);
      axios.post(`/sav/create/${profile?.id}`, savOperations).then((res) => {
        console.log("SAV CREATED", res);
      });
    }

    axios
      .put("/profiles/" + profile.id, {
        data: {
          ...updatedProfile,
        },
      })
      .then((resp) => {
        console.log(resp);
        toast.current.show({
          severity: "success",
          summary: "Sauvegarde Ok",
          detail: "Les champs ont été mis à jour",
          life: 3000,
        });
        setIsLoading(false);
      });
  };

  const markAsProcessed = () => {
    setProfile({ ...profile, hasBeenProcessed: !profile.hasBeenProcessed });
  };
  const markAsPriorityProfileSav = () => {
    setProfile({
      ...profile,
      hasPriorityStatusForSav: !profile.hasPriorityStatusForSav,
    });
  };

  const uploadHandlerTutorAvatar = async (uploadInfo) => {
    const file = uploadInfo.files[0];
    if (!file.isUploaded) {
      const formData = new FormData();

      formData.append("avatar", file);
      formData.append("updateOrigin", UpdateOrigin.BACK_OFFICE);
      await axios
        .post("/profiles/" + profile?.id + "/tutor-avatar", formData)
        .then((resp) => {
          if (resp.status === 200) {
            refreshParent();
          }
        });
    }
  };
  const handleChangeProfile = (value, field) => {
    if (field === "zipCode") {
      const cityToUpdate = filteredCityByZipCode(value);
      setProfile({
        ...profile,
        [field]: value,
        city: cityToUpdate,
      });
    } else {
      setProfile({ ...profile, [field]: value });
    }
  };

  const handleChangeRates = (value, field, index) => {
    const newRates = [...profile.rates];
    newRates[index][field] = value;
    setProfile({ ...profile, rates: newRates });
  };

  const handleChangeRadio = (value, field) => {
    if (value === "NOK") {
      window.confirm("Etes vous sur de vouloir supprimer ce profil ?")
        ? setProfile({ ...profile, [field]: value })
        : window.alert("Veillez à bien décocher la case avant de sauvegarder");
    } else {
      setProfile({ ...profile, [field]: value });
    }
  };

  const handleChangeRadioPersonalServiceApproval = (value, field) => {
    var personalServiceApproval = false;
    if (value === "OUI") {
      personalServiceApproval = true;
    }
    setProfile({ ...profile, personalServiceApproval });
  };

  const handleChangeRadioVaccationMode = (value, field) => {
    let vaccationMode = false;
    if (value === "OUI") {
      vaccationMode = true;
    }
    setProfile({ ...profile, vaccationMode });
  };

  const handleChangeHasWorkLicence = (value, field) => {
    let hasWorkLicence = false;
    if (value === "OUI") {
      hasWorkLicence = true;
    }
    setProfile({ ...profile, hasWorkLicence });
  };

  const handleChangeRadioTransport = (value, field) => {
    var transport = false;
    if (value === "OUI") {
      transport = true;
    }
    setProfile({ ...profile, transport });
  };

  const addRate = () => {
    const newRates = profile.rates ? [...profile.rates] : [];
    newRates.push({ price: 15, visits: 1, duration: "1h", details: "" });
    setProfile({ ...profile, rates: newRates });
  };
  const deleteRate = (index) => {
    const newRates = [...profile.rates];
    newRates.splice(index, 1);
    setProfile({ ...profile, rates: newRates });
  };

  const uploadHandlerAvatar = async (uploadInfo) => {
    const file = uploadInfo.files[0];
    if (!file.isUploaded) {
      const formData = new FormData();

      formData.append("avatar", file);
      formData.append("updateOrigin", UpdateOrigin.BACK_OFFICE);
      await axios
        .post("/profiles/" + profile?.id + "/avatar", formData)
        .then((resp) => {
          if (resp.status === 200) {
            refreshParent();
          }
        });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      {isLoading === false && (
        <>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <i
              className="pi pi-save"
              style={{ fontSize: "1.3rem" }}
              onClick={updateProfile}
            ></i>
            <div
              style={{
                position: "absolute",
                Zindex: 9,
                top: 0,
                right: 100,
                display: "flex",
                alignItems: "center",
                gap: 10,
                cursor: "pointer",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={markAsProcessed}
              >
                <i
                  className="pi pi-check-circle"
                  style={{
                    fontSize: "1.3rem",
                    color: profile?.hasBeenProcessed ? "green" : "red",
                  }}
                ></i>
                <p
                  style={{ color: profile?.hasBeenProcessed ? "green" : "red" }}
                >
                  Profil traité SAV
                </p>
              </div>
              <InputDropdown
                value={savOperations.savUpdateType}
                handleChange={(e) => {
                  console.log("VALUE", e.value);
                  handleCreateSav(e.value);
                }}
                options={savUpdateType}
                customStyle={{
                  height: "36px",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                Zindex: 9,
                top: 7,
                left: 200,
                display: "flex",
                alignItems: "center",
                gap: 10,
                cursor: "pointer",
              }}
              onClick={markAsPriorityProfileSav}
            >
              <i
                className="pi pi-exclamation-circle"
                style={{
                  fontSize: "1.3rem",
                  color: profile?.hasPriorityStatusForSav ? "green" : "red",
                }}
              ></i>
              <p
                style={{
                  color: profile?.hasPriorityStatusForSav ? "green" : "red",
                }}
              >
                Profil prioritaire
              </p>
            </div>
            {profile?.deleted && !profile?.selfDeleted ? (
              <>
                <i
                  className="pi pi-user-minus"
                  style={{ fontSize: "1.3rem" }}
                ></i>
                <i
                  className="pi pi-trash"
                  style={{ fontSize: "1.3rem", color: "red" }}
                ></i>
              </>
            ) : profile?.deleted && profile?.selfDeleted ? (
              <>
                <i className="pi pi-trash" style={{ fontSize: "1.3rem" }}></i>
                <i
                  className="pi pi-user-minus"
                  style={{ fontSize: "1.3rem", color: "red" }}
                ></i>
              </>
            ) : (
              <>
                <i className="pi pi-trash" style={{ fontSize: "1.3rem" }}></i>
                <i
                  className="pi pi-user-minus"
                  style={{ fontSize: "1.3rem" }}
                ></i>
              </>
            )}
            {profile?.locked === true ? (
              <>
                <i className="pi pi-lock" style={{ fontSize: "1.3rem" }}></i>
              </>
            ) : profile?.deleted || profile?.selfDeleted ? (
              <>
                <i className="pi pi-lock" style={{ fontSize: "1.3rem" }}></i>
              </>
            ) : (
              <>
                <i className="pi pi-unlock" style={{ fontSize: "1.3rem" }}></i>
              </>
            )}
          </div>
          <div>
            <div>
              <div
                style={{
                  borderTop:
                    profile?.role === "HELPEE"
                      ? "4px solid var(--main-red)"
                      : profile?.role === "HELPER"
                      ? "4px solid var(--main-yellow)"
                      : "4px solid var(--main-Green)",
                }}
              >
                {profile?.role === "HELPEE" ? (
                  <HelpeeRole
                    user={user}
                    profile={profile}
                    avatar={avatar}
                    tutorAvatar={tutorAvatar}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleChangeProfile={handleChangeProfile}
                    handleChangeRadio={handleChangeRadio}
                    handleChangeRadioVaccationMode={
                      handleChangeRadioVaccationMode
                    }
                    userTemplate={userTemplate}
                    tutorTemplate={tutorTemplate}
                    setAutonomy={setAutonomy}
                    setMobilities={setMobilities}
                    setMentalHealth={setMentalHealth}
                    setActivity={setActivity}
                    setNeededServices={setNeededServices}
                    getInitialData={getInitialData}
                    uploadHandlerAvatar={uploadHandlerAvatar}
                    uploadHandlerTutorAvatar={uploadHandlerTutorAvatar}
                    refreshParent={refreshParent}
                    searchProfile={searchProfile}
                    statInfos={statInfos}
                    comment={comment}
                    setComment={setComment}
                    addComment={addComment}
                    deleteAccount={deleteAccount}
                    healthGrids={healthGrids}
                    HealthData={HealthData}
                    ActivityData={ActivityData}
                    NeededServicesData={NeededServicesData}
                    deleteTemplate={deleteTemplate}
                    relationOptions={relationOptions}
                    filteredCityByZipCode={filteredCityByZipCode}
                    city={city}
                    setCity={setCity}
                    deleteComment={deleteComment}
                  />
                ) : profile?.role === "HELPER" ? (
                  <HelperRole
                    user={user}
                    profile={profile}
                    reviewsCount={reviewsCount}
                    avatar={avatar}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleChangeProfile={handleChangeProfile}
                    handleChangeRates={handleChangeRates}
                    handleChangeRadio={handleChangeRadio}
                    handleChangeRadioVaccationMode={
                      handleChangeRadioVaccationMode
                    }
                    handleChangeRadioTransport={handleChangeRadioTransport}
                    handleChangeHasWorkLicence={handleChangeHasWorkLicence}
                    addRate={addRate}
                    userTemplate={userTemplate}
                    deleteRate={deleteRate}
                    setAutonomy={setAutonomy}
                    setMobilities={setMobilities}
                    setMentalHealth={setMentalHealth}
                    setActivity={setActivity}
                    setNeededServices={setNeededServices}
                    getInitialData={getInitialData}
                    uploadHandlerAvatar={uploadHandlerAvatar}
                    refreshParent={refreshParent}
                    searchProfile={searchProfile}
                    statInfos={statInfos}
                    comment={comment}
                    setComment={setComment}
                    addComment={addComment}
                    deleteAccount={deleteAccount}
                    healthGrids={healthGrids}
                    HealthData={HealthData}
                    ActivityData={ActivityData}
                    NeededServicesData={NeededServicesData}
                    durationValues={durationValues}
                    deleteTemplate={deleteTemplate}
                    filteredCityByZipCode={filteredCityByZipCode}
                    city={city}
                    setCity={setCity}
                    setSoftSkills={setSoftSkills}
                    setPathologyExperience={setPathologyExperience}
                    deleteComment={deleteComment}
                  />
                ) : profile?.role === "HELPRO" ? (
                  <HelproRole
                    user={user}
                    profile={profile}
                    reviewsCount={reviewsCount}
                    avatar={avatar}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    setNeededServices={setNeededServices}
                    handleChangeProfile={handleChangeProfile}
                    handleChangeRates={handleChangeRates}
                    handleChangeRadio={handleChangeRadio}
                    handleChangeRadioPersonalServiceApproval={
                      handleChangeRadioPersonalServiceApproval
                    }
                    handleChangeRadioVaccationMode={
                      handleChangeRadioVaccationMode
                    }
                    handleChangeRadioTransport={handleChangeRadioTransport}
                    handleChangeHasWorkLicence={handleChangeHasWorkLicence}
                    addRate={addRate}
                    userTemplate={userTemplate}
                    deleteRate={deleteRate}
                    setAutonomy={setAutonomy}
                    setMobilities={setMobilities}
                    setMentalHealth={setMentalHealth}
                    setServicesHelpro={setServicesHelpro}
                    ServicesData={ServicesData}
                    NeededServicesProData={NeededServicesProData}
                    getInitialData={getInitialData}
                    uploadHandlerAvatar={uploadHandlerAvatar}
                    refreshParent={refreshParent}
                    searchProfile={searchProfile}
                    statInfos={statInfos}
                    comment={comment}
                    setComment={setComment}
                    addComment={addComment}
                    deleteAccount={deleteAccount}
                    healthGrids={healthGrids}
                    HealthData={HealthData}
                    ActivityData={ActivityData}
                    NeededServicesData={NeededServicesData}
                    durationValues={durationValues}
                    deleteTemplate={deleteTemplate}
                    filteredCityByZipCode={filteredCityByZipCode}
                    city={city}
                    setCity={setCity}
                    setSoftSkills={setSoftSkills}
                    setPathologyExperience={setPathologyExperience}
                    deleteComment={deleteComment}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

DetailedProfil.propTypes = { id: PropTypes.number.isRequired };
DetailedProfil.defaultProps = {};
export default DetailedProfil;
