const userMenuName = "Mes Menus";
const urlToFetch = {
  env: {
    REACT_APP_API_URL: () => {
      switch (window.location.host) {
        case "localhost:3000":
          return "http://localhost:4000";

        case "backoffice.dear-helpee.com":
          return "https://api.dear-helpee.com";
          break;

        default:
          return "https://api-staging-dearhelpee.azurewebsites.net/";
      }
    },
  },
};

const backOfficeEmail = "backoffice@dear-helpee.com";
const backOfficePassword = "Test1234!";
// User access level as defined on the back-end
// Plan to add translation for front-end
const userLevels = {
  0: "None",
  1: "Basic",
  2: "Manager", // = Gestionnaire en FR
  3: "Administrator",
  4: "Special",
};

// Allow to dig into an object and return the sub object
// Must be passed an array of string. each string will be used as a dynamic key in the order of the array.
const getRelevantSubObjectWithDynamicPath = (arrayOfString, objectToDig) => {
  let currentObject = { ...objectToDig };
  for (let i = 0; i < arrayOfString.length; i++) {
    currentObject = currentObject[arrayOfString[i]];
  }
  return currentObject;
};

const sortTemplatesByAlphabeticalOrder = (objArray) =>
  objArray.sort((a, b) => {
    const textA = a.name.toUpperCase();
    const textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
const sortDepartmentsByAlphabeticalOrder = (objArray) =>
  objArray.sort((a, b) => {
    const textA = a.description.toUpperCase();
    const textB = b.description.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

export default {
  userMenuName,
  userLevels,
  backOfficeEmail,
  backOfficePassword,
  getRelevantSubObjectWithDynamicPath,
  sortTemplatesByAlphabeticalOrder,
  sortDepartmentsByAlphabeticalOrder,
  urlToFetch,
};
