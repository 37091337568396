import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Stats from "./index";

const mapStateToProps = (state) => ({ general: state.general });
// Actions

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
