import axios from "../../../services/axios/Axios";
import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import InputDiv from "../../Base/InputDiv";
import { PhoneNumberValidator } from "../../../services/validator";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import InputArea from "../../Base/InputArea";
import CouponsList from "./CouponsList";
import { Buffer } from "buffer";
import Statistics from "./Statistics";

// TODO catch error is not enough, we need to check the response status code

const DetailedCompany = (props) => {
  const { newCompany, companyId } = props.data;
  const toast = useRef(null);
  const [company, setCompany] = useState([]);
  const [couponsData, setCouponsData] = useState(company?.coupons ?? []);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [nbAvailable, setNbAvailable] = useState("");
  // const [plan, setPlan] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState("none");
  const [addCodeActive, setAddCodeActive] = useState(false);
  const [logoBase64, setLogoBase64] = useState(null);

  useEffect(() => {
    if (companyId) {
      axios.get(`/companies/${companyId}`).then((response) => {
        setCompany(response.data);
        setCouponsData(response.data.coupons);
      });
    }
  }, [companyId]);

  const createCompany = async () => {
    setIsLoading(true);
    const formData = new FormData();
    Object.entries(company).forEach(([key, value]) => {
      formData.append(key, value);
    });
    axios
      .post("/companies", formData)
      .then((resp) => {
        toast.current.show({
          severity: "success",
          summary: "Création Ok",
          detail: "L'entreprise a été créée",
          life: 3000,
        });
      })
      .catch((error) => {
        console.error(error);
        toast.current.show({
          severity: "fail",
          summary: "Sauvegarde échouée",
          detail: "Veuillez réessayer plus tard",
          life: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const updateCompany = async () => {
    setIsLoading(true);
    const formData = new FormData();
    Object.entries(company).forEach(([key, value]) => {
      formData.append(key, value);
    });
    axios
      .patch("/companies/" + company.id, formData)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Sauvegarde Ok",
          detail: "Les champs ont été mis à jour",
          life: 3000,
        });
      })
      .catch((error) => {
        console.error(error);
        toast.current.show({
          severity: "fail",
          summary: "Sauvegarde échouée",
          detail: "Veuillez réessayer plus tard",
          life: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleChangeCompany = (value, field) => {
    setCompany({ ...company, [field]: value });
  };

  const handleChangeCode = (value, field) => {
    setCompany({ ...couponsData, [field]: value });
  };

  const renderAddCode = () => {
    return (
      <div>
        <Button
          icon="pi pi-plus"
          size="small"
          onClick={() => {
            setAddCodeActive(true);
          }}
        />
        <div
          style={{
            display: addCodeActive ? "flex" : "none",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <div>
            <p>Date de début</p>
            <input
              type="date"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
                handleChangeCode(e.target.value, "startDate");
              }}
            />
          </div>
          <div>
            <p>Date de fin</p>
            <input
              type="date"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
                handleChangeCode(e.target.value, "endDate");
              }}
            />
          </div>
          <div>
            <p>Nombre total</p>
            <InputDiv
              value={nbAvailable}
              handleChange={(e) => {
                setNbAvailable(e.target.value);
                handleChangeCode(e.target.value, "nbAvailable");
              }}
            />
          </div>
          <Button
            label="Générer un code"
            onClick={generateCode}
            loading={isLoading}
            size="small"
          />
        </div>
      </div>
    );
  };

  const downloadLogo = async () => {
    const response = await axios.get(`/companies/logo/${companyId}`, {
      responseType: "buffer",
    });

    const base64String = new Buffer.from(response.data.data).toString("base64");
    setLogoBase64(base64String);
  };

  const generateCode = async () => {
    setIsLoading(true);
    axios
      .post("/coupons/", {
        companyId,
        startDate,
        endDate,
        nbAvailable: parseInt(nbAvailable),
      })
      .then((resp) => {
        toast.current.show({
          severity: "success",
          summary: "Le code a été créé",
          life: 3000,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Toast ref={toast} />
      <p style={{ textAlign: "center" }}>
        ⚠️ Merci de sauvegarder après toute modification (y compris les
        documents)
      </p>
      <div style={{ flexDirection: "row", display: "flex" }}>
        <i
          className="pi pi-save"
          style={{ fontSize: "1.3rem" }}
          onClick={() => {
            newCompany ? createCompany() : updateCompany();
          }}
        ></i>
      </div>
      <TabView
        activeIndex={activeTab}
        onTabChange={(e) => {
          setActiveTab(e.index);
        }}
      >
        <TabPanel header={"Infos"} index={0}>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div className="Company-info">
              <div
                style={{
                  border: "2px solid lightgrey",
                  borderRadius: "8px",
                  padding: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div className="COMPANY">
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "0px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Nom
                    </p>
                    <InputDiv
                      value={company?.name}
                      handleChange={(e) => {
                        handleChangeCompany(e.target.value, "name");
                      }}
                    />
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "0px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Nom du contact
                    </p>
                    <InputDiv
                      value={company?.contactName}
                      handleChange={(e) => {
                        handleChangeCompany(e.target.value, "contactName");
                      }}
                    />
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "5px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Téléphone
                    </p>
                    <InputDiv
                      value={company?.phoneNumber}
                      handleChange={(e) => {
                        if (!PhoneNumberValidator(e.target.value)) {
                          setIsPhoneValid("block");
                          handleChangeCompany(e.target.value, "phoneNumber");
                        } else {
                          setIsPhoneValid("none");
                          handleChangeCompany(e.target.value, "phoneNumber");
                        }
                      }}
                    />
                    <span
                      style={{
                        display: isPhoneValid,
                        color: "red",
                        fontSize: "9px",
                        margin: "5px 0 0 10px",
                        fontWeight: "bold",
                      }}
                    >
                      Le numéro doit contenir 10 chiffres
                    </span>
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "5px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Email
                    </p>
                    <InputDiv
                      value={company?.email}
                      handleChange={(e) => {
                        handleChangeCompany(e.target.value, "email");
                      }}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: 11,
                        margin: "0px 10px 5px",
                        textDecoration: "underline",
                      }}
                    >
                      Description
                    </p>
                    <InputArea
                      value={company?.description}
                      handleChange={(e) => {
                        handleChangeCompany(e.target.value, "description");
                      }}
                    />
                    <div
                      style={{
                        paddingTop: "20px",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "0px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Logo (Format jpeg 16:9)
                      </p>
                      <input
                        type="file"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            handleChangeCompany(file, "file");
                            const reader = new FileReader();
                            reader.onloadend = () => {
                              const base64File = reader.result.split(",")[1];
                              setLogoBase64(base64File);
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                      />
                      <br />
                      {company.logoUuid && (
                        <button onClick={downloadLogo}>Preview</button>
                      )}

                      <span style={{ fontSize: "10px" }}>
                        {company.logoUuid}
                      </span>

                      {logoBase64 && (
                        <div>
                          <img
                            width={160}
                            height={90}
                            src={`data:image/jpeg;base64,${logoBase64}`}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!newCompany && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "20px 0",
                  }}
                >
                  {renderAddCode()}
                </div>
                <div
                  className="Coupons"
                  style={{
                    border: "2px solid lightgrey",
                    borderRadius: "8px",
                    padding: "12px",
                  }}
                >
                  {/* Add the new component for coupons */}
                  <CouponsList
                    coupons={couponsData}
                    handleChangeCode={handleChangeCode}
                  />
                </div>
              </>
            )}
          </div>
        </TabPanel>
        <TabPanel header={"Statistiques"} index={1}>
          <Statistics
            companyId={company.id}
            coupons={couponsData ? couponsData : null}
          />
        </TabPanel>
      </TabView>
    </>
  );
};
export default DetailedCompany;
