import { InputTextarea } from "primereact/inputtextarea";
import PropTypes from "prop-types";
import React from "react";
const InputArea = (fieldName) => {
  return (
    <div
      style={{
        width: "100%",
        //marginTop: "10px",
        marginLeft: "10px",
      }}
    >
      <span className="p-float-label">
        <InputTextarea
          rows={5}
          cols={30}
          disabled={fieldName.disabled}
          style={{
            ...fieldName.customStyle,
            borderColor: "#A9A9A9",
            fontSize: 11,
          }}
          id={fieldName.name}
          value={fieldName.value}
          onChange={fieldName.handleChange}
        />
        <label htmlFor="birthDate">{fieldName.name}</label>
      </span>
    </div>
  );
};

InputArea.propTypes = {};
InputArea.defaultProps = {};
export default InputArea;
