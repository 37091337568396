/*
 * Package Import
 */
import React from "react";
import { render } from "react-dom";

// Error Follow up by mail

import { Provider } from "react-redux";
import jwtDecode from "jwt-decode";

import store from "./store";
import getProvider from "./provider";
import { runWithAdal } from "react-adal";
import { authContext } from "./adalconfig";

/*
 * Code
 */
const provider = getProvider(store);
const node = document.getElementById("skel-root");

let DO_NOT_LOGIN = true;

if (
  localStorage.getItem(
    "adal.expiration.key495b14ec-0d72-4e07-be86-914f8b84f7fb"
  ) > Math.round(Date.now() / 1000, 0)
) {
  DO_NOT_LOGIN = true;
}

try {
  runWithAdal(
    authContext,
    () => {
      render(provider, node);
    },
    false
  );
} catch (e) {
  console.log("white screen error during prod : ", e);
  render(<Provider store={store}></Provider>, node);
}
