export const PATTERN_PHONE = /^0\d{9}$/;

export const PhoneNumberValidator = (value) => {
  if (value) {
    return RegExpValidator(PATTERN_PHONE, value);
  }
};

const RegExpValidator = (regexp, value) => {
  return regexp.test(value);
};
