import React from "react";

const RadioButton = ({ onChange, radioData, profileInfo, textColor }) => {
  if (!radioData || !profileInfo) {
    return null;
  }
  const options = radioData?.value.split(", ");
  const field = radioData?.name;

  const styles = {
    label: {
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "center",
      fontSize: 10,
      fontWeight: "bold",
      gap: 4,
      color: textColor ? { color: textColor } : "#F4F2F2",
      marginRight: 20,
    },
  };

  return (
    <div style={{ display: "flex" }}>
      {options.map((option, index) => (
        <label key={index} className="label" style={styles.label}>
          <input
            type="radio"
            value={option}
            defaultChecked={option === profileInfo}
            onChange={(e) => {
              onChange(e.target.value, field);
            }}
            name={radioData.name}
          />
          {option}
        </label>
      ))}
    </div>
  );
};

export default RadioButton;
