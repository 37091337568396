import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { Card } from "@tsamantanis/react-glassmorphism";
import { Bar } from "react-chartjs-2";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import axios from "../../../../services/axios/Axios";
import moment from "moment";
import InputDivNumber from "../../../Base/InputNumber";
import RechercheToutProfil from "./RechercheToutProfil";
import dayjs from "dayjs";
import { Tooltip } from "primereact/tooltip";

const Stats = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [rangeSearch, setRangeSearch] = useState(20);
  const [rangeSearchResult, setRangeSearchResult] = useState();
  const [numberOfCalls, setNumberOfCalls] = useState({
    total: 0,
    interlocutors: [],
  });

  const lightOptions = {
    plugins: {
      legend: {
        labels: {
          fontSize: "8px",
        },
      },
    },
    legend: {
      display: true,
      labels: {
        fontColor: "#495057",
        fontSize: "8px",
      },
    },
  };
  const profile = props?.profile;
  const searchProfile = props?.searchProfile;
  const labels = props?.profileInfo?.histoCA?.map((e) => e.id);
  const linedata = {
    labels,
    datasets: [
      {
        label: "Chiffre d'affaire",
        data: labels?.map(
          (e) => props?.profileInfo?.histoCA?.find((elt) => elt.id === e).CA
        ),
        borderColor: "#FF6F5B",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  const labels2 = props?.profileInfo?.histoVolume?.map((e) => e.id);
  const linedata2 = {
    labels,
    datasets: [
      {
        label: "Volume d'affaire",
        data: labels2?.map(
          (e) =>
            props?.profileInfo?.histoVolume?.find((elt) => elt.id === e).volume
        ),
        borderColor: "#FF6F5B",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  // Data for nofilter tab range km only
  useEffect(() => {
    axios
      .get(
        "search/viewResultProfiles/" +
          props.profileInfo.profile?.userId +
          "?roles=HELPER&&roles=HELPRO&&range=" +
          rangeSearch
      )
      .then((resp) => {
        if (resp?.status !== undefined) {
          setRangeSearchResult(resp.data.profilesList);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeSearch]);

  useEffect(() => {
    axios
      .get("twilio/numberOfCalls/" + props.profileInfo?.profile?.id)
      .then((resp) => {
        if (resp?.status !== undefined) {
          setNumberOfCalls(resp.data);
        }
      });
  }, [props.profileInfo?.profile?.id]);

  const displayMissionStatsHelpee = (createdMissions, profile) => {
    return createdMissions.length > 0 ? (
      <div>
        <DataTable
          value={createdMissions}
          paginator
          className="profilTable"
          stripedRows
          scrollable
          scrolldirection="both"
          scrollheight="flex"
          sortOrder={-1}
          paginatorPosition="bottom"
          rows={10}
          dataKey="id"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" /*{this.paginatorTemplate}*/
          selectionMode="single"
          onSelectionChange={(e) => {
            window.open(
              `/missions?detailedMissionId=${e.value.id}&createdById=${profile?.id}&createdByRole=${profile?.role}`
            );
          }}
        >
          <Column
            field="createdAt"
            header="Date de création"
            style={{ width: "50px" }}
            filter
            body={(rowData) => {
              return <div>{rowData?.createdAt.split("T")[0]}</div>;
            }}
            sortable
          ></Column>
          <Column
            body={(rowData) => {
              if (Array.isArray(rowData.dates) && rowData.dates.length > 0) {
                return dayjs(rowData.dates[0].startDate).format("DD/MM/YYYY");
              }
              return dayjs(rowData.dates.startDate).format("DD/MM/YYYY");
            }}
            header="Première date"
            filter
          ></Column>
          <Column
            field="id"
            header="ID mission"
            filter
            sortable
            style={{ width: "20px" }}
          ></Column>
          <Column field="title" header="Titre" filter></Column>
          <Column
            body={(rowData) => {
              return rowData.applications.length;
            }}
            header="Nb de candidatures"
            filter
          ></Column>
          <Column
            field="missionType"
            header="Type"
            body={(rowData) => {
              if (rowData.missionType === "ponctual") {
                return "Ponctuelle";
              } else if (rowData.missionType === "defined_period") {
                return "Périodique";
              } else {
                return "Récurrente";
              }
            }}
            filter
          ></Column>
        </DataTable>
      </div>
    ) : (
      <div>Pas de missions postées</div>
    );
  };

  const displayMissionStatsHelper = (appliedMissions, profile) => {
    return appliedMissions.length > 0 ? (
      <div>
        <DataTable
          value={appliedMissions}
          paginator
          className="profilTable"
          stripedRows
          scrollable
          scrollDirection="both"
          scrollHeight="flex"
          sortOrder={-1}
          paginatorPosition="bottom"
          rows={10}
          dataKey="id"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" /*{this.paginatorTemplate}*/
          selectionMode="single"
          onSelectionChange={(e) => {
            window.open(
              `/missions?detailedMissionId=${e.value.mission.id}&createdById=${e.value.mission.createdBy.id}&createdByRole=${e.value.mission.createdBy.role}`
            );
          }}
        >
          <Column
            field="id"
            header="ID mission"
            filter
            sortable
            style={{ width: "20px" }}
          ></Column>
          <Column
            field="createdAt"
            header="Date de candidature"
            style={{ width: "50px" }}
            filter
            body={(rowData) => {
              return <div>{rowData?.createdAt.split("T")[0]}</div>;
            }}
            sortable
          ></Column>
          <Column
            body={(rowData) => {
              if (
                Array.isArray(rowData.mission.dates) &&
                rowData.mission.dates.length > 0
              ) {
                return dayjs(rowData.mission.dates[0].startDate).format(
                  "DD/MM/YYYY"
                );
              }
              return dayjs(rowData.mission.dates.startDate).format(
                "DD/MM/YYYY"
              );
            }}
            header="Première date"
            filter
          ></Column>

          <Column field="mission.title" header="Titre" filter></Column>
          <Column
            field="mission.createdBy.id"
            header="Id du créateur"
            filter
          ></Column>
        </DataTable>
      </div>
    ) : (
      <div>Pas de candidatures</div>
    );
  };

  const displayNbOfApplications = () => {
    return profile?.createdMissions?.reduce(
      (acc, mission) => acc + mission.applications.length,
      0
    );
  };

  return (
    <>
      <TabView
        activeIndex={activeTab}
        onTabChange={(e) => setActiveTab(e.index)}
      >
        <TabPanel header="Statistiques" index={0}>
          <div>
            <h2>Like & Match</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginTop: 20,
                marginBottom: 20,
                width: "25%",
              }}
            >
              {profile?.role === "HELPEE" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                  }}
                >
                  <div>
                    Missions postées: {profile?.createdMissions?.length}
                  </div>
                  <div>
                    Candidatures aux missions: {displayNbOfApplications()}
                  </div>
                </div>
              )}
              <div>
                <Tooltip target=".contactssent" position="top" />
                <p
                  className="contactssent"
                  data-pr-tooltip={props?.profileInfo?.givenLike.map((e) => {
                    if (e.favoriteProfile.locked === true) {
                      return (
                        "(X)" +
                        e.favoriteProfile.lastName +
                        " " +
                        e.favoriteProfile.firstName
                      );
                    } else {
                      return (
                        e.favoriteProfile.lastName +
                        " " +
                        e.favoriteProfile.firstName
                      );
                    }
                  })}
                  data-pr-position="right"
                  data-pr-at="right top"
                  data-pr-my="left center-2"
                >
                  Demandes de contact envoyées :{" "}
                  {props?.profileInfo?.givenLike.length}
                </p>
              </div>
              <div>
                <Tooltip target=".contactsreceived" position="top" />
                <p
                  className="contactsreceived"
                  data-pr-tooltip={props?.profileInfo?.receivedLike.map((e) => {
                    if (e.profile.locked === true) {
                      return (
                        "(X)" + e.profile.lastName + " " + e.profile.firstName
                      );
                    } else {
                      return e.profile.lastName + " " + e.profile.firstName;
                    }
                  })}
                  data-pr-position="right"
                  data-pr-at="right top"
                  data-pr-my="left center-2"
                >
                  Demandes de contact reçues :{" "}
                  {props?.profileInfo?.receivedLike.length}
                </p>
              </div>
              <div>
                <Tooltip target=".matches" position="top" />
                <p
                  className="matches"
                  data-pr-tooltip={props?.profileInfo?.match.map((e) => {
                    if (e.profileA.id === profile.id) {
                      return e.profileB.lastName + " " + e.profileB.firstName;
                    } else {
                      return e.profileA.lastName + " " + e.profileA.firstName;
                    }
                  })}
                  data-pr-position="right"
                  data-pr-at="right+5 top"
                  data-pr-my="left center-2"
                >
                  Mises en relations : {props?.profileInfo?.match.length}
                </p>
              </div>
              <div>
                <Tooltip target=".conversations" position="top" />
                <p
                  className="conversations"
                  data-pr-tooltip={props?.profileInfo?.conversations.map(
                    (e) => {
                      console.log("EEE", e.firstName, e.lastName);
                      return e.firstName + " " + e.lastName;
                    }
                  )}
                  data-pr-position="right"
                  data-pr-at="right+5 top"
                  data-pr-my="left center-2"
                >
                  Discussions : {props?.profileInfo?.conversations.length}
                </p>
              </div>
              <div>
                <Tooltip target=".videoCalls" position="top" />
                <p
                  className="videoCalls"
                  data-pr-tooltip={numberOfCalls.interlocutors.map((e) => {
                    console.log("EEE", e);
                    return e.firstName + " " + e.lastName;
                  })}
                  data-pr-position="right"
                  data-pr-at="right+5 top"
                  data-pr-my="left center-2"
                >
                  Discussions Vidéos : {numberOfCalls.total}
                </p>
              </div>
            </div>
          </div>
          <div>
            <h2>Réservations</h2>
            Réservations créés : {props?.profileInfo?.reservations.length}
          </div>
          <div>
            <h2>Chiffre d'affaire</h2>
            Chiffre d'affaire total generé : {props?.profileInfo?.ca.toFixed(2)}
            €
            <br />
            Volume d'affaire : {props?.profileInfo?.volume.toFixed(2)}€
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Card style={{ width: "100%", height: "100%" }}>
                <div
                  style={{
                    width: "250px",
                    height: "250px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  key="homDiv"
                >
                  <Bar data={linedata} options={lightOptions} />
                </div>
              </Card>
              <Card style={{ width: "100%", height: "100%" }}>
                <div
                  style={{
                    width: "250px",
                    height: "250px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  key="homDiv"
                >
                  <Bar data={linedata2} options={lightOptions} />
                </div>
              </Card>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Résultat de recherche" index={0}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "6px ",
            }}
          >
            <p>Chercher dans un rayon de</p>
            <InputDivNumber
              value={rangeSearch}
              handleChange={(e) => {
                props.onChangeRange?.(e.target?.value);
              }}
              showButtons={true}
              suffix=" Km"
              customStyle={{ height: "26px", width: "85px" }}
            />
            <p
              style={{ marginLeft: "80px" }}
            >{`${searchProfile?.length} profils trouvés`}</p>
          </div>
          <DataTable
            value={searchProfile}
            paginator
            className="profilTable"
            stripedRows
            scrollable
            scrollDirection="both"
            scrollHeight="flex"
            sortOrder={-1}
            paginatorPosition="bottom"
            rows={10}
            dataKey="id"
            responsiveLayout="scroll"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" /*{this.paginatorTemplate}*/
            selectionMode="single"
            onSelectionChange={(e) => {
              window.open(`/profils?detailedProfileId=${e.value.user_id}`);
            }}
          >
            <Column
              header={"Match"}
              body={(rawData) => {
                if (
                  props?.profileInfo?.match
                    .map((e) => [e.profileA.id, e.profileB.id])
                    .flat()
                    .includes(rawData.profile_id)
                ) {
                  return <span>💛</span>;
                } else {
                  return null;
                }
              }}
            ></Column>
            <Column
              header={"Like émis"}
              body={(rawData) => {
                if (
                  props?.profileInfo?.givenLike
                    .map((e) => e.favoriteProfile.id)
                    .includes(rawData.profile_id)
                ) {
                  return <span>❤️</span>;
                } else {
                  return null;
                }
              }}
            ></Column>
            <Column
              style={{
                minWidth: "100px",
              }}
              // body={mailBodyTemplate}
              field="profile_role"
              sortable
              filter
              header="Role"
            ></Column>
            <Column
              style={{ minWidth: "145.95px" }}
              field="profile_firstName"
              sortable
              filter
              header="Prénom"
            ></Column>
            <Column
              style={{ minWidth: "145px" }}
              field="profile_lastName"
              header="Nom"
              sortable
              filter
            ></Column>
            <Column
              style={{ minWidth: "145px" }}
              field="profile_profileStatus"
              header="Profile Status"
              body={(rowData) =>
                rowData.profile_profileStatus === "LIVE" ? (
                  <span style={{ color: "green" }}>LIVE</span>
                ) : rowData.profile_profileStatus === "BLOCKED" ? (
                  <span style={{ color: "red" }}>BLOQUÉ</span>
                ) : rowData.profile_profileStatus === "UNSUBSCRIBED" ? (
                  "DÉSINSCRIT"
                ) : rowData.profile_profileStatus === "DELETED" ? (
                  "SUPPRIMÉ"
                ) : rowData.profile_profileStatus === "INCOMPLETE" ? (
                  <span style={{ color: "orange" }}>INCOMPLET</span>
                ) : rowData.profile_profileStatus === "INACTIF_1" ? (
                  <span style={{ color: "orange" }}>INACTIF 1</span>
                ) : rowData.profile_profileStatus === "INACTIF_6" ? (
                  <span style={{ color: "lightred" }}>INACTIF 6</span>
                ) : rowData.profile_profileStatus === "INACTIF_12" ? (
                  <span style={{ color: "red" }}>INACTIF 12</span>
                ) : (
                  <span style={{ color: "black" }}>
                    {rowData.profile_profileStatus}
                  </span>
                )
              }
              sortable
              filter
            ></Column>
            <Column
              style={{ minWidth: "145.95px" }}
              field="user_creationDate"
              sortable
              filter
              header="Date de Création"
              body={(rowData) => {
                const dateParts = rowData.user_creationDate?.split(" ");
                if (dateParts && dateParts.length > 0) {
                  const date = dateParts[0];
                  return <span>{date}</span>;
                }
                return null; // Handle cases where rowData.user?.creationDate is undefined or invalid
              }}
            ></Column>
            <Column
              style={{ minWidth: "145px" }}
              field="profile_completedAt"
              header="Date de Complétion"
              sortable
              filter
              body={(rowData) =>
                rowData?.profile_completedAt
                  ? moment(rowData.profile_completedAt).format("DD-MM-YYYY")
                  : ""
              }
            ></Column>
          </DataTable>
        </TabPanel>
        <TabPanel header="Recherche élargie" index={0}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "6px ",
            }}
          >
            <p>Chercher dans un rayon de</p>
            <InputDivNumber
              value={rangeSearch}
              //name="Nom"
              handleChange={(e) => {
                setRangeSearch(e.target?.value);
              }}
              showButtons={true}
              suffix=" Km"
              customStyle={{ height: "26px", width: "85px" }}
            />
            <p
              style={{ marginLeft: "80px" }}
            >{`${rangeSearchResult?.length} profils trouvés`}</p>
          </div>
          <DataTable
            value={rangeSearchResult}
            paginator
            className="profilTable"
            stripedRows
            scrollable
            scrollDirection="both"
            scrollHeight="flex"
            sortOrder={-1}
            paginatorPosition="bottom"
            rows={10}
            dataKey="id"
            responsiveLayout="scroll"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" /*{this.paginatorTemplate}*/
            selectionMode="single"
            onSelectionChange={(e) => {
              window.open(`/profils?detailedProfileId=${e.value.user_id}`);
            }}
          >
            <Column
              header={"Match"}
              body={(rawData) => {
                if (
                  props?.profileInfo?.match
                    .map((e) => [e.profileA.id, e.profileB.id])
                    .flat()
                    .includes(rawData.profile_id)
                ) {
                  return <span>💛</span>;
                } else {
                  return null;
                }
              }}
            ></Column>
            <Column
              header={"Like émis"}
              body={(rawData) => {
                if (
                  props?.profileInfo?.givenLike
                    .map((e) => e.favoriteProfile.id)
                    .includes(rawData.profile_id)
                ) {
                  return <span>❤️</span>;
                } else {
                  return null;
                }
              }}
            ></Column>
            <Column
              style={{
                minWidth: "100px",
              }}
              // body={mailBodyTemplate}
              field="profile_role"
              sortable
              filter
              header="Role"
            ></Column>

            <Column
              style={{ minWidth: "145.95px" }}
              field="profile_firstName"
              sortable
              filter
              header="Prénom"
            ></Column>
            <Column
              style={{ minWidth: "145px" }}
              field="profile_lastName"
              header="Nom"
              sortable
              filter
            ></Column>
            <Column
              style={{ minWidth: "145px" }}
              field="profile_profileStatus"
              header="Profile Status"
              body={(rowData) =>
                rowData.profile_profileStatus === "LIVE" ? (
                  <span style={{ color: "green" }}>LIVE</span>
                ) : rowData.profile_profileStatus === "BLOCKED" ? (
                  <span style={{ color: "red" }}>BLOQUÉ</span>
                ) : rowData.profile_profileStatus === "UNSUBSCRIBED" ? (
                  "DÉSINSCRIT"
                ) : rowData.profile_profileStatus === "DELETED" ? (
                  "SUPPRIMÉ"
                ) : rowData.profile_profileStatus === "INCOMPLETE" ? (
                  <span style={{ color: "orange" }}>INCOMPLET</span>
                ) : rowData.profile_profileStatus === "INACTIF_1" ? (
                  <span style={{ color: "orange" }}>INACTIF 1</span>
                ) : rowData.profile_profileStatus === "INACTIF_6" ? (
                  <span style={{ color: "lightred" }}>INACTIF 6</span>
                ) : rowData.profile_profileStatus === "INACTIF_12" ? (
                  <span style={{ color: "red" }}>INACTIF 12</span>
                ) : (
                  <span style={{ color: "black" }}>
                    {rowData.profile_profileStatus}
                  </span>
                )
              }
              sortable
              filter
            ></Column>
            <Column
              style={{ minWidth: "145px" }}
              field="user_creationDate"
              header="Date de Création"
              sortable
              filter
              body={(rowData) => {
                const dateParts = rowData.user_creationDate?.split(" ");
                if (dateParts && dateParts.length > 0) {
                  const date = dateParts[0];
                  return <span>{date}</span>;
                }
                return null; // Handle cases where rowData.user?.creationDate is undefined or invalid
              }}
            ></Column>
            <Column
              style={{ minWidth: "145px" }}
              field="profile_completedAt"
              header="Date de Complétion"
              sortable
              filter
              body={(rowData) =>
                rowData?.profile_completedAt
                  ? moment(rowData.profile_completedAt).format("DD-MM-YYYY")
                  : ""
              }
            ></Column>
          </DataTable>
        </TabPanel>
        <TabPanel header="Recherche tout profil" index={0}>
          <RechercheToutProfil profileInfo={props.profileInfo} />
        </TabPanel>
        <TabPanel
          header={
            profile.role === "HELPEE"
              ? "Missions postées"
              : "Candidatures aux missions"
          }
          index={0}
        >
          {profile?.role === "HELPEE"
            ? displayMissionStatsHelpee(profile?.createdMissions, profile)
            : displayMissionStatsHelper(profile?.appliedMissions, profile)}
        </TabPanel>
      </TabView>
    </>
  );
};
Stats.propTypes = { props: PropTypes.object.isRequired };
Stats.defaultProps = {};
export default Stats;
