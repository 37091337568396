import React, { useEffect, useRef, useState } from "react";
import axios from "../../../services/axios/Axios";
import "./style.css";
import MissionInformation from "./MissionInformation/MissionInformation";
import { ProgressSpinner } from "primereact/progressspinner";
import CreatorProfile from "./CreatorProfile/CreatorProfile";
import MissionApplications from "./MissionApplications/MissionApplications";
import { TabPanel, TabView } from "primereact/tabview";
import MissionNotification from "./MissionNotification/MissionNotification";
import { Toast } from "primereact/toast";

const DetailedMission = (props) => {
  const toast = useRef(null);
  const { id: missionId, creatorId, creatorRole } = props?.data;
  const [mission, setMission] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [avatar, setAvatar] = useState();
  const [tutorAvatar, setTutorAvatar] = useState();

  useEffect(() => {
    missionId && fetchMissionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMissionData = async () => {
    await fetchMissionDetails();
    await fetchCreatorAvatar();
    setIsLoading(false);
  };

  const fetchCreatorAvatar = async () => {
    await axios
      .get("/profiles/" + creatorId + "/avatar", {
        responseType: "blob",
      })
      .then((resp) => {
        if (resp?.status !== undefined) setAvatar(resp.data);
      });
    if (creatorRole === "HELPEE") {
      await axios
        .get("/profiles/" + creatorId + "/tutor-avatar", {
          responseType: "blob",
        })
        .then((resp) => {
          if (resp?.status !== undefined) setTutorAvatar(resp.data);
        });
    }
  };

  const fetchMissionDetails = async () => {
    await axios.get(`/missions/bo/${missionId}`).then((response) => {
      setMission(response.data);
    });
  };

  const handleChangeMission = (value, key) => {
    setMission({ ...mission, [key]: value });
  };

  const updateMission = async () => {
    setIsLoading(true);
    const newMission = {
      title: mission.title,
      description: mission.description,
      needs: mission.needs,
    };
    axios
      .put(`/missions/bo/${missionId}`, {
        data: { updateMissionDto: newMission, creatorId: mission.createdBy.id },
      })
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "La mission a été mise à jour",
          life: 2000,
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "La mission n'a pas pu être mise à jour",
          life: 2000,
        });
      });
  };

  return (
    <div>
      <Toast ref={toast} />
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <div>
          <i
            className="pi pi-save"
            style={{ fontSize: "1.3rem" }}
            onClick={updateMission}
          ></i>
          <TabView>
            <TabPanel header={`Détails de la mission #${missionId}`}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CreatorProfile
                  profile={mission.createdBy}
                  profileAvatar={avatar}
                  profileTutorAvatar={tutorAvatar}
                />
                <MissionInformation
                  mission={mission}
                  handleChangeMission={handleChangeMission}
                />
              </div>
            </TabPanel>
            <TabPanel header="Envoi de notifications">
              <MissionNotification missionId={missionId} />
            </TabPanel>
            <TabPanel header="Candidatures">
              <MissionApplications applications={mission.applications} />
            </TabPanel>
          </TabView>
        </div>
      )}
    </div>
  );
};

export default DetailedMission;
