import React, { useEffect, useState } from "react";
import axios from "../../../../services/axios/Axios";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

// TODO catch error is not enough, we need to check the response status code

const CouponsList = (couponsData) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = React.useRef(null);
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    setCoupons(couponsData.coupons);
  }, [couponsData]);

  const handleInputChange = (couponId, value, field) => {
    const couponIndex = coupons.findIndex((coupon) => coupon.id === couponId);
    const updatedCoupons = [...coupons];
    if (field === "nbAvailable") {
      updatedCoupons[couponIndex][field] = parseInt(value);
    } else {
      updatedCoupons[couponIndex][field] = value;
    }
    setCoupons(updatedCoupons);
  };

  const updateCode = async (couponId) => {
    setIsLoading(true);
    const updatedCoupon = coupons.find((coupon) => coupon.id === couponId);
    axios
      .patch("/coupons/" + updatedCoupon.id, {
        ...updatedCoupon,
      })
      .then((resp) => {
        console.log(resp);
        toast.current.show({
          severity: "success",
          summary: "Sauvegarde Ok",
          detail: "Les champs ont été mis à jour",
          life: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "fail",
          summary: "Sauvegarde échouée",
          detail: "Veuillez réessayer plus tard",
          life: 3000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const disableCode = async (couponId) => {
    setIsLoading(true);
    axios
      .patch(`/coupons/disable/${couponId}`)
      .then((resp) => {
        console.log(resp);
        setCoupons(
          coupons.map((coupon) => {
            if (coupon.id === couponId) {
              coupon.isActive = false;
            }
            return coupon;
          })
        );
        toast.current.show({
          severity: "success",
          summary: "Coupon désactivé",
          detail: "Le code a été désactivé",
          life: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "fail",
          summary: "Désactivation échouée",
          detail: "Veuillez réessayer plus tard",
          life: 3000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderCoupons = () => {
    return coupons.map((coupon) => {
      console.log("coupon", coupon);
      return (
        <div
          key={coupon.id}
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "flex-start",
            border: "solid grey 2px",
            borderRadius: "10px",
            marginBottom: "20px",
            padding: "20px",
          }}
        >
          <div>
            <p>ID</p>
            <p>{coupon.id}</p>
          </div>
          <div>
            <p>Code</p>
            <p>{coupon.code}</p>
          </div>
          <div>
            <p>Date de début</p>
            <p>{coupon.startDate.split("T")[0]}</p>
            <p style={{ fontSize: "10px" }}>Nouvelle date</p>
            <input
              type="date"
              value={coupon.startDate}
              onChange={(e) => {
                handleInputChange(coupon.id, e.target.value, "startDate");
              }}
            ></input>
          </div>
          <div>
            <p>Date de fin</p>
            <p>{coupon.endDate.split("T")[0]}</p>
            <p style={{ fontSize: "10px" }}>Nouvelle date</p>
            <input
              type="date"
              value={coupon.endDate}
              onChange={(e) => {
                handleInputChange(coupon.id, e.target.value, "endDate");
              }}
            ></input>
          </div>
          <div>
            <p>Nombre de licences</p>
            <p>{coupon.nbAvailable}</p>
            <p style={{ fontSize: "10px" }}>Nouveau Nombre</p>
            <input
              type="number"
              value={coupon.nbAvailable}
              onChange={(e) => {
                handleInputChange(coupon.id, e.target.value, "nbAvailable");
              }}
            ></input>
          </div>
          <div>
            <p>Nombre de licences activées</p>
            <p>{coupon.nbUsed}</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Button
              disabled={!coupon.isActive}
              label="Update"
              loading={isLoading}
              onClick={() => {
                updateCode(coupon.id);
              }}
            />
            <Button
              style={{
                backgroundColor: "red",
                color: "white",
                border: "none",
                fontSize: "10px",
              }}
              disabled={!coupon.isActive}
              label="Désactiver définitivement et supprimer les abonnements associés"
              loading={isLoading}
              onClick={() => {
                disableCode(coupon.id);
              }}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {renderCoupons()}
      </div>
    </>
  );
};

export default CouponsList;
