import { applyMiddleware, compose, createStore } from "redux";
import * as types from "./types";
import reducers from "./reducers";
import ajax from "./middleware/ajax/index";

let devTools = [];

if (process.env.NODE_ENV !== "production") {
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    devTools = [window.__REDUX_DEVTOOLS_EXTENSION__()];
  }
}

const allMiddlewares = applyMiddleware(ajax);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const allMiddlewaresCompose = composeEnhancers(allMiddlewares);

// Store
const store = createStore(reducers, allMiddlewaresCompose);

/*
 * Export
 */
export default store;
