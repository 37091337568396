import React from "react";
import {
  PRIMARY_RED,
  PRIMARY_YELLOW,
  PRIMARY_GREEN,
} from "../../../styles/colors";

const DotRoleColorNumber = ({ role, value }) => {
  const roleColor = {
    HELPEE: PRIMARY_RED,
    HELPER: PRIMARY_YELLOW,
    HELPRO: PRIMARY_GREEN,
  };

  return (
    <div
      style={{
        float: "right",
        width: "32px",
        height: "32px",
        borderRadius: "16px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        backgroundColor: roleColor[role],
      }}
    >
      {value}
    </div>
  );
};

export default DotRoleColorNumber;
