import React, { useState } from "react";
import InputDiv from "../../../Base/InputDiv";
import { PhoneNumberValidator } from "../../../../services/validator";
import { TabView, TabPanel } from "primereact/tabview";
import { Avatar } from "primereact/avatar";
import DocumentSelectorAndValidator from "../../../Base/DocumentSelectorAndValidator/container";
import picto from "../../../../images/picto_hq.png";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import Stats from "../Stats/container";
import InputArea from "../../../Base/InputArea";
import HighLightSwitch from "../../../Base/HighLightSwitch";
import InputDropdown from "../../../Base/InputDropdown";
import RadioButton from "../../../Base/RadioButton";
import { userOrigin } from "../../../../services/enums";
import {
  PRIMARY_YELLOW,
  PRIMARY_GREEN,
  PRIMARY_RED,
} from "../../../../styles/colors";
import { useEffect } from "react";
import axios from "../../../../services/axios/Axios";
import { Image } from "primereact/image";
import { UpdatesHistory } from "../UpdatesHistory";

const HelpeeRole = ({
  user,
  profile,
  avatar,
  tutorAvatar,
  activeTab,
  setActiveTab,
  handleChangeProfile,
  handleChangeRadio,
  handleChangeRadioVaccationMode,
  userTemplate,
  tutorTemplate,
  setAutonomy,
  setMobilities,
  setMentalHealth,
  setActivity,
  setNeededServices,
  getInitialData,
  uploadHandlerAvatar,
  uploadHandlerTutorAvatar,
  refreshParent,
  searchProfile,
  statInfos,
  comment,
  setComment,
  addComment,
  deleteAccount,
  healthGrids,
  HealthData,
  ActivityData,
  NeededServicesData,
  deleteTemplate,
  relationOptions,
  filteredCityByZipCode,
  city,
  setCity,
  deleteComment,
}) => {
  var parse = require("html-react-parser");

  const [isPhoneValid, setIsPhoneValid] = useState("none");
  const [coupon, setCoupon] = useState();
  const displayComments = () => {
    if (user?.comment) {
      const comments = user.comment
        .split("<br/>")
        .filter((comment) => comment.trim() !== "");
      return comments.map((comment, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div style={{ width: "50%" }}>{parse(comment)}</div>
          <Button
            className={"p-button-primary"}
            size="small"
            onClick={() => {
              console.log("USER COMMENT", user?.comment);
              console.log("DELETE COMMENT", comment);
              deleteComment(comment);
            }}
            style={{ fontSize: "10px", padding: "8px", marginTop: "10px" }}
            label="Supprimer"
          />
        </div>
      ));
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (user?.currentPlan === "PREMIUM_MONTHLY" && profile?.id) {
      axios
        .get(`/profiles/${profile.id}/subscription-promotion-company-bo`)
        .then((res) => {
          setCoupon(res.data.coupon);
        })
        .catch((err) => {
          console.log("ERROR", err);
        });
    }
  }, [user.currentPlan]);

  const premiumTemplate = (e) => {
    return (
      <div
        onClick={() => {
          setActiveTab(e.index);
          console.log(activeTab);
        }}
        style={{ color: "green", fontWeight: 700 }}
      >
        {user.currentPlan === "PREMIUM_MONTHLY" ? (
          <div style={{ display: "flex", gap: "5px" }}>
            <p style={{ color: PRIMARY_GREEN }}>PREMIUM</p>
            {coupon ? (
              <p style={{ color: PRIMARY_GREEN }}> - {coupon?.company?.name}</p>
            ) : null}
          </div>
        ) : (
          <p style={{ color: PRIMARY_YELLOW }}>FREEMIUM</p>
        )}
      </div>
    );
  };

  const computeProfileStatus = (profileStatus) => {
    const statusArray = new Set(["INACTIF_1", "INACTIF_6", "INACTIF_12"]);
    return statusArray.has(profileStatus);
  };

  return (
    <>
      <p style={{ textAlign: "center" }}>
        ⚠️ Merci de sauvegarder après toute modification (y compris les
        documents)
      </p>
      <TabView
        activeIndex={activeTab}
        onTabChange={(e) => setActiveTab(e.index)}
      >
        {profile?.relation !== "Me" ? (
          <TabPanel headerTemplate={tutorTemplate} index={0}>
            <div
              className="CHECKBOXES"
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "8px",
                backgroundColor: "#47b9a8",
                borderRadius: "8px",
              }}
            >
              <div
                className="QUALITÉ"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <h2
                  style={{
                    margin: "0px 0px 0px 4px",
                    color: "#F4F2F2",
                  }}
                >
                  Qualité
                </h2>
                <div style={{ display: "flex" }}>
                  <RadioButton
                    onChange={handleChangeRadio}
                    radioData={{
                      name: "qualityAppreciation",
                      value: "TODO, TOP, OK, OK_AA, NOK_AA, NOK",
                    }}
                    profileInfo={profile?.qualityAppreciation}
                  />
                </div>
              </div>
              <div
                className="BIENVENUE"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <h2
                  style={{
                    margin: "0px 0px 0px 4px",
                    color: "#F4F2F2",
                  }}
                >
                  Bienvenue
                </h2>
                <div style={{ display: "flex" }}>
                  <RadioButton
                    onChange={handleChangeRadio}
                    radioData={{
                      name: "welcomeMessage",
                      value: "TODO, OK, MESS",
                    }}
                    profileInfo={profile?.welcomeMessage}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div className="ÉTAT_CIVIL">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2 style={{ margin: "10px 0px" }}>
                    PROFIL ID#{profile?.id}
                  </h2>
                  <h2 style={{ color: PRIMARY_RED }}>
                    {profile?.role} {profile?.profileStatus}
                  </h2>
                  {computeProfileStatus(profile?.profileStatus) && (
                    <button
                      style={{
                        border: "2px solid",
                        borderColor: PRIMARY_RED,
                        backgroundColor: PRIMARY_RED,
                        borderRadius: "8px",
                        padding: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.confirm(
                          "Êtes-vous sûr de vouloir passer ce profil en LIVE ?"
                        )
                          ? handleChangeProfile("LIVE", "profileStatus")
                          : window.alert("Opération annulée");
                      }}
                      className="p-button-danger"
                    >
                      <span style={{ color: "white", fontWeight: "bold" }}>
                        Passer ce profil en LIVE ?
                      </span>
                    </button>
                  )}
                </div>
                <div
                  style={{
                    border: "2px solid lightgrey",
                    borderRadius: "8px",
                    padding: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div>
                      <div
                        className="AVATAR"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "end",
                          padding: "10px",
                        }}
                      >
                        {tutorAvatar !== undefined ? (
                          <Image
                            src={URL.createObjectURL(tutorAvatar)}
                            // zoomSrc={URL.createObjectURL(avatar)}
                            width="200px"
                            height="200px"
                            alt="avatar"
                            preview
                          />
                        ) : (
                          <Avatar
                            image={picto}
                            shape="circle"
                            style={{
                              width: "150px",
                              height: "150px",
                            }}
                            className="mx-2"
                          />
                        )}
                        <FileUpload
                          multiple={false}
                          withCredentials
                          mode="basic"
                          name="demo[]"
                          auto
                          chooseOptions={{
                            iconOnly: true,
                            // icon: "pi pi-fw pi-plus",
                          }}
                          customUpload
                          uploadHandler={uploadHandlerTutorAvatar}
                        />
                      </div>
                      <div style={{ marginTop: 5, display: "flex" }}>
                        <p
                          style={{
                            fontWeight: "600",
                            fontSize: 11,
                            margin: "0px 10px 5px",
                            textDecoration: "underline",
                          }}
                        >
                          Mode absence
                        </p>
                        <RadioButton
                          onChange={handleChangeRadioVaccationMode}
                          radioData={{
                            name: "vaccationMode",
                            value: "OUI, NON",
                          }}
                          profileInfo={profile?.vaccationMode ? "OUI" : "NON"}
                          textColor="black"
                        />
                      </div>
                      <button
                        onClick={async () =>
                          axios
                            .post("/auth/forgot", {
                              email: profile?.user?.email,
                            })
                            .then(() => {
                              alert(
                                "Email de réinitialisation du mot de passe envoyé"
                              );
                            })
                        }
                        style={{
                          margin: "15px 0",
                        }}
                      >
                        Envoyer email réinitisation mdp
                      </button>
                    </div>

                    <div className="PROFIL">
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "0px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Prénom
                      </p>
                      <InputDiv
                        value={profile?.tutorFirstName}
                        //name="Prénom"
                        handleChange={(e) =>
                          handleChangeProfile(e.target.value, "tutorFirstName")
                        }
                      />
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "0px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Nom
                      </p>
                      <InputDiv
                        value={profile?.tutorLastName}
                        //name="Nom"
                        handleChange={(e) =>
                          handleChangeProfile(e.target.value, "tutorLastName")
                        }
                      />
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "0px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Email
                      </p>
                      <InputDiv
                        value={profile?.user?.email}
                        //name="Nom"
                        disabled={true}
                      />
                    </div>
                    <div>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "5px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Téléphone
                      </p>
                      <InputDiv
                        value={profile?.phoneNumber}
                        //name="Telephone"
                        handleChange={(e) => {
                          if (!PhoneNumberValidator(e.target.value)) {
                            setIsPhoneValid("block");
                            handleChangeProfile(e.target.value, "phoneNumber");
                          } else {
                            setIsPhoneValid("none");
                            handleChangeProfile(e.target.value, "phoneNumber");
                          }
                        }}
                      />
                      <span
                        style={{
                          display: isPhoneValid,
                          color: "red",
                          fontSize: "9px",
                          margin: "5px 0 0 10px",
                          fontWeight: "bold",
                        }}
                      >
                        Le numéro doit contenir 10 chiffres
                      </span>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "5px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Relation
                      </p>
                      <InputDropdown
                        value={profile?.relation || ""}
                        //name="Relation"
                        handleChange={(e) =>
                          handleChangeProfile(e.target.value, "relation")
                        }
                        options={relationOptions}
                        placeholder={"Relation avec l'aidé"}
                        customStyle={{
                          width: "170px",
                        }}
                        divStyle={{
                          marginLeft: "10px",
                        }}
                      />
                    </div>
                    <div className="DOCUMENTS">
                      <DocumentSelectorAndValidator
                        profile={profile}
                        type="FrontID"
                        valid={profile?.validIdFront}
                        refreshParent={() => refreshParent()}
                      ></DocumentSelectorAndValidator>
                      <DocumentSelectorAndValidator
                        profile={profile}
                        type="BackID"
                        valid={profile?.validIdBack}
                        invalid={profile?.inValidateBackID}
                        refreshParent={() => refreshParent()}
                      ></DocumentSelectorAndValidator>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "600",
                              fontSize: 14,
                            }}
                          >
                            Provenance de l'utilisateur
                          </p>
                          <InputDropdown
                            value={profile.profileOrigin}
                            //name="duration"
                            handleChange={(e) => {
                              console.log("VALUE", e.value);
                              handleChangeProfile(e.value, "profileOrigin");
                            }}
                            options={userOrigin}
                            customStyle={{
                              height: "36px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="INFOS_HELPEE">
                <h2>RENSEIGNEMENTS</h2>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    border: "2px solid lightgrey",
                    borderRadius: "8px",
                    padding: "12px",
                  }}
                >
                  <div className="BIO">
                    <h5 style={{ margin: "5px 0px 5px 10px" }}>Bio</h5>
                    <InputArea
                      handleChange={(e) =>
                        handleChangeProfile(e.target.value, "tutorBio")
                      }
                      value={profile?.tutorBio}
                      name=""
                      customStyle={{ height: "200px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        ) : null}
        <TabPanel
          headerTemplate={userTemplate}
          index={profile?.relation === "Me" ? 0 : 1}
        >
          <div
            className="CHECKBOXES"
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "8px",
              backgroundColor: "#47b9a8",
              borderRadius: "8px",
            }}
          >
            <div
              className="QUALITÉ"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <h2
                style={{
                  margin: "0px 0px 0px 4px",
                  color: "#F4F2F2",
                }}
              >
                Qualité
              </h2>
              <div style={{ display: "flex" }}>
                <RadioButton
                  onChange={handleChangeRadio}
                  radioData={{
                    name: "qualityAppreciation",
                    value: "TODO, TOP, OK, OK_AA, NOK_AA, NOK",
                  }}
                  profileInfo={profile?.qualityAppreciation}
                />
              </div>
            </div>
            <div
              className="BIENVENUE"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <h2
                style={{
                  margin: "0px 0px 0px 4px",
                  color: "#F4F2F2",
                }}
              >
                Bienvenue
              </h2>
              <div style={{ display: "flex" }}>
                <RadioButton
                  onChange={handleChangeRadio}
                  radioData={{
                    name: "welcomeMessage",
                    value: "TODO, OK, MESS",
                  }}
                  profileInfo={profile?.welcomeMessage}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div className="ÉTAT_CIVIL">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2 style={{ margin: "10px 0px" }}>PROFIL ID#{profile?.id}</h2>
                <h2 style={{ color: PRIMARY_RED }}>
                  {profile?.role} {profile?.profileStatus}
                </h2>
                {profile?.relation === "Me"
                  ? computeProfileStatus(profile?.profileStatus) && (
                      <button
                        style={{
                          border: "2px solid",
                          borderColor: PRIMARY_RED,
                          backgroundColor: PRIMARY_RED,
                          borderRadius: "8px",
                          padding: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          window.confirm(
                            "Êtes-vous sûr de vouloir passer ce profil en LIVE ?"
                          )
                            ? handleChangeProfile("LIVE", "profileStatus")
                            : window.alert("Opération annulée");
                        }}
                        className="p-button-danger"
                      >
                        <span style={{ color: "white", fontWeight: "bold" }}>
                          Passer ce profil en LIVE ?
                        </span>
                      </button>
                    )
                  : null}
              </div>
              <div
                style={{
                  border: "2px solid lightgrey",
                  borderRadius: "8px",
                  padding: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    className="AVATAR"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "end",
                      padding: "10px",
                    }}
                  >
                    {avatar !== undefined ? (
                      <Image
                        src={URL.createObjectURL(avatar)}
                        zoomSrc={URL.createObjectURL(avatar)}
                        width="200px"
                        height="200px"
                        alt="avatar"
                        preview
                      />
                    ) : (
                      <Avatar
                        image={picto}
                        shape="circle"
                        style={{
                          width: "150px",
                          height: "150px",
                        }}
                        className="mx-2"
                      />
                    )}
                    <FileUpload
                      multiple={false}
                      withCredentials
                      mode="basic"
                      name="demo[]"
                      auto
                      chooseOptions={{
                        iconOnly: true,
                        // icon: "pi pi-fw pi-plus",
                      }}
                      customUpload
                      uploadHandler={uploadHandlerAvatar}
                    />
                    <div style={{ marginTop: 5, display: "flex" }}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "0px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Mode absence
                      </p>
                      <RadioButton
                        onChange={handleChangeRadioVaccationMode}
                        radioData={{
                          name: "vaccationMode",
                          value: "OUI, NON",
                        }}
                        profileInfo={profile?.vaccationMode ? "OUI" : "NON"}
                        textColor="black"
                      />
                    </div>
                    <button
                      onClick={async () =>
                        axios
                          .post("/auth/forgot", {
                            email: profile?.user?.email,
                          })
                          .then(() => {
                            alert(
                              "Email de réinitialisation du mot de passe envoyé"
                            );
                          })
                      }
                      style={{
                        margin: "15px 0",
                      }}
                    >
                      Envoyer email réinitisation mdp
                    </button>
                  </div>
                  <div
                    className="PROFIL"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "20px" }}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "5px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Prénom
                      </p>
                      <InputDiv
                        value={profile?.firstName}
                        //name="Prénom"
                        handleChange={(e) =>
                          handleChangeProfile(e.target.value, "firstName")
                        }
                      />
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "5px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Nom
                      </p>
                      <InputDiv
                        value={profile?.lastName}
                        //name="Nom"
                        handleChange={(e) =>
                          handleChangeProfile(e.target.value, "lastName")
                        }
                      />
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "5px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Date de naissance
                      </p>
                      <InputDiv
                        value={profile?.birthDate}
                        //name="Date de naissance"
                        handleChange={(e) =>
                          handleChangeProfile(e.target.value, "birthDate")
                        }
                      />
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "0px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Email
                      </p>
                      <InputDiv
                        value={profile?.user?.email}
                        //name="Nom"
                        disabled={true}
                      />
                    </div>
                    <div style={{ marginRight: "20px" }}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "5px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Code postal
                      </p>
                      <InputDiv
                        value={profile?.zipCode}
                        //name="Code postal"
                        handleChange={(e) => {
                          const newZipCode = e.target.value;
                          const newCity = filteredCityByZipCode(newZipCode);
                          handleChangeProfile(newZipCode, "zipCode");
                          setCity(newCity);
                        }}
                      />
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "5px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Ville
                      </p>
                      <InputDiv value={city} disabled />
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "5px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Pays
                      </p>
                      <InputDiv
                        value={profile?.country}
                        //name="Pays"
                        handleChange={(e) =>
                          handleChangeProfile(e.target.value, "country")
                        }
                      />
                    </div>
                    <div>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "5px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Téléphone
                      </p>
                      <InputDiv
                        value={profile?.phoneNumber}
                        // style={{ borderColor: setIsPhoneValid }}
                        //name="Telephone"
                        handleChange={(e) => {
                          if (!PhoneNumberValidator(e.target.value)) {
                            setIsPhoneValid("block");
                            handleChangeProfile(e.target.value, "phoneNumber");
                          } else {
                            setIsPhoneValid("none");
                            handleChangeProfile(e.target.value, "phoneNumber");
                          }
                        }}
                      />
                      <span
                        style={{
                          display: isPhoneValid,
                          color: "red",
                          fontSize: "9px",
                          margin: "5px 0 0 10px",
                          fontWeight: "bold",
                        }}
                      >
                        Le numéro doit contenir 10 chiffres
                      </span>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 11,
                          margin: "5px 10px 5px",
                          textDecoration: "underline",
                        }}
                      >
                        Relation
                      </p>
                      <InputDropdown
                        value={profile?.relation || ""}
                        //name="Relation"
                        handleChange={(e) =>
                          handleChangeProfile(e.target.value, "relation")
                        }
                        options={relationOptions}
                        placeholder={"Relation avec l'aidé"}
                        customStyle={{
                          width: "170px",
                        }}
                        divStyle={{
                          marginLeft: "10px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="EMERGENCY">
              <h2>CONTACTS D'URGENCE</h2>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  border: "2px solid lightgrey",
                  borderRadius: "8px",
                  padding: "12px",
                }}
              >
                <div>
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: 11,
                      margin: "0px 0px 10px 10px",
                      textDecoration: "underline",
                    }}
                  >
                    Nom
                  </p>
                  <InputDiv
                    value={profile?.emergencyName}
                    //name="Nom"
                    handleChange={(e) =>
                      handleChangeProfile(e.target.value, "emergencyName")
                    }
                  />
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: 11,
                      margin: "10px 0px 10px 10px",
                      textDecoration: "underline",
                    }}
                  >
                    Téléphone
                  </p>
                  <InputDiv
                    value={profile?.emergencyPhoneNumber}
                    //name="Téléphone"
                    handleChange={(e) =>
                      handleChangeProfile(
                        e.target.value,
                        "emergencyPhoneNumber"
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="RENSEIGNEMENTS">
              <h2>RENSEIGNEMENTS</h2>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  border: "2px solid lightgrey",
                  borderRadius: "8px",
                  padding: "12px",
                }}
              >
                <div className="BIO">
                  <h5 style={{ margin: "5px 0px 5px 10px" }}>Bio</h5>
                  <InputArea
                    handleChange={(e) =>
                      handleChangeProfile(e.target.value, "bio")
                    }
                    value={profile?.bio}
                    name=""
                    customStyle={{ height: "200px" }}
                  />
                </div>
              </div>
            </div>
            <div className="CONSIGNES_HELPEE">
              <h2>CONSIGNES</h2>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  border: "2px solid lightgrey",
                  borderRadius: "8px",
                  padding: "12px",
                }}
              >
                <div className="ConsignesArrivee">
                  <h5 style={{ margin: "5px 0px 5px 10px" }}>
                    Consignes de visite à l'arrivée
                  </h5>
                  <InputArea
                    value={profile?.tipsArrival}
                    //name="tipsArrival"
                    handleChange={(e) =>
                      handleChangeProfile(e.target.value, "tipsArrival")
                    }
                    customStyle={{ height: "200px" }}
                  />
                </div>
                <div className="ConsignesMaisons">
                  <h5 style={{ margin: "5px 0px 5px 10px" }}>
                    Consignes de visite dans la maison
                  </h5>
                  <InputArea
                    value={profile?.tipsHouse}
                    // name="tipsHouse"
                    handleChange={(e) =>
                      handleChangeProfile(e.target.value, "tipsHouse")
                    }
                    customStyle={{ height: "200px" }}
                  />
                </div>
                <div className="SAVOIR">
                  <h5 style={{ margin: "5px 0px 5px 10px" }}>À Savoir</h5>
                  <InputArea
                    value={profile?.tipsPerson}
                    //name="tipsPerson"
                    handleChange={(e) =>
                      handleChangeProfile(e.target.value, "tipsPerson")
                    }
                    customStyle={{ height: "200px" }}
                  />
                </div>
                <div className="Avant_partir">
                  <h5 style={{ margin: "5px 0px 5px 10px" }}>
                    Avant de partir
                  </h5>
                  <InputArea
                    value={profile?.tipsDeparture}
                    //name="tipsDeparture"
                    handleChange={(e) =>
                      handleChangeProfile(e.target.value, "tipsDeparture")
                    }
                    customStyle={{ height: "200px" }}
                  />
                </div>
              </div>
            </div>

            <div className="AISE_DEPENDANCE">
              <h2>SITUATIONS DÉPENDANCES</h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  border: "2px solid lightgrey",
                  borderRadius: "8px",
                  padding: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: 11,
                      margin: "0px 0px 0px 10px",
                      textDecoration: "underline",
                    }}
                  >
                    Sur l'origine de l'autonomie
                  </p>
                  {healthGrids?.autonomyLoss?.map((info, index) => {
                    return (
                      <div style={{ display: "inline-flex" }} key={index}>
                        <HighLightSwitch
                          isHighlighted={
                            profile?.autonomyLoss?.indexOf(info) !== -1
                          }
                          onClick={() => {
                            setAutonomy(info);
                          }}
                          text={HealthData[info].text}
                        />
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: 11,
                      margin: "0px 0px 0px 10px",
                      textDecoration: "underline",
                    }}
                  >
                    Sur la mobilité
                  </p>
                  {healthGrids?.mobilities?.map((info, index) => {
                    return (
                      <div style={{ display: "inline-flex" }} key={index}>
                        <HighLightSwitch
                          isHighlighted={
                            profile?.mobilities?.indexOf(info) !== -1
                          }
                          onClick={() => {
                            setMobilities(info);
                          }}
                          text={HealthData[info].text}
                        />
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: 11,
                      margin: "0px 0px 0px 10px",
                      textDecoration: "underline",
                    }}
                  >
                    Et la tête
                  </p>
                  {healthGrids?.mentalHealth?.map((info, index) => {
                    return (
                      <div style={{ display: "inline-flex" }} key={index}>
                        <HighLightSwitch
                          isHighlighted={
                            profile?.mentalHealth?.indexOf(info) !== -1
                          }
                          onClick={() => {
                            setMentalHealth(info);
                          }}
                          text={HealthData[info].text}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="BESOIN_HELPEE">
              <h2>BESOIN</h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  border: "2px solid lightgrey",
                  borderRadius: "8px",
                  padding: "12px",
                }}
              >
                {healthGrids?.neededServices?.map((info, index) => {
                  return (
                    <div style={{ display: "inline-block" }} key={index}>
                      <HighLightSwitch
                        isHighlighted={
                          profile?.neededServices?.indexOf(info) !== -1
                        }
                        onClick={() => {
                          setNeededServices(info);
                        }}
                        text={NeededServicesData[info].text}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="ACTIVITIES">
              <h2>ACTIVITÉS</h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  border: "2px solid lightgrey",
                  borderRadius: "8px",
                  padding: "12px",
                }}
              >
                {healthGrids?.activities?.map((info, index) => {
                  return (
                    <div style={{ display: "inline-block" }} key={index}>
                      <HighLightSwitch
                        isHighlighted={
                          profile?.activities?.indexOf(info) !== -1
                        }
                        onClick={() => {
                          setActivity(info);
                        }}
                        text={ActivityData[info].text}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Statistiques">
          <Stats
            profile={profile}
            profileInfo={statInfos}
            searchProfile={searchProfile}
            onChangeRange={(distance) => getInitialData(distance)}
          ></Stats>
        </TabPanel>
        <TabPanel header="Historique">
          <UpdatesHistory profileId={profile?.id} />
        </TabPanel>
        <TabPanel
          header="Commentaire"
          style={{ fontWeight: "normal" }}
          index={profile?.relation === "Me" ? 1 : 2}
        >
          <h5>Historique</h5>
          <div
            style={{
              width: "100%",
              height: "300px",
              overflow: "auto",
            }}
            id="histo"
          >
            {displayComments()}
          </div>

          <div
            style={{
              width: "100%",
              marginTop: "30px",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span className="p-float-label">
              <InputText
                id="Ajouter un commentaire"
                style={{ width: "400px" }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <label htmlFor="Ajouter un commentaire">
                Ajouter un commentaire
              </label>
            </span>
            <Button
              className={"p-button-primary"}
              onClick={() => addComment()}
              label="Valider"
            />
          </div>
        </TabPanel>
        <TabPanel
          headerTemplate={premiumTemplate}
          style={{
            margin: "auto",
            marginRight: "30px",
            cursor: "pointer",
          }}
          index={profile?.relation === "Me" ? 2 : 3}
        >
          <div
            style={{
              textAlign: "center",
              verticalAlign: "middle",
              height: "300px",
            }}
          >
            <h5>Informations du coupon</h5>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {coupon ? (
                <>
                  <p style={{ fontWeight: "bold" }}>Code: {coupon?.code}</p>
                  <p>Date de validité: {coupon?.endDate}</p>
                  <p>{coupon?.nbAvailable} utilisations restantes</p>
                  <p>{coupon?.nbUsed} utilisations effectuées</p>
                </>
              ) : null}
            </div>
            <h5>Informations de l'entreprise</h5>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {coupon ? (
                <>
                  <p style={{ fontWeight: "bold" }}>{coupon?.company?.name}</p>
                  <p>
                    Contact: {coupon?.company?.contactName}{" "}
                    {coupon?.company?.email}
                  </p>
                  <p>
                    {coupon?.company?.zipCode} {coupon?.company?.city}
                  </p>
                </>
              ) : null}
            </div>
          </div>
        </TabPanel>
        <TabPanel
          headerTemplate={deleteTemplate}
          style={{
            color: "red",
            margin: "auto",
            marginRight: "30px",
            cursor: "pointer",
          }}
          index={profile?.relation === "Me" ? 2 : 3}
        >
          <div
            style={{
              textAlign: "center",
              verticalAlign: "middle",
              height: "300px",
            }}
          >
            <div style={{ margin: "50px" }}>
              La suppression est définitive, êtes vous sur(e) de vouloir
              continuer?
              <br /> <br /> <br />
              <Button onClick={deleteAccount} className="p-button-danger">
                Supprimer
              </Button>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </>
  );
};

export default HelpeeRole;
