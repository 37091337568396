import { React, useEffect, useState } from "react";
import axios from "../../services/axios/Axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../styles/company.css";
import DetailedCompany from "./DetailedCompany";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";

const Company = (props) => {
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {
    axios.get("/companies").then((response) => {
      setCompanyData(response.data);
    });
  }, []);

  const initialState = {
    first: 0,
    rows: 10,
    page: 0,
    sortField: "companies.createdAt",
    sortOrder: "DESC",
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "companies.createdAt": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "companies.id": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "companies.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "companies.phoneNumber": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "companies.email": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    },
  };

  const getDataPage = () => {
    axios.get("/companies").then((response) => {
      setCompanyData(response.data);
    });
  };

  const addCompany = () => {
    props.actions.setAddSubform({
      component: DetailedCompany,
      data: {
        newCompany: true,
        updateParent: () => getDataPage(),
      },
      actions: props.actions,
    });
  };

  return (
    <div
      style={{
        margin: "50px auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <Button label="Ajouter une entreprise" onClick={addCompany} />
      <DataTable
        value={companyData}
        dataKey="id"
        showGridlines
        stripedRows
        className="dataStyle"
        filter="true"
        filters={initialState.filters}
        sortField={initialState.sortField}
        sortOrder={initialState.sortOrder}
        globalFilterFields={[
          "companies.name",
          "companies.id",
          "companies.createdAt",
        ]}
        selectionMode="single"
        onSelectionChange={(e) => {
          // Open overlay to create code and give it a start date and end date
          console.log(e.value);
          props.actions.setAddSubform({
            component: DetailedCompany,
            data: {
              newCompany: false,
              companyId: e.value.id,
              updateParent: () => getDataPage(initialState),
            },
            actions: props.actions,
          });
        }}
      >
        <Column
          field="createdAt"
          header="Date de création"
          style={{ width: "50px" }}
          filter
          body={(rowData) => {
            return <div>{rowData?.createdAt.split("T")[0]}</div>;
          }}
          sortable
        ></Column>
        <Column
          field="id"
          header="ID"
          filter
          sortable
          style={{ width: "20px" }}
        ></Column>
        <Column field="name" header="Nom" filter></Column>
        <Column field="contactName" header="Nom du contact" filter></Column>
        <Column field="phoneNumber" header="Téléphone" filter></Column>
        <Column field="email" header="Mail" filter></Column>
        <Column
          field="coupons.id"
          header="Nb licences"
          style={{ width: "10%" }}
          body={(rowData) => {
            let nbCodes = 0;
            rowData?.coupons.map((coupon) => (nbCodes += coupon?.nbAvailable));
            return <div>{nbCodes}</div>;
          }}
        ></Column>
        <Column
          field="coupons.id"
          header="Nb licences activées"
          style={{ width: "10%" }}
          body={(rowData) => {
            let nbActiveCodes = 0;
            rowData?.coupons.map((coupon) => (nbActiveCodes += coupon?.nbUsed));
            return <div>{nbActiveCodes}</div>;
          }}
        ></Column>
      </DataTable>
    </div>
  );
};

export default Company;
