/*
 * Package import
 */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BaseForm from "./index";
import { setHideSubForm } from "../../store/reducers/general/index";

// State
const mapStateToProps = (state) => ({
  general: state.general,
});

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setHideSubForm,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseForm);
