/*
 * Package Import
 */
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { IntlProvider } from "react-intl";

/*
 * Local Import
 */
import Route from "./components/Route/container";
// import "./index.css";
import "./styles/skeleton.css";
import "./styles/hidden.css";
import "./styles/header.css";
import "./styles/menu.css";

/*
 * Code
 */
export default (store) => (
  <Provider store={store}>
    <IntlProvider locale="fr">
      <Router>
        <Route />
      </Router>
    </IntlProvider>
  </Provider>
);
