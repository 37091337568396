import React from "react";
import "../style.css";
import picto from "../../../../images/picto_hq.png";
import { Avatar } from "primereact/avatar";
import { PRIMARY_RED } from "../../../../styles/colors";
import { Button } from "primereact/button";

const CreatorProfile = ({ profile, profileAvatar, profileTutorAvatar }) => {
  const displayAvatar = () => {
    if (profile.relation === "Me") {
      return displayHelpeeSelfAvatar(profileAvatar);
    } else {
      return displayHelpeetutorAvatar();
    }
  };

  const displayHelpeeSelfAvatar = (avatar, type = null) => {
    if (avatar !== undefined) {
      return (
        <Avatar
          image={URL.createObjectURL(avatar)}
          shape="circle"
          style={{
            width: type === "tutor" ? "50px" : "150px",
            height: type === "tutor" ? "50px" : "150px",
          }}
          className="mx-2"
        />
      );
    } else {
      return (
        <Avatar
          image={picto}
          shape="circle"
          style={{
            width: type === "tutor" ? "50px" : "150px",
            height: type === "tutor" ? "50px" : "150px",
          }}
          className="mx-2"
        />
      );
    }
  };

  const displayHelpeetutorAvatar = () => {
    return (
      <div style={{ display: "flex", alignItems: "baseline", gap: "5px" }}>
        {displayHelpeeSelfAvatar(profileAvatar)}
        <div style={{ marginLeft: "-50px" }}>
          {displayHelpeeSelfAvatar(profileTutorAvatar, "tutor")}
        </div>
      </div>
    );
  };

  return (
    <div className="section-content-profile">
      <div>{displayAvatar()}</div>
      <div className="section-profile">
        <div style={{ display: "flex", gap: "10px" }}>
          <p>{profile.zipCode}</p>
          <p>{profile.city}</p>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div>
            <p>ID: {profile.id}</p>
            <h2>{profile.firstName}</h2>
            <p style={{ color: PRIMARY_RED }}>La personne à aider</p>
          </div>
          {profile.relation !== "Me" && (
            <div>
              <h3> & {profile.tutorFirstName}</h3>
              <p>Son proche aidant</p>
            </div>
          )}
        </div>
        <Button
          label="Voir le profil"
          size="small"
          onClick={() => {
            window.open(`/profils?detailedProfileId=${profile.userId}`);
          }}
          style={{ backgroundColor: PRIMARY_RED, border: 0 }}
          className="p-button-raised"
        />
      </div>
    </div>
  );
};

export default CreatorProfile;
