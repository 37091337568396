import React from "react";
import { Badge } from "primereact/badge";
import "./KpiButtonStyle.css";

const KpiButton = ({
  bgColor,
  data,
  label,
  additionalLabel,
  fixedHeight = false,
}) => {
  return (
    <div
      className="kpi-button-container"
      style={{
        backgroundColor: bgColor,
        padding: fixedHeight ? "0px" : "10px",
      }}
    >
      <div
        className={
          fixedHeight
            ? "label-container additional-label-container"
            : "label-container"
        }
      >
        <p style={{ fontSize: 14, fontWeight: "bold" }}>{label}</p>
        {additionalLabel && (
          <p style={{ fontSize: 14, fontWeight: "bold" }}>{additionalLabel}</p>
        )}
      </div>
      {data && (
        <Badge
          value={data}
          style={{ backgroundColor: "white", color: bgColor }}
        />
      )}
    </div>
  );
};

export default KpiButton;
